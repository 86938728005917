import { configureStore } from "@reduxjs/toolkit";
import categoriesReducer from "../features/slices/CategorySlice";
import modalReducer from "../features/slices/ModalSlice";
import profileReducer from "../features/slices/ProfileSlice";
import filterReducer from "../features/slices/FilterSlice";
import commonReducer from "../features/slices/CommonSlice";
import loadingReducer from "../features/slices/LoadingSlice";
import gameReducer from "../features/slices/GamesSlice";
import userReducer from "../features/slices/UserSlice";
import paymentReducer from "../features/slices/PaymentSlice";
export const store = configureStore({
  reducer: {
    categories: categoriesReducer,
    modal: modalReducer,
    profile: profileReducer,
    filter: filterReducer,
    common: commonReducer,
    loading: loadingReducer,
    game: gameReducer,
    user: userReducer,
    payment: paymentReducer,
  },
});
