import React from "react";
import newChatIcon from "../../assets/images/newChatIcon.png";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { updateSelectedItem } from "../../features/slices/CommonSlice";
export default function ChatBox() {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const dispatch = useDispatch();
  const closeChat = () => {
    dispatch(updateSelectedItem(null));
  };
  return (
    <div className="chat--box">
      <div className="chatbox-header d-flex p-3">
        <img loading="lazy" src={newChatIcon} alt="kubera" width={50} />
        <div className="mx-2 mt-1">
          <p className="mb-0" style={{ color: "#F5CA3D" }}>
            KUBERA SUPPORT
          </p>
          <p className="text--medium mb-0">Offline</p>
        </div>
        {!!isMobile && <i className="bi bi-x-lg" onClick={closeChat}></i>}
      </div>
      <div className="chatbox-body p-4 mb-5">
        <div
          className="p-3 mb-2"
          style={{ background: "#1E1E1E", borderRadius: "10px" }}
        >
          <p className="mb-0 text--medium">Our Executive is Offline</p>
        </div>
        <div
          className="p-3 mb-2"
          style={{ background: "#1E1E1E", borderRadius: "10px" }}
        >
          <p className="mb-0 text--medium">For any quires,</p>
          <p className="mb-0 text--medium">
            please contact{" "}
            <span style={{ color: "#FAE26A" }}>+91 8112112594</span>
          </p>
        </div>
      </div>
    </div>
  );
}
