import React, { useState, useEffect } from "react";
import badgeProcess from "../../../assets/images/underVerification.svg";
import newChatIcon from "../../../assets/images/newChatIcon.png";
import { reset } from "../../../features/slices/PaymentSlice";
import DeleteBankDetail from "../../common/DeleteBankDetail";
import {
  closePaymentModal,
  openPaymentCompletionModal,
  openPaymentModal,
} from "../../../features/slices/ModalSlice";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  showChatBoxInDesktop,
  updateSelectedItem,
} from "../../../features/slices/CommonSlice";
import {
  showAddBankModal,
  closeAddBankModal,
} from "../../../features/slices/ProfileSlice";
import BankAccountAddedPopup from "../../common/BankAccountAddedPopup";
import { createWithdrawRequest } from "../../../features/slices/PaymentSlice";
import ErrorCard from "../../common/ErrorCard";
import { getUserData } from "../../../features/slices/UserSlice";
import NewBankAccount from "../../profile/NewBankAccount";
export default function WithdrawContent() {
  const dispatch = useDispatch();
  const amountOptions = [
    {
      amount: "500",
      id: "amount1",
    },
    {
      amount: "1,000",
      id: "amount2",
    },
    {
      amount: "5,000",
      id: "amount3",
    },
    {
      amount: "10,000",
      id: "amount4",
    },
    {
      amount: "50,000",
      id: "amount5",
    },
  ];
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const payment = useSelector((state) => state.payment);
  const user = useSelector((state) => state.user);
  const profile = useSelector((state) => state.profile);

  const [enteredAmount, setAmount] = useState("0");
  const [selectedAmount, setSelectedAmount] = useState("amount1");
  const [primaryBank, setPrimaryBank] = useState(
    user.userData?.bankDetails[0]?.bankId || null
  );
  const [redBorder, setRedBorder] = useState(false);
  const [localError, setLocalError] = useState("");
  const [verifiedBanks, setVerifiedBanks] = useState([]);
  const [unVerifiedBanks, setUnVerifiedBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState(
    user.userData?.bankDetails[0]?.bankId || null
  );
  const [showDelete, setShowDelete] = useState(false);
  const [accountTobeDeleted, setAccountTobeDeleted] = useState({});
  const [bankAdded, setBankAdded] = useState(false);

  const goToNextStep = () => {
    if (+enteredAmount < 100 || +enteredAmount > 100000) {
      setRedBorder(true);
      return;
    } else if (!verifiedBanks.length) {
      setLocalError("Please add atleast one verified bank account");
      setTimeout(() => {
        setLocalError("");
      }, 2000);
      return;
    } else if (!selectedBank) {
      setLocalError("Please Select a bank account");
      return;
    }
    dispatch(
      createWithdrawRequest({ amount: enteredAmount, bankId: selectedBank })
    );
  };

  const handleAmountSelect = (amount) => {
    const prevAmount = +enteredAmount.replace(/,/g, "");
    const newAmount = +amount.replace(/,/g, "");
    const addedAMount = prevAmount + newAmount;
    let finalAmount = (
      parseInt(addedAMount.toString().replace(/[^\d]+/gi, "")) || ""
    ).toLocaleString("en-US");
    if (finalAmount.length === 7) {
      finalAmount = finalAmount.slice(0, 1) + "," + finalAmount.slice(1);
    }
    setAmount(finalAmount);
  };

  const addNewBankAccount = () => {
    // dispatch(closePaymentModal());
    dispatch(showAddBankModal());
  };

  const openDepositScreen = () => {
    if (!isMobile) dispatch(openPaymentModal("Deposit"));
    else dispatch(updateSelectedItem("deposit"));
  };

  useEffect(() => {
    if (user.userData.bankDetails && user.userData.bankDetails.length) {
      const verifedOnes = user.userData.bankDetails.filter(
        (item) => item.isVerified
      );
      setVerifiedBanks(verifedOnes);
      const unVerifiedOnes = user.userData.bankDetails.filter(
        (item) => !item.isVerified
      );
      setUnVerifiedBanks(unVerifiedOnes);
    } else {
      setVerifiedBanks([]);
      setUnVerifiedBanks([]);
    }
  }, [user.userData.bankDetails]);

  useEffect(() => {
    if (payment.withrawRequestedSuccessfully) {
      dispatch(getUserData({ source: "withdraw_request", showLoader: true }));
      if (isMobile) dispatch(updateSelectedItem("withdrawCompleted"));
      else {
        dispatch(closePaymentModal());
        dispatch(openPaymentCompletionModal("Withdrawl"));
      }
    }
  }, [payment.withrawRequestedSuccessfully]);

  useEffect(() => {
    if (isMobile)
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
  }, [payment.error]);

  const setEnteredAmount = (event) => {
    let amount = (
      parseInt(event.target.value.replace(/[^\d]+/g, "")) || ""
    ).toLocaleString("en-US");
    if (amount.length === 7) {
      amount = amount.slice(0, 1) + "," + amount.slice(1);
    }
    setAmount(amount);
    setRedBorder(false);
  };
  const getFormattedBalance = (balance) => {
    if (balance) {
      if (typeof balance === "number") return balance.toLocaleString();
      if (!balance.includes(",")) {
        const intBalance = parseInt(balance);
        return intBalance.toLocaleString();
      }
    }
    return "0";
  };

  const showChatBox = () => {
    if (isMobile) {
      dispatch(updateSelectedItem("chat-bot"));
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      dispatch(showChatBoxInDesktop());
      dispatch(closePaymentModal());
    }
  };
  const removeBankAccount = (item) => {
    setShowDelete(true);
    setAccountTobeDeleted(item);
  };
  const closeModal = () => {
    setShowDelete(false);
    dispatch(reset());
  };
  const closeBankSuccessModal = () => {
    setBankAdded(false);
  };
  useEffect(() => {
    if (payment.bankDetails) {
      console.log("bankdetails");
      dispatch(
        getUserData({ source: "bank_details_withdraw_form", showLoader: true })
      );
      dispatch(closeAddBankModal());
      // setSuccessMessage("Account Added Successfully!");
      // setTimeout(() => {
      //   closeModal();
      // }, 4500);
      setBankAdded(true);
    }
  }, [payment.bankDetails]);
  return (
    <>
      {bankAdded && (
        <BankAccountAddedPopup closeModal={closeBankSuccessModal} />
      )}
      {profile.addBankModal && <NewBankAccount />}
      {showDelete && (
        <DeleteBankDetail
          accountInfo={accountTobeDeleted}
          closeModal={closeModal}
        />
      )}
      <div className="row">
        <div
          className="col-md-6 col-lg-6 col-xl-6 col-sm-12"
          style={{ borderRight: "1px solid #70707071" }}
        >
          {payment.error && isMobile ? (
            <ErrorCard error={payment.error} />
          ) : null}
          <div className="withdrawl-left mt-4" style={{ padding: "0 1.6rem" }}>
            <div className="balance-info py-2 px-3 text--black mb-3">
              <div
                className="balance d-flex m-auto justify-content-around"
                style={{ height: "75px" }}
              >
                <div className="total-bal">
                  <div>
                    <p className="text--normal text-center mb-1">Total Bal.</p>
                    <p className="mb-0">
                      <b>
                        ₹
                        {getFormattedBalance(
                          user.userData.redisBalance ||
                            user.userData.totalBalance
                        )}
                      </b>
                    </p>
                  </div>
                </div>
                <div className="withdrawl-bal">
                  <div>
                    <p className="text--normal text-center mb-1">
                      Withdrawl Bal.
                    </p>
                    <p className="mb-0">
                      <b>
                        ₹
                        {getFormattedBalance(
                    user.userData.redisBalance || user.userData.totalBalance
                  )}
                      </b>
                    </p>
                  </div>
                </div>
                <div className="bonus-bal">
                  <div>
                    <p className="text--normal text-center mb-1">Bonus</p>
                    <p className="mb-0">
                      <b>₹{getFormattedBalance(user.userData.bonus)}</b>
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="bal-progress mb-3">
                <div className="circle--progress"></div>
                <span className="progress-value text--normal">
                  <strong>72.4%</strong>
                </span>
              </div> */}
            </div>
          </div>
          <div className="amount-input my-3 px-4">
            <label className="text--small">
              Amount <span>*</span>
            </label>
            <div className="d-flex">
              <span style={{ float: "start" }}>INR</span>
              <input
                type="text"
                className={redBorder ? "red-border" : ""}
                placeholder="Enter Amount"
                pattern="^[\d,]+$"
                value={enteredAmount}
                onChange={(e) => {
                  setEnteredAmount(e);
                }}
              />
            </div>
            <p className="low-opacity text--small mb-0 mt-1">100-5,00,000</p>
          </div>
          {/* <div className="amount-options my-3 px-4">
            <div className="d-flex">
              {amountOptions.map((item, index) => (
                <div
                  key={index}
                  className="text-center amount"
                  id={item.id}
                  onClick={() => handleAmountSelect(item.amount)}
                >
                  +{item.amount}
                </div>
              ))}
            </div>
          </div> */}
          <div className="px-4">
            <p
              className="low-opacity text--small mb-0 mt-1"
              style={{ color: "#E42D2D" }}
            >
              {+enteredAmount.replace(/,/g, "") < 100
                ? "Amount can't be less than 100"
                : +enteredAmount.replace(/,/g, "") > 500000
                ? "Amount can't be greater than 500,000"
                : ""}
            </p>
          </div>
          {!isMobile && (
            <div className="m-4">
              <p className="text--medium">
                Want to win a Deposit Bonus?{" "}
                <b
                  style={{ color: "#F5CA3D", cursor: "pointer" }}
                  onClick={openDepositScreen}
                >
                  Deposit Now
                </b>
              </p>
              <img
                loading="lazy"
                onClick={openDepositScreen}
                className="w-100 mb-3"
                src={require("../../../assets/images/casino.webp")}
                alt="casino"
              />
            </div>
          )}
        </div>
        <div
          className="col-md-6 col-lg-6 col-xl-6 col-sm-12"
          style={{ position: "relative" }}
        >
          <p className="mt-3 px-3 text--normal">
            Saved Bank Accounts for withdrawals
          </p>
          <div className="mb-3 px-3 bank-lists">
            <div className="added-banks-list">
              {verifiedBanks.map((item, index) => (
                <div className="form-check mb-1" key={index}>
                  <div className="d-flex checkbox-div">
                    <input
                      className="bank-checkbox-input"
                      type="radio"
                      name="flexRadioDefault"
                      value={item.bankName}
                      id="flexRadioDefault1"
                      checked={primaryBank === item.bankId}
                      onChange={() => {
                        setPrimaryBank(item.bankName);
                        setSelectedBank(item.bankId);
                        setLocalError("");
                      }}
                    />
                    <label
                      className="text--normal form-check-label mx-2"
                      for="flexRadioDefault1"
                    >
                      {item.bankName}
                    </label>
                  </div>
                  <p
                    className="text--small mx-2 mt-1 mb-1"
                    style={{ fontWeight: "200", paddingLeft: "17px" }}
                  >
                    Account number Ending with XX{" "}
                    {(item.accountNumber % 10000).toString()}
                  </p>
                  <button
                    className="mb-3 delete-bank"
                    style={{
                      color: "#FF1515",
                      marginLeft: "17px",
                      width: "60px",
                      background: "none",
                      border: "none",
                      fontSize: "14px",
                    }}
                    onClick={() => removeBankAccount(item)}
                  >
                    Delete
                  </button>
                  {primaryBank === item.bankId && (
                    <>
                      <div className="primary-account">
                        <i className="bi bi-check2 mx-2"></i>
                        <span>Primary</span>
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
            {unVerifiedBanks.map((item, index) => (
              <div className="mt-2 banks-to-be-added d-flex mb-2" key={index}>
                <img
                  loading="lazy"
                  src={require("../../../assets/images/lock.png")}
                  width={20}
                  height={28}
                  alt="lock"
                  style={{ marginTop: "-5px" }}
                />
                {/* <i className="bi bi-lock-fill"></i> */}
                <div className="mx-2">
                  <div className="d-flex">
                    <p
                      className="text--normal form-check-label mb-2"
                      for="flexRadioDefault2"
                    >
                      {item.bankName}
                    </p>
                    <img
                      loading="lazy"
                      className="mx-3"
                      src={badgeProcess}
                      alt="in process"
                    />
                  </div>
                  <p className="text--small" style={{ fontWeight: "200" }}>
                    Account number Ending with XX{" "}
                    {(item.accountNumber % 10000).toString()}
                  </p>
                </div>
              </div>
            ))}

            <p
              className="text--normal add-bank-account-text"
              style={{ color: "#29B3EF", cursor: "pointer" }}
              onClick={addNewBankAccount}
            >
              + Add Bank Account
            </p>
            {payment.error && !isMobile ? (
              <ErrorCard error={payment.error} />
            ) : null}

            {localError ? (
              <div
                className="mt-4 p-3 payment-error border border-light border-opacity-25 rounded"
                style={{
                  color: "rgb(158, 109, 29)",
                  background: "rgb(20,20,20)",
                }}
              >
                <p className="text--medium mb-0">
                  <i class="bi bi-info-circle"></i> {localError}
                </p>
              </div>
            ) : null}
          </div>
          <div className="action-btns px-3">
            <p className="text-center text--medium statement">
              Problem in making payment please contact customer support
            </p>
            <div className="d-flex justify-content-between">
              <button
                className="border-btn"
                style={{ color: "#F5CA3D", border: "1px solid #F5CA3D" }}
                onClick={showChatBox}
              >
                <img
                  loading="lazy"
                  src={newChatIcon}
                  width={isMobile ? 25 : 30}
                  alt="logo"
                  className="mx-1"
                />
                Chat with us
              </button>
              <button className="standard-btn" onClick={goToNextStep}>
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
