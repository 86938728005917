import React from "react";
import { useSelector } from "react-redux";

export default function PhoneAndEmail() {
  const user = useSelector((state) => state.user);
  return (
    <>
      <div className="row">
        <div className="col-xl-6 col-md-8 col-lg-8 col-12 pt-4">
          <h6>Mobile Verification</h6>
          <label className="text--normal text--light mb-2 mt-3">Phone</label>
          <div className="phoneEmail-verification-input mb-5">
            <input
              type="text"
              readOnly
              value={`${user.userData.countryCode || ""} ${
                user.userData.phone || ""
              }`}
            />
            <div className="info--message verified-badge d-flex">
              <i className="bi bi-check2 mx-1"></i>
              <p className="mb-0">Verified</p>
            </div>
          </div>
        </div>
      </div>
      <hr />
      {/* <div className="row">
        <div className="col-xl-6 col-lg-8 col-md-8 col-12 pt-4">
          <h6>Email Verification</h6>
          <label className="text-muted text--normal text--light mb-2">
            Email
          </label>
          <div className="phoneEmail-verification-input mb-5">
            <input type="text" value="abc@gmail.com" placeholder="Email" />
            {!isMobile && <button>Update</button>}
          </div>
          {isMobile && <button className="standard-btn w-100">Update</button>}
        </div>
      </div> */}
    </>
  );
}
