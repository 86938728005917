import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closeforgotPasswordModal,
  openMobileOtpModal,
  openLoginModal,
} from "../../features/slices/ModalSlice";
import { updateSelectedItem } from "../../features/slices/CommonSlice";
import { useMediaQuery } from "react-responsive";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import ChatBotOption from "./ChatBotOption";
import {
  relogin,
  sendMobileOTP,
  updateUser,
  storePhoneAndCountry,
} from "../../features/slices/UserSlice";
import Cookies from "js-cookie";
export default function ForgotPasswordActions() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const modal = useSelector((state) => state.modal);
  const common = useSelector((state) => state.common);
  const user = useSelector((state) => state.user);
  const [phone, setPhone] = useState("");
  const [isvalid, setIsValid] = useState(false);
  const [isInfoIncorrect, setIsInfoIncorrect] = useState(true);
  const [passwordResetSuccessful, setPasswordResetSuccessful] = useState(false);
  const [message, setMessage] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [countryCode, setCountryCode] = useState(null);
  const [localError, setLocalError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const handlePasswordInput = (event) => {
    setPassword(event.target.value);
  };
  const handlePhoneNumberChange = (value) => {
    if (value) setIsValid(isValidPhoneNumber(value));
    setLocalError("");
    if (value && isValidPhoneNumber(value)) {
      const phoneNumber = parsePhoneNumber(value);
      setCountryCode("+" + phoneNumber.countryCallingCode);
      setPhone(phoneNumber.nationalNumber);
    } else {
      setPhone(value);
    }
  };
  const matchPasswords = (event) => {
    setLocalError("");
    setConfirmPassword(event.target.value);
  };
  const togglePassword = () => {
    setLocalError("");
    setShowPassword(!showPassword);
  };
  const submit = () => {
    if (!isValidPhoneNumber(countryCode + "" + phone)) {
      setLocalError("Phone number is not Valid");
      return;
    }

    dispatch(sendMobileOTP({ countryCode: countryCode, phone: phone }));
    dispatch(storePhoneAndCountry({ countryCode: countryCode, phone: phone }));
  };
  const openLogin = () => {
    if (isMobile) {
      dispatch(updateSelectedItem("login"));
    } else {
      dispatch(openLoginModal());
      dispatch(closeforgotPasswordModal());
    }
  };

  const updatePassword = () => {
    if (password.length < 8) {
      setLocalError("Password must have atleast 8 characters");
      return;
    }
    if (password !== confirmPassword) {
      setLocalError("Both passwords should match");
      return;
    }
    if (!message.length)
      dispatch(
        updateUser({ newPassword: password, confirmPassword: password })
      );
  };

  useEffect(() => {
    if (user.otpSent) {
      dispatch(closeforgotPasswordModal());
      const isFromForgotPassword = !modal.initialOtpVerification;
      if (!isMobile) dispatch(openMobileOtpModal(isFromForgotPassword));
      else
        dispatch(
          updateSelectedItem({
            name: "mobileOTP",
            fromForgotPassword: isFromForgotPassword,
          })
        );
    }
  }, [user.otpSent]);

  useEffect(() => {
    if (user.userUpdated) {
      setPasswordResetSuccessful(true);
    }
  }, [user.userUpdated]);

  return (
    <div>
      <div className="incorrect-details-div mb-3">
        {user.error && <p className="text-center message">{user.error} </p>}
        {localError && <p className="text-center message">{localError} </p>}
        <ChatBotOption />
      </div>

      {passwordResetSuccessful ? (
        <>
          <p className="text--normal mb-2">
            <strong>Password Reset</strong>
          </p>
          <p className="text--small low-opacity">
            Your password has been reset successfully!
          </p>
          <button className="login-btn mb-3 mt-3 p-2" onClick={openLogin}>
            Login
          </button>
        </>
      ) : (
        <>
          {modal.isFromForgotPassword || common.isFromForgotPassword ? (
            <>
              <p className="text--normal mb-2">
                <strong>Set New Password</strong>
              </p>
              <p className="text--small low-opacity">
                Your new password must be different from previously used
                password
              </p>
              <b>Password</b>
              <div className="password overflow-hidden">
                <input
                  type={`${showPassword ? "text" : "password"}`}
                  name="password"
                  id="password"
                  required
                  onChange={handlePasswordInput}
                />
                <i
                  className={`bi ${showPassword ? "bi-eye" : "bi-eye-slash"}`}
                  onClick={togglePassword}
                  style={{ fontSize: "18px", marginTop: "-1px" }}
                ></i>
              </div>
              {password.length >= 8 ? (
                <p></p>
              ) : (
                <p className="normal--message">Password much have 8 Chars</p>
              )}
              <b>Confirm Password</b>
              <div className="password overflow-hidden">
                <input
                  type={`${showPassword ? "text" : "password"}`}
                  name="password"
                  id="confirmPassword"
                  required
                  onChange={matchPasswords}
                />
                <i
                  className={`bi ${showPassword ? "bi-eye" : "bi-eye-slash"}`}
                  onClick={togglePassword}
                  style={{ fontSize: "18px", marginTop: "-1px" }}
                ></i>
              </div>
              {confirmPassword.length && password === confirmPassword ? (
                <p></p>
              ) : (
                <p className="normal--message">Both Passwords should match</p>
              )}
              <button
                className="login-btn mb-3 mt-3 p-2"
                onClick={updatePassword}
              >
                Submit
              </button>
            </>
          ) : (
            <>
              <div>
                <p className="text--normal mb-2">
                  <strong>
                    {modal.initialOtpVerification
                      ? "Verify OTP"
                      : "Forgot Password"}
                  </strong>
                </p>
                <p className="text--small low-opacity">
                  We will send an OTP on your registered Mobile Number. PLease
                  enter your Mobile Number.
                </p>
                <b>Mobile</b>
                <div className="input-group">
                  <PhoneInput
                    placeholder="Enter phone number"
                    className={`text-input mb-3   ${
                      isvalid ? "form-control is-valid" : ""
                    }`}
                    // international
                    onChange={handlePhoneNumberChange}
                    defaultCountry="IN"
                  ></PhoneInput>
                </div>
                <button className="login-btn mb-3 mt-3 p-2" onClick={submit}>
                  Submit
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
