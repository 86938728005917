import React from "react";
import image from "../../assets/images/megaphone.svg";

export default function Headlines() {
  return (
    <div>
      <div className="container-fluid headlines" id="headlines">
        <div className="row">
          <div className="col-12 col-lg-2 col-sm-12 col-md-6 headline1 p-1">
            <span>
              <img
                loading="lazy"
                src={image}
                className="px-2"
                style={{ cursor: "pointer" }}
              />
            </span>
            <marquee>
              32 Cards Matka, Live Roulette 1, Live Roulette 2, 32 Cards Matka,
              Live Roulette 1, Live Roulette 2,
            </marquee>
          </div>
          <div className="col-10 col-lg-10 col-md-6 headline2 p-1">
            <marquee>
              32 Cards Matka, Live Roulette 1, Live Roulette 2, Andar Bahar,
              Live Andar Bahar, 32 Cards Matka, Live Roulette 1, Live Roulette
              2, Andar Bahar, Live Andar Bahar, 32 Cards Matka, Live Roulette 1,
              Live Roulette 2, Andar Bahar, Live Andar Bahar, 32 Cards Matka,
              Live Roulette 1, Live Roulette 2, Andar Bahar, Live Andar Bahar,
              32 Cards Matka, Live Roulette 1, Live Roulette 2, Andar Bahar,
              Live Andar Bahar
            </marquee>
          </div>
        </div>
      </div>
    </div>
  );
}
