import React, { useState, useEffect } from "react";
import profileIcon from "../../assets/images/profileIcon.svg";
import walletIcon from "../../assets/images/walletIcon.svg";
import verificationIcon from "../../assets/images/verificationIcon.svg";
import securityIcon from "../../assets/images/securityIcon.svg";
import ledgerIcon from "../../assets/images/ledgerIcon.svg";
import activeProfileIcon from "../../assets/images/activeProfileIcon.svg";
import activeWalletIcon from "../../assets/images/activeWalletIcon.svg";
import activeSecurityIcon from "../../assets/images/activeSecurityIcon.svg";
import activeLedgerIcon from "../../assets/images/activeLedgerIcon.svg";
import activeVerificationIcon from "../../assets/images/activeVerificationIcon.svg";
import UserProfile from "../profile/UserProfile";
import VerificationItems from "./VerificationItems";
import Security from "../profile/Security";
import LedgerItems from "./LedgerItems";
import PhoneAndEmail from "../profile/PhoneAndEmail";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import {
  getCountryList,
  updateSelectedItem,
} from "../../features/slices/CommonSlice";
export default function ProfilePhone() {
  const common = useSelector((state) => state.common);
  const user = useSelector((state) => state.user);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const dispatch = useDispatch();
  const [activeItem, setActiveItem] = useState("profile");
  const [activeVerificationItem, setActiveVerificationItem] = useState("");
  const [ActiveComponent, setActiveComponent] = useState("");
  const [selectedItem, setSelectedItem] = useState({});
  const [profileItems, setProfileItems] = useState([
    {
      id: "profile",
      name: "Profile",
      src: profileIcon,
      active_src: activeProfileIcon,
    },
    {
      id: "balance",
      name: "Balance",
      src: walletIcon,
      active_src: activeWalletIcon,
    },
    {
      id: "verification",
      name: "Verification",
      src: verificationIcon,
      active_src: activeVerificationIcon,
    },
    {
      id: "security",
      name: "Security",
      src: securityIcon,
      active_src: activeSecurityIcon,
    },
    {
      id: "ledger",
      name: "Ledger",
      src: ledgerIcon,
      active_src: activeLedgerIcon,
    },
  ]);
  const [verificationItems, setVerificationItems] = useState([
    {
      id: "phone_email",
      component: "PhoneAndEmail",
      name: "Phone",
    },
    {
      id: "id_proof",
      component: "IdProof",
      name: "Id Proof",
    },
    // {
    //   id: "selfie",
    //   component: "Selfie",
    //   name: "Selfie",
    // },
    {
      id: "bank_verification",
      component: "BankVerification",
      name: "Bank Verification",
    },
  ]);
  const [ledgerItems, setLedgerItems] = useState([
    {
      id: "finance",
      component: "Finance",
      name: "Finance",
    },
    {
      id: "game_transactions",
      component: "GameTransactions",
      name: "Game Transactions",
    },
    {
      id: "bonus",
      component: "Bonus",
      name: "All Transactions",
    },
  ]);
  const loadComponent = async (component) => {
    const { default: DynamicComponent } = await import(
      `../profile/${component}`
    );
    setActiveComponent(() => DynamicComponent);
  };
  const loadContent = (event) => {
    localStorage.setItem(
      "mobileProfileScreen",
      event.target.id || event.target.parentElement.id
    );
    setActiveItem(event.target.id || event.target.parentElement.id);
  };

  const handleClick = (id, type) => {
    let selected;
    if (type === "verification")
      selected = verificationItems.find((item) => item.id === id);
    else selected = ledgerItems.find((item) => item.id === id);
    setActiveComponent(selected.component);
    setSelectedItem(selected);
    setActiveVerificationItem(id);
    loadComponent(selected.component);
  };

  const showDepositScreen = () => {
    dispatch(updateSelectedItem("deposit"));
  };

  useEffect(() => {
    if (common.showBalance) {
      setActiveItem("balance");
    }
  }, [common.showBalance]);
  useEffect(() => {
    dispatch(getCountryList());
    const profileSection = localStorage.getItem("mobileProfileScreen");
    if (localStorage.getItem("showBalance")) {
      setActiveItem("balance");
    }
    localStorage.removeItem("showBalance");
    if (profileSection) {
      setActiveItem(profileSection);
    }
  }, []);
  const getFormattedBalance = (balance) => {
    if (balance) {
      if (typeof balance === "number") return balance.toLocaleString();
      if (!balance.includes(",")) {
        const intBalance = parseInt(balance);
        return intBalance.toLocaleString();
      }
    }
    return "0";
  };

  return (
    <div
      className="profile--sm"
      style={{ background: "#111111", height: "auto" }}
    >
      <div className="profile-items" style={{ marginRight: "10px" }}>
        <div className="mt-3 px-2 d-flex">
          {profileItems.map((item, index) => {
            return (
              <div
                className="d-flex border border-light border-opacity-25 py-2 px-3 mx-1 profile-item"
                id={item.id}
                onClick={loadContent}
              >
                <img
                  loading="lazy"
                  src={activeItem === item.id ? item.active_src : item.src}
                  alt={item.id}
                  width={20}
                />
                {activeItem === item.id ? (
                  <p
                    className="text--medium mb-0 mx-1"
                    style={{ color: "#F5C839" }}
                  >
                    {item.name}
                  </p>
                ) : (
                  <p className="text--medium mb-0 mx-1">{item.name}</p>
                )}
              </div>
            );
          })}
        </div>
      </div>

      <div
        className="main-content p-3"
        style={
          activeItem === "ledger" && isMobile ? { marginBottom: "100px" } : {}
        }
      >
        {activeItem === "profile" && <UserProfile />}
        {activeItem === "balance" && (
          <div className="balance-sm">
            <p>Balance</p>
            <div className="balance-info p-4 text--black mb-3">
              <div className="balance d-flex m-auto justify-content-between">
                <div className="total-bal">
                  <p className="text--medium mb-1">Total Bal.</p>
                  <p>
                    <strong>
                      ₹
                      {getFormattedBalance(
                        user.userData.redisBalance || user.userData.totalBalance
                      )}
                    </strong>
                  </p>
                </div>
                <div className="withdrawl-bal">
                  <p className="text--medium mb-1">Withdrawl Bal.</p>
                  <p>
                    <strong>
                      ₹{getFormattedBalance(
                    user.userData.redisBalance || user.userData.totalBalance
                  )}
                    </strong>
                  </p>
                </div>
                <div className="bonus-bal">
                  <p className="text--medium mb-1">Bonus</p>
                  <p>
                    <strong>₹{getFormattedBalance(user.userData.bonus)}</strong>
                  </p>
                </div>
              </div>
              {/* <div className="bal-progress">
                <div className="circle--progress"></div>
                <span className="progress-value text--medium">
                  <b>72.4%</b>
                </span>
              </div> */}
            </div>
            <button
              className="standard-btn deposit-now mb-3 mt-3 p-2 w-100"
              onClick={showDepositScreen}
            >
              Deposit Now
            </button>
          </div>
        )}
        {activeItem === "verification" && (
          <VerificationItems
            items={verificationItems}
            clickItem={handleClick}
          />
        )}
        {activeItem === "ledger" && (
          <LedgerItems items={ledgerItems} clickItem={handleClick} />
        )}
        {activeItem === "security" && <Security />}
        {activeVerificationItem && (
          <div className="mobile-verification-item">
            <div className="d-flex">
              <i
                className="bi bi-arrow-left"
                style={{
                  fontSize: "25px",
                  marginRight: "10px",
                }}
                onClick={() => {
                  setActiveComponent("");
                  setActiveVerificationItem("");
                }}
              ></i>
              <p className="mb-0 name">{selectedItem.name}</p>
            </div>
            {ActiveComponent && <ActiveComponent />}
          </div>
        )}
      </div>
    </div>
  );
}
