import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closePaymentMethod,
  closePaymentModal,
  openPaymentCompletionModal,
} from "../../../features/slices/ModalSlice";
import { useMediaQuery } from "react-responsive";
import { updateSelectedItem } from "../../../features/slices/CommonSlice";
import qrcodeImage from "../../../assets/images/qrCode.svg";
import ActionButtons from "./ActionButtons";
import { createDepositRequest } from "../../../features/slices/PaymentSlice";
import ErrorCard from "../../common/ErrorCard";
export default function UPIPayment() {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [referenceNumber, setReferenceNumber] = useState("");
  const [redBorder, setRedBorder] = useState(false);
  const [tos, setTos] = useState(false);
  const [copyMessage, setCopyMessage] = useState("");
  const modal = useSelector((state) => state.modal);
  const payment = useSelector((state) => state.payment);
  const [checkValidation, setValidationRemider] = useState(false);
  const dispatch = useDispatch();

  // const closeModal = () => {
  //   dispatch(closePaymentMethod());
  // };
  const goToNextStep = (event) => {
    // const form = document.querySelector(".needs-validation");
    if (referenceNumber.length !== 12) {
      setRedBorder(true);
      if (isMobile) {
        const section = document.getElementById("utr_number");
        section.scrollIntoView({ behavior: "smooth" });
      }
      event.preventDefault();
      return;
    }
    if (!tos) {
      if (isMobile) {
        const section = document.getElementById("checkboxId");
        section.scrollIntoView({ behavior: "smooth" });
        setValidationRemider(true);
      }
      return;
    }
    event.preventDefault();
    dispatch(
      createDepositRequest({
        ...modal.depositData,
        referenceNumber: referenceNumber,
        tos: tos,
      })
    );
  };
  const getUpiId = () => {
    if (payment.paymentMethods.length)
      return payment.paymentMethods.find(
        (item) => item.id === modal.depositData.paymentMethodId
      ).details.upiId;
    return "";
  };

  useEffect(() => {
    if (payment.depositRequestedSuccessfully) {
      dispatch(closePaymentMethod());
      if (isMobile) dispatch(updateSelectedItem("depositCompleted"));
      else {
        dispatch(openPaymentCompletionModal("Deposit"));
        dispatch(closePaymentModal());
      }
    }
  }, [payment.depositRequestedSuccessfully, dispatch, isMobile]);
  useEffect(() => {
    if (isMobile)
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
  }, [payment.error, isMobile]);

  const copyId = async () => {
    const copyText = document.getElementById("id_upi").textContent;
    try {
      await navigator.clipboard.writeText(copyText);
      console.log("Content copied to clipboard");
      setCopyMessage("Copied");
      setTimeout(() => {
        setCopyMessage("");
      }, 2000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <div className="row upi--p">
      {payment.error && isMobile ? (
        <div className="w-100">
          <ErrorCard error={payment.error} />
        </div>
      ) : null}
      <div
        className="col-lg-6 col-md-6 col-xl-6 pt-4 pb-4 px-5 upi--p1"
        style={{ borderRight: "0.5px solid #70707071" }}
      >
        <div>
          {isMobile && (
            <>
              <div className="amount-info text-center">
                Please deposit{" "}
                <span style={{ color: "#F0C451" }}>
                  ₹ {modal.depositData.amount}/-
                </span>
              </div>
              <p className="text--normal">
                Scan the QR Code or you can send using below UPI Id
              </p>
            </>
          )}
          <img loading="lazy" src="https://cdn.kubera365.com/kubera/frontend_images/payment_method/upi_1.webp" alt="qr-code" />
        </div>
        {/* {!isMobile && (
          <>
            <p className="text--medium mt-4">
              <span style={{ opacity: "0.75" }}>How to know your</span>{" "}
              <b>UTR Number</b>
            </p>
            <img  loading="lazy" src={gif} alt="gif" />
          </>
        )} */}
      </div>

      <div
        className="col-md-6 col-lg-6 col-xl-6 px-4 pt-3 pb-5 upi--p2"
        style={{ position: "relative" }}
      >
        {!isMobile && (
          <>
            <div className="amount-info text-center">
              Please deposit{" "}
              <span style={{ color: "#F0C451" }}>
                ₹ {modal.depositData.amount}/-
              </span>
            </div>
            <p className="scan-qr-statement">
              Scan the QR Code or you can send using below UPI Id
            </p>
          </>
        )}
        <form className="needs-validation">
          <div className="upi-id-div">
            <label className="text--medium text--light mb-2">
              Transfer your amount to this UPI Id:
            </label>
            <div className="d-flex upi_id">
              <p className="mb-0 text--medium" id="id_upi">
                {getUpiId()}
              </p>
              <button
                type="button"
                className="btn copy-btn"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="Tooltip on top"
                onClick={copyId}
              >
                <span className={copyMessage ? "copied" : "not-copied"}>
                  Copied
                  <div></div>
                </span>
                Copy
              </button>
            </div>
            <div className="mt-2">
              <span className="text--medium">Name: </span>
              {isMobile ? <br /> : null}
              <span className="text--medium mb-0">John Doe Enterprise</span>
            </div>
          </div>

          <label className="text--medium text--light mb-2 mt-4" id="utr_number">
            Enter the Transaction Reference Number(UTR) <span>*</span>
          </label>
          <div className="trn-utr mb-2" style={{ paddingRight: "1rem" }}>
            <input
              type="number"
              placeHolder="123456789123"
              className={redBorder ? "red-border form-control" : "form-control"}
              required
              onChange={(e) => {
                setReferenceNumber(e.target.value);
                setRedBorder(false);
              }}
            />
          </div>
          {referenceNumber.length !== 12 ? (
            <p
              className="text--medium"
              style={{ color: "#FF5252", paddingRight: "1rem" }}
            >
              Must key in 12 numeric number
            </p>
          ) : null}
          <p className="text--small text--light">
            Please make payment according to the Amount Stated on the QR page
            within the Limited Time, if there's any losses due to wrong amound
            paid or did not complete the whole payment process. COMPANY WILL NOT
            BEAR THE RESPONSIBILITY.
          </p>

          <div className="d-flex margin--bottom">
            <input
              className="form-check-input mt-0 checkbox-upi"
              type="checkbox"
              id="checkboxId"
              value=""
              aria-label="Checkbox for following text input"
              onChange={(e) => {
                setTos(e.target.checked);
                setValidationRemider(false);
              }}
              required
              style={
                checkValidation
                  ? { border: "1px solid red" }
                  : { border: "1px solid #707070" }
              }
            />
            <p
              className="text--medium text--light mx-2 checkbox-label-upi"
              style={{ marginTop: "3px" }}
            >
              I have read and agree with the terms of{" "}
              <span style={{ color: "#31B3FF" }}>
                <a
                  href="/privacy-policy"
                  style={{ textDecoration: "none", color: "#31B3FF" }}
                >
                  payment and withdrawal policy
                </a>
              </span>
            </p>
          </div>
          {payment.error && !isMobile ? (
            <ErrorCard error={payment.error} />
          ) : null}
          {/* <div style={{ marginBottom: "4.5rem" }}>
            {isMobile && (
              <>
                <p className="text--medium mt-2">
                  <span style={{ opacity: "0.75" }}>How to know your</span>{" "}
                  <b>UTR Number</b>
                </p>
                <img  loading="lazy" src={gif} className="mb-5 w-100" alt="gif" />
              </>
            )}
          </div> */}

          <ActionButtons toNext={goToNextStep} />
        </form>
      </div>
    </div>
  );
}
