import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateSelectedItem } from "../../features/slices/CommonSlice";
import { relogin, getUserData } from "../../features/slices/UserSlice";
export default function MobileNavbar() {
  const common = useSelector((state) => state.common);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [rotate, setRotate] = useState(false);
  const openLoginPopup = () => {
    if (common.selectedItem === "login") dispatch(updateSelectedItem(null));
    else dispatch(updateSelectedItem("login"));
    dispatch(relogin());
  };
  const openSignupPopup = () => {
    if (common.selectedItem === "signup") dispatch(updateSelectedItem(null));
    else dispatch(updateSelectedItem("signup"));
    dispatch(relogin());
  };
  const getFormattedBalance = (balance) => {
    if (balance) {
      if (typeof balance === "number") return balance.toLocaleString();
      if (!balance.includes(",")) {
        if (balance.includes(".")) return balance.toLocaleString();
        else {
          const intBalance = parseInt(balance);
          return intBalance.toLocaleString();
        }
      }
    }
    return "0";
  };

  const refreshBalance = async () => {
    // setRotate(true);
    // dispatch(getUserData(false));
    dispatch(updateSelectedItem("deposit"));
  };

  useEffect(() => {
    if (user.userData) setRotate(false);
  }, [user.userData]);
  return (
    <div className="d-flex px-2 justify-content-between">
      <a className="navbar-brand" href="/">
        <img
          loading="lazy"
          src={require("../../assets/images/logo.png")}
          alt="kubera"
          width={110}
          height={64}
          style={{ objectFit: "contain" }}
        />
      </a>
      {user.userLoggedIn ? (
        <>
          <div className="logged-in-user-data-in-header display--grid">
            <div className="balance-n-deposit d-flex">
              <div className="balance display--grid" onClick={refreshBalance}>
                <div>
                  <span>Bal:</span>
                  <br />
                  <span>
                    <b>
                      ₹
                      {user.userData.socketBalance ||
                        user.userData.redisBalance ||
                        user.userData.totalBalance}
                    </b>
                  </span>
                </div>
                <div
                  className={`balance-refresh-icon text-center ${
                    rotate ? "show" : ""
                  }`}
                >
                  <i
                    className={`bi bi-arrow-clockwise ${
                      rotate ? "rotate-icon" : ""
                    }`}
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="mobile-login-signup">
            <div className="btn-group d-flex">
              <div className="login-left w-50" onClick={openLoginPopup}>
                Login
              </div>
              <div className="signup-right w-50" onClick={openSignupPopup}>
                Signup
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
