import React from "react";
import { closeforgotPasswordModal } from "../../features/slices/ModalSlice";
import { useDispatch, useSelector } from "react-redux";
import "react-phone-number-input/style.css";
import ForgotPasswordActions from "./ForgotPasswordActions";
import Cookies from "js-cookie";
export default function ForgotPasswordModal() {
  const dispatch = useDispatch();

  const hideForgotPasswordModal = () => {
    dispatch(closeforgotPasswordModal());
  };
  const closeModal = (event) => {
    if (event.target.id === "fadeModal") dispatch(closeforgotPasswordModal());
  };

  return (
    <div
      className="modal fade show"
      style={{
        display: "block",
        backdropFilter: "blur(15px)",
        background: "rgb(70, 70, 70, 0.6)",
      }}
      id="fadeModal"
      tabIndex="-1"
      onClick={closeModal}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title fs-5">Forgot Password</h2>
            <i
              data-bs-dismiss="modal"
              aria-label="Close"
              className="bi bi-x-lg"
              onClick={hideForgotPasswordModal}
            ></i>
          </div>
          <div className="modal-body login-form">
            <ForgotPasswordActions />
          </div>
        </div>
      </div>
    </div>
  );
}
