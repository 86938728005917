import React from "react";
import paymentRejectdImage from "../../assets/images/payment-rejected.svg";
import paymentPendingImage from "../../assets/images/pending-payment.svg";
export default function PaymentStatus() {
  const urlParams = new URLSearchParams(window.location.search);
  const status = urlParams.get("status");
  let paymentStatusMessage = "";
  switch (status) {
    case "success":
      paymentStatusMessage = "Your balance will be updated soon";
      break;
    case "cancel":
      paymentStatusMessage = "Retry after some time";
      break;
    case "pending":
      paymentStatusMessage =
        "We are yet to get confirmation from the Payment Gateway. Please check the status after a while in Ledger";
      break;

    default:
      break;
  }

  const goHomeAndOpenDeposit = () => {
    localStorage.setItem("openDeposit", true);
    window.location.href = "/";
  };
  return (
    <div
      style={{
        background: "#0D0D0D",
        height: "800px",
      }}
      className="payment-status-parent"
    >
      <div className="payment-status text-center mt-5 p-4 border border-light border-opacity-10 m-auto rounded pt-5 pb-5">
        {status === "success" ? (
          <>
            <img
              loading="lazy"
              src={require("../../assets/images/payment-check.png")}
              alt="verification-complete"
              width={60}
            />
            <p className="mt-4">
              <strong>Payment Accepted</strong>
            </p>{" "}
          </>
        ) : status === "cancel" ? (
          <>
            <img
              loading="lazy"
              src={paymentRejectdImage}
              alt="payment-decline"
              width={60}
            />
            <p className="mt-4">
              <strong>Payment Unsuccessful</strong>
            </p>
          </>
        ) : status === "pending" ? (
          <>
            <>
              <img
                loading="lazy"
                src={paymentPendingImage}
                alt="payment-decline"
                width={60}
              />
              <p className="mt-4">
                <strong>Status Pending</strong>
              </p>
            </>
          </>
        ) : null}

        <p style={{ opacity: "0.5" }} className="text--medium">
          {paymentStatusMessage}
        </p>
        {status === "success" ? (
          <button className="standard-btn mt-3" style={{ width: "200px" }}>
            <a href="/" style={{ textDecoration: "none", color: "black" }}>
              Play Now
            </a>
          </button>
        ) : status === "cancel" ? (
          <button
            className="standard-btn mt-3"
            style={{ width: "200px" }}
            onClick={goHomeAndOpenDeposit}
          >
            Retry
          </button>
        ) : status === "pending" ? (
          <button className="standard-btn mt-3" style={{ width: "200px" }}>
            <a href="/" style={{ textDecoration: "none", color: "black" }}>
              Home
            </a>
          </button>
        ) : null}
      </div>
    </div>
  );
}
