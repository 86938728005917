import api from "../../api";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { startLoading, stopLoading } from "./LoadingSlice";
export const getCountryList = createAsyncThunk(
  "getCountries",
  async (_, { dispatch }) => {
    dispatch(startLoading());
    try {
      const response = await api.get(`/countries`);
      return response.data;
    } catch (error) {
      // Handle error condition
      throw Error("Error fetching user data");
    } finally {
      dispatch(stopLoading());
    }
  }
);
export const getCityList = createAsyncThunk(
  "getCities",
  async (data, { dispatch }) => {
    dispatch(startLoading());
    try {
      const response = await api.get(
        `/cities?countryId=${data.countryId}&stateId=${data.stateId}`
      );
      return response.data;
    } catch (error) {
      // Handle error condition
      throw Error("Error fetching user data");
    } finally {
      dispatch(stopLoading());
    }
  }
);
export const getStateList = createAsyncThunk(
  "getStates",
  async (payload, { dispatch }) => {
    dispatch(startLoading());
    try {
      const response = await api.get(`/states?countryId=${payload}`);
      return response.data;
    } catch (error) {
      // Handle error condition
      throw Error("Error fetching user data");
    } finally {
      dispatch(stopLoading());
    }
  }
);

const commonSlice = createSlice({
  name: "common",
  initialState: {
    selectedItem: null,
    overlayItem: null,
    isFromForgotPassword: false,
    isUserLoggedIn: false,
    countries: [],
    states: [],
    cities: [],
    error: null,
    showBalance: false,
    chatBox: false,
    initialOtpVerification: false,
    chatBoxDesktop: false,
  },
  reducers: {
    updateSelectedItem(state, action) {
      const item = action.payload;
      if (item?.initialOtpVerification) {
        state.selectedItem = item.name;
        state.initialOtpVerification = item.initialOtpVerification;
      }
      if (item?.name) {
        state.selectedItem = item.name;
        state.isFromForgotPassword = item.fromForgotPassword;
      } else {
        state.selectedItem = item;
        if (item !== "forgotPassword") state.isFromForgotPassword = false;
      }
    },
    updateOverlayItem(state, action) {
      state.overlayItem = action.payload;
    },
    emptyLists(state) {
      state.states = [];
      state.cities = [];
    },
    jumpToBalance(state) {
      state.showBalance = true;
    },
    hideChatBox(state) {
      state.chatBox = false;
    },
    openChatBox(state) {
      state.chatBox = true;
    },
    showChatBoxInDesktop(state) {
      state.chatBoxDesktop = true;
    },
    hideChatBoxDesktop(state) {
      state.chatBoxDesktop = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCountryList.fulfilled, (state, action) => {
        state.countries = action.payload.data;
      })
      .addCase(getCountryList.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(getCityList.fulfilled, (state, action) => {
        state.cities = action.payload.data;
      })
      .addCase(getCityList.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(getStateList.fulfilled, (state, action) => {
        state.states = action.payload.data;
      })
      .addCase(getStateList.rejected, (state, action) => {
        state.error = action.error.message;
      });
  },
});

export const {
  updateSelectedItem,
  updateOverlayItem,
  emptyLists,
  jumpToBalance,
  hideChatBox,
  openChatBox,
  showChatBoxInDesktop,
  hideChatBoxDesktop,
} = commonSlice.actions;

export default commonSlice.reducer;
export const { reducer } = commonSlice;
