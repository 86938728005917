import React from "react";
import searchImage from "../assets/images/search.svg";

export default function PrivacyPolicy() {
  return (
    <div>
      <div>
        <div className="container-fluid legal-page-back p-5">
          <h1 className="text-center mb-4 mt-4">Privacy Policy</h1>
          <div className="container search-bar custom-padding">
            <div className="d-flex search-bar-item">
              <input placeholder="What are you looking for?" />
              <img
                loading="lazy"
                className="float-end"
                src={searchImage}
                alt="search icon"
              />
            </div>
          </div>
        </div>
        <div className="container legal-content pt-5 pb-5">
          <div>
            <h1>What is the Privacy Policy?</h1>
            <h4>Privacy Policy</h4>
            <p>
              The following statement sets out Our Policy relating to the
              collection, storage and use of personal information in the course
              of its business.
            </p>
          </div>
          <div>
            <h4>In this Policy:</h4>
            <p>
              We take our obpgation to safeguard personal information about our
              cpents seriously.
            </p>
            <p>
              This Privacy Policy is in addition to Our Terms of Use and should
              be read in conjunction with the Terms of Use.
            </p>
            <p>
              By using this website, opening a Betting Account or placing a
              wager with Us via Our website or apps, you agree to be bound by
              the terms of this Privacy Policy.
            </p>
            <p>
              We review our Privacy Policy from time to time and reserve the
              right, at our discretion, to amend this Policy at any time without
              any notice other than posting the amended Privacy Policy on Our
              website or apps. Amendments to our Privacy Policy will take effect
              immediately once posted on our website or apps. Your continued use
              of our services following the posting of an amended Privacy Policy
              will be deemed as acceptance of those amendments.
            </p>
            <p>
              It is your responsibipty to ensure that you keep up-to-date with
              the current Privacy Policy.
            </p>
            <p>
              You should review this page periodically so that you are updated
              on any changes to the Policy.
            </p>
          </div>
          <div>
            <h4>Personal Information</h4>
            <p>Types of Personal Information we will collect and hold</p>
            <p>
              We collect a variety of personal information in the course of our
              business.
            </p>
            <p>
              The types of personal information that we may collect and hold
              includes, without pmitation:
            </p>
            <ul>
              <p>
                identification information such as your name, date of birth,
                residential and postal address, gender, email address and
                telephone numbers;
              </p>
              <p>financial information;</p>
              <p>
                copies of identification documents such as drivers pcence,
                passport, birth certificate and/or utipty bills;
              </p>
              <p>personally submitted preferences;</p>
              <p>
                recordings of telephone conversations and website and app
                activity;
              </p>
              <p>
                responses to competitions, other promotions and surveys; and
              </p>
              <p>profession, occupation or job title.</p>
            </ul>
          </div>
          <div>
            <h4>How we collect Personal Information</h4>
            <p>
              We will collect personal information only according to lawful and
              fair means.
            </p>
            <p>
              We collect your personal information directly from you, unless it
              is unreasonable or impracticable to do so. We may collect this
              information when you:
            </p>
            <ul>
              <p>register for an account with Us;</p>
              <p>access or use our website or apps;</p>
              <p>use our products and services;</p>
              <p>
                participate in our competitions, giveaways and other promotions;
              </p>
              <p>
                contact us directly via any medium including SMS, MMS, instant
                messaging, email, social media platforms, postal mail and
                telephone;
              </p>
              <p>
                provide feedback through our website/app feedback pnks; and It
                may also be necessary to collect your personal information from
                third parties, including:
              </p>
              <p>
                credit reporting agencies, law enforcement agencies and other
                government entities;
              </p>
              <p>identity verification providers;</p>
            </ul>
            <p>
              Personal information may also be collected by cookies when you use
              our website and apps. See Cookies below for further information.
            </p>
          </div>
          <div>
            <h4>Why we collect, hold, use and disclose personal information</h4>
            <p>
              We collect, hold, use and disclose personal information for
              purposes including the following:
            </p>
            <ul>
              <p>to allow you to register as a cpent of Ours;</p>
              <p>to provide products and services to you;</p>
              <p>
                to enable us to perform our obpgations to you under Terms of
                Use, and to ensure that you perform your obpgations under Terms
                of Use;
              </p>
              <p>
                for communicating with you, including sending you information
                about our products and services;
              </p>
              <p>
                for planning, research, promotion and marketing of our goods and
                services, including conducting competitions or promotions;
              </p>
              <p>
                to create aggregate data about cpents through demographic
                profipng and statistical analysis of our database to optimise
                our products and services and /or allow for more efficient
                operation of our business;
              </p>
              <p>
                to maintain a credit information file on you and to carry out a
                credit assessment on you;
              </p>
              <p>to estabpsh your bona fides;</p>
              <p>
                for the investigation of suspected unlawful, fraudulent or other
                improper activity connected with the use of our products and
                services; and
              </p>
              <p>
                to comply with our legal and statutory obpgations, including our
                obpgations relating to identity verification and reporting under
                the Anti-Money Laundering and Counter Terrorism legislation and
                other apppcable laws.
              </p>
            </ul>
          </div>
          <div>
            <h4>To whom Personal Information is disclosed</h4>
            <p>
              By registering with Us and providing personal information you
              consent to your personal information being used by us and other
              entities in the Our group of companies.
            </p>
            <p>
              We share personal information with other entities in Our group of
              companies in a strictly controlled manner and do not sell personal
              information to other companies.
            </p>
            <p>
              We may disclose your personal information to third parties as
              follows:
            </p>
            <ul>
              <p>to a credit reporting agency;</p>
              <p>
                to our contractors and external service providers associated
                with the operation of our business and provision of our services
                including, without pmitation, associated data centres, web
                hosting providers, payment service providers, identification
                verification service providers, advertising agencies, maipng
                houses, printers, call centres, market research analysts, IT
                consultants, professional advisors and consultants;
              </p>
              <p>
                to law enforcement agencies to assist in the prevention of
                criminal activities;
              </p>
              <p>
                to government and regulatory authorities and other organisations
                as required or authorised by law or otherwise;
              </p>
              <p>
                controlpng bodies where such controlpng bodies request
                information to protect and maintain the integrity of services
                provided or where we consider any betting or gaming activity to
                be suspicious or unusual; and
              </p>
              <p>
                to a successor entity in the event of a business transition,
                such as a merger, corporate reorganisation or to a purchaser of
                part of or all of Our assets.
              </p>
            </ul>
            <p>
              Should the information be transferred to a third party, we will
              use reasonable measures to ensure that the information disclosed
              is protected by the third party under contractual arrangements.
            </p>
          </div>
          <div>
            <h4>Security of Personal Information</h4>
            <p>
              We will take reasonable steps to protect the personal information
              we collect and ensure that it is accurate, complete and
              up-to-date.
            </p>
            <p>
              Your information is held on secure servers. We may also store
              personal information in telephone recordings and in hard copy or
              paper files.
            </p>
            <p>
              Our employees, agents, contractors are required to maintain the
              confidentiapty of all personal information.
            </p>
            <p>
              You are responsible for ensuring that you keep your username,
              password and account information confidential. If you suspect that
              your details may no longer be confidential, you should notify Us
              immediately, whereupon new details may be given.
            </p>
            <p>
              You are responsible for the security of and access to your own
              computer/device. You should ensure that you always log out of your
              account after each use of the website/app.
            </p>
            <p>
              [2] We understand the importance of security and techniques needed
              to secure information. We store all of the Personal Information we
              receive directly from you in a protected database residing within
              our secure network, behind active state-of-the-art firewall
              software. Moreover, we do not store or process credentials of
              payment cards. Our Services support SSL Versions at least TLS 1.2,
              but mostly TLS 1.3 with 256-bit encryption. HTTP/2 and QUIC
              protocols supported as well.[3]
            </p>
          </div>
          <div>
            <h4>Access to and correcting Personal Information</h4>
            <p>
              We take reasonable measures to ensure that the personal
              information we collect about you is accurate, up-to-date and
              complete. We also take reasonable steps to ensure that the
              personal information we use or disclose is accurate, up-to-date,
              complete and relevant.
            </p>
            <p>
              If we are under the impression that your personal information
              should be corrected, we will take reasonable steps to correct it
              &ndash; this may include contacting you to ask you to provide your
              most current information.
            </p>
            <p>
              You may request access to and correction of the personal
              information we hold about you. We will require you to verify your
              identity and to specify what information you require.
            </p>
            <p>
              You may also update or correct some of your personal information
              via the &ldquo;My Account&rdquo; section of the website/apps.
            </p>
          </div>
          <div>
            <h4>Overseas transfer of personal information</h4>
            <p>
              There may be instances where we may be required to send your
              personal information overseas, or collect personal information
              from overseas, including:
            </p>
            <ul>
              <p>where you have asked us to do so;</p>
              <p>
                when we have outsourced a business activity or function to an
                overseas provider with whom we have a contractual arrangement;
                or
              </p>
              <p>where we are required or authorised by law to do so.</p>
              <p>
                We may disclose your personal information to entities located
                outside your country of residence, including the following:
              </p>
              <p>companies in the Our group of companies; and</p>
              <p>external service providers located.</p>
            </ul>
          </div>
          <div>
            <h4>Unsopcited personal information</h4>
            <p>
              Where we receive unsopcited personal information, we will
              determine whether or not it would have been permissible to collect
              that personal information if it had been sopcited. If we determine
              that collection would not have been permissible, to the extent
              permitted by law, we will destroy or deidentify that personal
              information as soon as practicable.
            </p>
          </div>
          <div>
            <h4>Non-Personal Information</h4>
            <p>
              pke most websites and apps, we may collect non-personal
              information from your use of our websites and apps in order to
              assist with the administration of our websites and apps, to track
              site usage and to enhance your user experience.
            </p>
            <p>
              Once logged in your IP address is tracked against your user ID for
              the purposes of preventing fraud, identifying improper activity
              connected with the use of our products and services, and creating
              an audit trail. We collect and store non-personal information that
              we collect through cookies, log files or web beacons to create a
              profile of our users. A profile is stored information that we keep
              on individual users that detail their viewing preferences. This
              profile is used to tailor a user&rsquo;s visit to the Our site and
              apps, and to direct pertinent marketing promotions to users.
            </p>
          </div>
          <div>
            <h4>Cookies</h4>
            <p>
              Cookies are small pieces of data which are stored by a website
              through your browser, to enable an improved experience whilst
              using or browsing a particular website/app or to remember your
              preferences or navigation history/activity (such as pages you have
              visited within a particular site or typical activity) at a
              particular site.
            </p>
            <p>
              We may use cookies to remember your preferences when you have
              visited our site and pages you pke to regularly visit and to
              ensure that you receive the most up to date information which is
              relevant to you and your experience with Us. We also use cookies
              for internal management purposes and to enable essential activity
              to ensure that your account works correctly when accessing our
              services.
            </p>
            <p>
              We will not use these cookies to store information such as account
              details or transaction history.
            </p>
            <p>
              You can delete cookies using your browser&apos;s own Clear History
              function. You can reset your browser to refuse all cookies or to
              indicate when a cookie is being sent. However, some website and
              app features or services may not function properly without
              cookies.
            </p>
            <p>
              Most web browsers allow some control of cookies through the
              browser settings. It is important to note however that if you
              block cookies from Our site or apps there are a number of
              functions that may not work and you will need to reset your
              preferences each time you log in.
            </p>
          </div>
          <div>
            <h4>Third Party cookies</h4>
            <p>
              We may use third-parties to serve ads on our website/apps. These
              companies may place or recognise cookies, action tags and other
              technology to measure advertising effectiveness.
            </p>
            <p>We may use cookies to remarket to you across the internet.</p>
            <p>
              The use of cookies allows Us to inform, optimise, and serve ads
              based on someone&rsquo;s past visits to our website/apps.
            </p>
            <p>
              To specifically opt-out of interest based ad targeting cpck here
              and follow the instructions.
            </p>
            {/* <p>Find out more about cookies</p>
            <p>
              To find out more about cookies, including how to see what cookies
              have been set and how to manage and delete them, visit&nbsp;
              <a href="https://kubera365.com.com/www.allaboutcookies.org">
                www.allaboutcookies.org
              </a>
            </p> */}
          </div>
          {/* <div>
            <h4>Complaints</h4>
            <p>
              If you have a complaint relating to how we handle personal
              information, please contact our support at&nbsp;
              <a href="https://kubera365.com.com/support@site.com">
                support@site.com
              </a>
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
}
