import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  updateSelectedItem,
  jumpToBalance,
} from "../../features/slices/CommonSlice";
import { useMediaQuery } from "react-responsive";
export default function Completed({ closeModal, fromBankVerification }) {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const modal = useSelector((state) => state.modal);
  const common = useSelector((state) => state.common);
  const payment = useSelector((state) => state.payment);
  const gotoWallet = () => {
    if (!window.location.href.includes("/profile")) {
      const domainURL =
        window.location.protocol + "//" + window.location.hostname;
      window.location.href = `${domainURL}/profile`;
      localStorage.setItem("showBalance", true);
    } else {
      dispatch(jumpToBalance());
    }
    if (!isMobile) closeModal();
    else {
      dispatch(updateSelectedItem(null));
    }
  };
  return (
    <div className="request-completed">
      <div className="text-center">
        <img
          loading="lazy"
          src={require("../../assets/images/payment-check.png")}
          alt="verification-complete"
          width={80}
        />
        <p className="text---large mt-4">
          {payment.depositMessage || payment.withdrawMessage}
        </p>
        <p className="text--medium text--light">
          {`${
            modal.paymentType === "Withdrawl" ||
            common.selectedItem === "withdrawCompleted"
              ? "Your amount will reflect in your Bank Account within 5 minutes."
              : "Your amount will reflect in wallet within 5 minutes."
          }`}
          <b
            style={{ color: "#31B3FF", cursor: "pointer" }}
            onClick={gotoWallet}
          >
            Check Now
          </b>
        </p>
      </div>
    </div>
  );
}
