import React from "react";
export default function BankAccountAddedPopup({ closeModal }) {
  return (
    <div
      className={`modal fade show otp--modal`}
      style={{ display: "block", backdropFilter: "blur(15px)" }}
      tabIndex="-1"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content" style={{ width: "100%" }}>
          <div className="modal-header">
            <h2 className="modal-title fs-5"></h2>
            <i onClick={closeModal} className="bi bi-x-lg"></i>
          </div>
          <div className="modal-body mobile-otp-modal--body text-center">
            <div className="text-center">
              <img
                loading="lazy"
                src={require("../../assets/images/payment-check.png")}
                alt="complete"
                width={80}
              />
              <p className="text--normal mt-4">Bank added successfully. </p>
              <p className="text--medium">
                Bank account will be available after verification.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
