import React, { useRef, useState, useEffect } from "react";
import searchImage from "../../assets/images/search.svg";
import filtersIcon from "../../assets/images/filter-arrows.svg";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedItem } from "../../features/slices/CommonSlice";
import { showFilterMenu } from "../../features/slices/FilterSlice";
import { searchGames } from "../../features/slices/GamesSlice";
import { openLoginModal } from "../../features/slices/ModalSlice";
import { relogin } from "../../features/slices/UserSlice";
export default function (props) {
  const dispatch = useDispatch();
  const divRef = useRef(null);
  const game = useSelector((state) => state.game);
  const user = useSelector((state) => state.user);
  const [searchResults, setSearchResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedGame, setSelectedGame] = useState("");
  const [timer, setTimer] = useState(null);
  const [loader, setLoader] = useState(false);

  const showSearchResults = (event) => {
    setShowDropdown(true);
    setSelectedGame(event.target.value);
    let delay = timer;
    clearTimeout(delay);
    delay = setTimeout(() => {
      if (event.target.value.length >= 3) {
        setLoader(true);
        dispatch(searchGames({ gameName: event.target.value }));
      } else {
        setSearchResults([]);
        setShowDropdown(false);
      }
    }, 500);
    setTimer(delay);
  };
  const setValueToInputField = (event) => {
    setSelectedGame(event.target.textContent);
    setSearchResults([]);
    setShowDropdown(false);
  };

  const showFilters = () => {
    dispatch(showFilterMenu());
    dispatch(updateSelectedItem("filter"));
  };
  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    let results = [];
    if (game.searchedGames && game.searchedGames.length) {
      game.searchedGames.forEach((element) => {
        results = [...results, ...element.games];
      });
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
    setLoader(false);
  }, [game.searchedGames]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const storeGame = (game) => {
    localStorage.setItem("game", JSON.stringify(game));
    dispatch(openLoginModal("login"));
    dispatch(relogin());
  };
  const styles = {
    width: "100%",
  };
  const iconStyles = {
    right: "10px",
  };
  const loaderStyles = { width: "20px", height: "20px", marginTop: "18px" };
  const loaderStylesCustom = {
    width: "20px",
    height: "20px",
    marginTop: "18px",
    right: "100px",
  };

  return (
    <div className="container-fluid search-bar custom-padding">
      <div className="d-flex search-bar-item">
        <input
          type="text"
          placeholder="Search Your Favourite Game..."
          value={selectedGame}
          style={props.isHome ? styles : {}}
          onChange={showSearchResults}
        />
        {loader ? (
          <div
            className="spinner-border float-right"
            style={props.isHome ? loaderStyles : loaderStylesCustom}
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <img
            loading="lazy"
            style={props.isHome ? iconStyles : {}}
            className="float-right"
            src={searchImage}
            alt="search icon"
          />
        )}
        {!props.isHome ? (
          <button
            className="standard-btn text-center mobile-filter"
            onClick={showFilters}
          >
            <img loading="lazy" src={filtersIcon} alt="filter" />
            Filter
          </button>
        ) : null}
        {showDropdown && searchResults.length ? (
          <div
            ref={divRef}
            className="search--results border border-light border-opacity-25 pt-5 px-3"
          >
            <ul>
              {searchResults.map((item, index) =>
                user.userLoggedIn ? (
                  <a href={`/play-game/${item.opentableId}`} key={index}>
                    <li className="pb-4">{item.name}</li>
                  </a>
                ) : (
                  <li
                    style={{ listStyle: "none", cursor: "pointer" }}
                    className="pb-4"
                    key={index}
                    onClick={() => storeGame(item)}
                  >
                    {item.name}
                  </li>
                )
              )}
            </ul>
          </div>
        ) : (
          showDropdown && (
            <div
              ref={divRef}
              className="search--results border border-light border-opacity-25 py-5 px-4"
            >
              No Match Found
            </div>
          )
        )}
      </div>
    </div>
  );
}
