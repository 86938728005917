import React, { useState, useEffect } from "react";
import { closeAddBankModal } from "../../features/slices/ProfileSlice";
import { useDispatch, useSelector } from "react-redux";
import api from "../../api";
import { addBankDetails, reset } from "../../features/slices/PaymentSlice";
import { getUserData } from "../../features/slices/UserSlice";
import { useMediaQuery } from "react-responsive";
import BankAccountAddedPopup from "../common/BankAccountAddedPopup";
import { startLoading, stopLoading } from "../../features/slices/LoadingSlice";

export default function NewBankAccount() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const payment = useSelector((state) => state.payment);
  const user = useSelector((state) => state.user);
  const [timer, setTimer] = useState(null);
  const [validate, setValidate] = useState(false);
  const [ifsc, setIfsc] = useState("");
  const [account, setAccount] = useState("");
  const [name, setPersonName] = useState("");
  const [error, setError] = useState("");
  const [validated, setValidated] = useState(false);
  const [ifscInfo, setIfscGotInfo] = useState("");
  const [localError, setLocalError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [bankAdded, setBankAdded] = useState(false);

  const closeModal = () => {
    dispatch(closeAddBankModal());
  };
  const addNewBank = (e) => {
    e.preventDefault();
    const form = document.querySelector(".needs-validation");
    if (!account || !name) setLocalError("Please fill in the all information");
    else setLocalError("");
    if (ifsc.length !== 11) setLocalError("Ifsc number is not valid!");
    if (!validated) return;

    const payload = {
      accountName: name,
      accountNumber: +account,
      ifscCode: ifsc,
    };
    dispatch(addBankDetails(payload));
    // else return;
  };

  const validateIfsc = (e) => {
    setError("");
    setLocalError("");
    setValidate(false);
    setIfsc(e.target.value);
    setValidated(false);
    let delay = timer;
    clearTimeout(delay);
    delay = setTimeout(() => {
      if (e.target.value.length == 11) {
        setValidate(true);
        setIfsc(e.target.value);
        console.log("yesss");
      }
    }, 300);
    setTimer(delay);
  };

  const setName = (e) => {
    setPersonName(e.target.value);
    setLocalError("");
  };
  const setAccountNo = (e) => {
    setLocalError("");
    dispatch(reset());
    setAccount(e.target.value);
  };

  useEffect(() => {
    dispatch(reset());
  }, []);

  useEffect(() => {
    if (ifsc && account && name) setLocalError("");
  }, [ifsc, account, name]);

  useEffect(() => {
    if (validate) {
      const validateIFSC = async () => {
        try {
          dispatch(startLoading());
          const response = await api.get(
            `/payment/verifyIfscCode?ifscCode=${ifsc}`
          );
          setValidated(true);
          setIfscGotInfo(response.data.data.message);
          console.log(response);
        } catch (error) {
          console.log(error);
          setError(error?.response?.data?.error || "Request Failed");
        } finally {
          dispatch(stopLoading());
        }
      };
      validateIFSC();
    }
  }, [validate]);

  return (
    <>
      <div
        className="modal fade show new-account"
        style={{
          display: "block",
          backdropFilter: "blur(15px)",
          background: "rgb(70, 70, 70, 0.6)",
        }}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title fs-5">Add Bank Account</h2>

              <i onClick={closeModal} className="bi bi-x-lg"></i>
            </div>
            <div className="modal-body p-4">
              <form className="needs-validation">
                {successMessage ? (
                  <div
                    className="mt-4 p-3 border border-light border-opacity-25 rounded mb-3"
                    style={{
                      color: "green",
                      background: "rgb(20,20,20)",
                    }}
                  >
                    <p className="text--medium mb-0">
                      <i class="bi bi-info-circle"></i> {successMessage}
                    </p>
                  </div>
                ) : null}
                {payment.error || localError ? (
                  <div
                    className="mt-4 p-3 border border-light border-opacity-25 rounded mb-3"
                    style={{
                      color: "rgb(255, 120, 120)",
                      background: "rgb(20,20,20)",
                    }}
                  >
                    <p className="text--medium mb-0">
                      <i class="bi bi-exclamation-circle"></i> Error:{" "}
                      {payment.error || localError}
                    </p>
                  </div>
                ) : null}
                <div className="form">
                  <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12 col-12">
                      <label
                        className="text--medium mb-3"
                        style={{ fontWeight: "200" }}
                      >
                        Account Holder Name
                      </label>
                      <input
                        type="text"
                        placeholer="Enter Account Holder Name"
                        defaultValue=""
                        className="form-control"
                        onChange={(e) => setName(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-6 col-sm-12 col-lg-6">
                      <label
                        className="text--medium mb-3"
                        style={{ fontWeight: "200" }}
                      >
                        Account Number
                      </label>
                      <input
                        type="number"
                        placeholer="Enter Account Number"
                        defaultValue=""
                        onChange={(e) => setAccountNo(e)}
                        required
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 col-lg-6">
                      <label
                        className="text--medium mb-3 if--sc--label"
                        style={{ fontWeight: "200" }}
                      >
                        IFSC Code
                      </label>
                      <div className="flex if--info">
                        <input
                          type="text"
                          placeholer="Enter IFSC"
                          defaultValue=""
                          required
                          className="form-control"
                          onChange={(e) => validateIfsc(e)}
                        ></input>
                        {validated ? <i className="bi bi-check2 mx-2"></i> : ""}
                      </div>
                      {error.length ? (
                        <p className="text--light text--medium mb-0 mt-2">
                          <i class="bi bi-exclamation-circle text-danger mx-2"></i>{" "}
                          Error: {error}
                        </p>
                      ) : (
                        ""
                      )}
                      {validated && !error.length ? <p>{ifscInfo}</p> : ""}
                    </div>
                  </div>
                </div>
                <div className="text-end">
                  <button
                    className="standard-btn add-new-bank w-50 mt-4 mb-2"
                    type="submit"
                    onClick={(e) => addNewBank(e)}
                  >
                    Add Bank Account
                  </button>
                  <p
                    className="text--medium"
                    style={{ fontWeight: "300", color: "#E42D2D" }}
                  >
                    Note: Bank account will be available after verification
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
