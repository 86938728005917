import React, { useState, useEffect } from "react";
import wallet from "../../assets/images/wallet.svg";
import withdraw from "../../assets/images/withdraw.svg";
import verified from "../../assets/images/verified.png";
import presentBox from "../../assets/images/present-box.png";
import aviatrixLogo from "../../assets/images/aviatrix-logo.png";
import hamburger from "../../assets/images/hamburger.svg";
import activeWalletIcon from "../../assets/images/activeWalletIcon.svg";
import Cookies from "js-cookie";
import {
  openLoginModal,
  openSignupModal,
  closeMobileOtpModal,
  closePaymentCompletionModal,
  openPaymentModal,
} from "../../features/slices/ModalSlice";
import { getUserData, relogin } from "../../features/slices/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchSubCategories } from "../../features/slices/GamesSlice";
import {
  hideChatBox,
  updateSelectedItem,
} from "../../features/slices/CommonSlice";
import { reset } from "../../features/slices/PaymentSlice";
export default function DesktopNavbar() {
  // const common = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const game = useSelector((state) => state.game);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  const [name, setName] = useState(null);
  const [rotate, setRotate] = useState(false);

  const handleMouseEnter = (name) => {
    setName(name);
  };

  const handleMouseLeave = (name) => {
    setName(null);
  };
  const openLoginPopup = () => {
    dispatch(openLoginModal("login"));
    dispatch(relogin());
    dispatch(hideChatBox());
  };
  const openSignupPopup = () => {
    dispatch(closeMobileOtpModal());
    dispatch(openSignupModal());
    dispatch(relogin());
    dispatch(hideChatBox());
  };

  const showSubCategories = (id) => {
    if (game.subCategories.length) {
      if (game.subCategories[0].categoryId !== id) {
        setSubCategories([]);
        dispatch(fetchSubCategories(id));
        return;
      }
    } else {
      dispatch(fetchSubCategories(id));
    }
  };

  useEffect(() => {
    setSubCategories(game.subCategories);
  }, [game.subCategories]);

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  useEffect(() => {
    const token = Cookies.get("token");
    if (token) setIsLoggedIn(true);
    else setIsLoggedIn(false);
    setRotate(false);
  }, [user]);

  const showModal = () => {
    dispatch(openPaymentModal("Deposit"));
    dispatch(updateSelectedItem(null));
    dispatch(closePaymentCompletionModal());
    dispatch(reset());
  };

  const getFormattedBalance = (balance) => {
    if (balance) {
      if (typeof balance === "number") return balance.toLocaleString();
      if (!balance.includes(",")) {
        if (balance.includes(".")) return balance.toLocaleString();
        else {
          const intBalance = parseInt(balance);
          return intBalance.toLocaleString();
        }
      }
    }
    return "0";
  };

  const refreshBalance = async () => {
    setRotate(true);
    dispatch(getUserData({ source: "refresh_balance", showLoader: false }));
  };

  useEffect(() => {
    if (user.userData) setRotate(false);
  }, [user.userData]);

  return (
    <nav className="navbar navbar-expand-lg custom-header px-4 pt-0 pb-0">
      <div className="container-fluid d-flex px-5">
        <a className="navbar-brand" href="/">
          <img
            loading="lazy"
            src={require("../../assets/images/logo.png")}
            alt="kubera"
            width={110}
            height={64}
            style={{ objectFit: "contain" }}
          />
        </a>
        <div className="menu-items me-auto d-flex mx-3">
          {game.categories.length
            ? game.categories.map((item, index) => (
                <div className="dropdown" key={index}>
                  <div
                    // onClick={() => showSubCategories(item.id)}
                    onMouseEnter={() => handleMouseEnter(item.name)}
                    onMouseLeave={() => handleMouseLeave(item.name)}
                    className={`menu-item dropdown-toggle ${
                      name === item.name ? "active-menu-item" : ""
                    }`}
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span>
                      {capitalizeFirstLetter(item.name)}{" "}
                      <i className="bi bi-chevron-down"></i>
                    </span>
                  </div>
                  <div
                    className={`dropdown-menu sub-menu-dropdown${
                      name === item.name ? " show" : ""
                    }`}
                    onMouseEnter={() => handleMouseEnter(item.name)}
                    onMouseLeave={() => handleMouseLeave(item.name)}
                  >
                    <a href={`/${item.name}`}>
                      <p>{capitalizeFirstLetter(item.name)}</p>
                    </a>
                    <hr />
                    <ul>
                      {item.subcategoryData.length
                        ? item.subcategoryData.map((subCat, ind) => (
                            <a href={`/${item.name}/${subCat.name}`} key={ind}>
                              <li>{capitalizeFirstLetter(subCat.name)}</li>
                            </a>
                          ))
                        : ""}
                    </ul>
                  </div>
                </div>
              ))
            : ""}
          <a href="/play-aviatrix">
            <div className="menu-item">
              <span>
                <img src={aviatrixLogo} />
              </span>
            </div>
          </a>
          <a href="/all-games">
            <div className="menu-item">
              <span>All Games</span>
            </div>
          </a>
        </div>
        {isLoggedIn ? (
          <div className="d-flex logged-in-user-data-in-header">
            {/* <div className="text-center px-3 gift-icon display--grid dropdown">
              <i
                className="bi bi-gift-fill dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              ></i>
              <ul className="dropdown-menu">
                <strong>Gifts</strong>
                <li className="d-flex">
                  <img  loading="lazy" src={presentBox} alt="wallet" />
                  <div>
                    <b>Daily Bonus(Day 3)</b>
                    <p>You won ₹50 as a Daily Bonus.</p>
                  </div>
                </li>
                <li className="d-flex">
                  <img  loading="lazy" src={presentBox} alt="wallet" />
                  <div>
                    <b>Daily Bonus(Day 3)</b>
                    <p>You won ₹50 as a Daily Bonus.</p>
                  </div>
                </li>
                <li className="d-flex">
                  <img  loading="lazy" src={presentBox} alt="wallet" />
                  <div>
                    <b>Daily Bonus(Day 3)</b>
                    <p>You won ₹50 as a Daily Bonus.</p>
                  </div>
                </li>
              </ul>
            </div>
            <div
              className="text-center px-3 bell-icon dropdown"
              style={{ marginRight: "30px" }}
            >
              <div
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="bi bi-bell-fill"></i>
                <span className="notification-number display--grid">2</span>
              </div>
              <ul className="dropdown-menu">
                <strong>Notifications</strong>
                <li className="d-flex">
                  <img  loading="lazy" src={wallet} alt="wallet" />
                  <div>
                    <b className="deposit">Deposit</b>
                    <p>₹5000.00 has been deposited successfully</p>
                  </div>
                </li>
                <li className="d-flex">
                  <img  loading="lazy" src={withdraw} alt="wallet" />
                  <div>
                    <b className="withdraw">Withdraw</b>
                    <p>₹5000.00 has been withdrawn successfully</p>
                  </div>
                </li>
                <li className="d-flex">
                  <img  loading="lazy" src={verified} alt="wallet" />
                  <div>
                    <b className="verified">Verified</b>
                    <p>Your ID Proof has been verified Successfully</p>
                  </div>
                </li>
              </ul>
            </div> */}
            {/* <div className="text-center px-3 header-username display--grid">
              <span>{user.userData.username || ""}</span>
            </div> */}
            <div>
              <div className="balance-n-deposit d-flex">
                <div
                  className="balance display--grid balance-refresh"
                  onClick={refreshBalance}
                >
                  <div>
                    <span>Bal:</span>
                    <br />
                    <span className="balance-value">
                      <b>
                        ₹
                        {user.userData.socketBalance ||
                          user.userData.redisBalance ||
                          user.userData.totalBalance}
                      </b>
                    </span>
                  </div>
                  <div
                    className={`balance-refresh-icon text-center ${
                      rotate ? "show" : ""
                    }`}
                  >
                    <i
                      className={`bi bi-arrow-clockwise ${
                        rotate ? "rotate-icon" : ""
                      }`}
                    ></i>
                  </div>
                </div>
                <div
                  className="deposit d-flex"
                  style={{
                    fontSize: "14px",
                    lineHeight: "0.4cm",
                  }}
                  onClick={showModal}
                >
                  <img
                    loading="lazy"
                    src={activeWalletIcon}
                    width={28}
                    style={{ marginRight: "5px" }}
                    alt="wallet"
                  />
                  Deposit Now
                </div>
              </div>
            </div>
            <img
              loading="lazy"
              className="hamburger"
              src={hamburger}
              alt="menu"
              width={30}
              data-bs-toggle="offcanvas"
              data-bs-target="#sidebarMenu"
              aria-controls="offcanvasRight"
            />
          </div>
        ) : (
          <div className="right-menu-items d-flex">
            <div className="d-flex login-signup-btns">
              <button className="login-btn mx-2" onClick={openLoginPopup}>
                Login
              </button>

              <button className="signup-btn mx-2" onClick={openSignupPopup}>
                Signup
              </button>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
}
