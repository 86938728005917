import { createSlice } from "@reduxjs/toolkit";
const filterSlice = createSlice({
  name: "filter",
  initialState: {
    isFilterMenuVisible: false,
  },
  reducers: {
    showFilterMenu: (state) => {
      state.isFilterMenuVisible = true;
    },
    hideFilterMenu: (state) => {
      state.isFilterMenuVisible = false;
    },
  },
});

export const { showFilterMenu, hideFilterMenu } = filterSlice.actions;

export default filterSlice.reducer;
