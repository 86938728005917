import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import {
  hideKeepWorkingModal,
  loginUser,
  refreshJwtToken,
  relogin,
  showKeepWorkingModal,
} from "../../features/slices/UserSlice";
import Cookies from "js-cookie";
import moment from "moment";
export default function KeepWorkingPopup({ closeModal }) {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [remainingTime, setRemainingTime] = useState(0);
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  // Function to update the remaining time every second
  useEffect(() => {
    const countdown = localStorage.getItem("countdown");
    if (!countdown) {
      localStorage.setItem("countdown", moment().format());
      setRemainingTime(5 * 60);
    } else {
      const current = moment(moment().format());
      const difference = current.diff(moment(countdown), "seconds");
      setRemainingTime(300 - difference);
    }
    const interval = setInterval(() => {
      setRemainingTime((prevTime) => prevTime - 1);
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);
  // const closeModal = () => {
  //   dispatch(hideKeepWorkingModal());
  // };
  const refreshToken = () => {
    localStorage.removeItem("countdown");
    const refreshToken = Cookies.get("refreshToken");
    const payload = {
      grantType: "refreshToken",
      refreshToken: refreshToken || "",
    };
    dispatch(refreshJwtToken(payload));
    // dispatch(hideKeepWorkingModal());
    // setTimeout(() => {
    //   dispatch(showKeepWorkingModal());
    // }, 5000);
  };
  return (
    <div
      className={`modal fade show payment-completion-modal`}
      style={{
        display: "block",
        backdropFilter: "blur(15px)",
        background: "rgb(70, 70, 70, 0.6)",
      }}
      tabIndex="-1"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content alert-popup">
          <div className="modal-body p-4">
            <div>
              <p className="text--medium">
                Your session will be timed out in {formatTime(remainingTime)}.
                You will be logged out after that(Please be careful).
              </p>
            </div>
            <div className="btns mt-5">
              <div className={isMobile ? "" : "float-end"}>
                <div className={isMobile ? "d-block" : "d-flex"}>
                  <button
                    className={`close-btn ${isMobile ? "mb-3" : ""}`}
                    onClick={() => closeModal()}
                    style={{ width: "130px", marginLeft: "0" }}
                  >
                    Ok
                  </button>
                  <button
                    className={`use-here-btn`}
                    onClick={refreshToken}
                    style={{ width: "130px", marginRight: "0" }}
                  >
                    Keep Playing
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
