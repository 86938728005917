import React from "react";

export default function LedgerItems({ items, clickItem }) {
  return (
    <div className="ledger--sm">
      <div>
        <p>Ledger</p>

        {items.map((item, index) => {
          return (
            <div
              className="d-flex sub--menu-item border border-light border-opacity-25"
              key={index}
              id={item.id}
              onClick={() => clickItem(item.id, "ledger")}
            >
              <div className="w-100 d-flex">
                <i className="bi bi-check-lg"></i>
                <p className="text--normal mx-3 mb-0">{item.name}</p>
              </div>
              <i className="bi bi-chevron-right float-end"></i>
            </div>
          );
        })}
      </div>
    </div>
  );
}
