import React, { useEffect } from "react";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import {
  getFavGames,
  hideToast,
  makeFavourite,
  removeFavourite,
} from "../../features/slices/UserSlice";
export function GamesCarousel(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const settings = {
    className: "slider",
    infinite: props.data.length > 4,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 500,
    dots: true,
    focusOnSelect: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          className: "center",
          centerMode: true,
          slidesToShow: 2,
          infinite: props.data.length > 2,
          centerPadding: "5px",
          slidesToScroll: 1,
          speed: 500,
          dots: true,
          focusOnSelect: false,
        },
      },
    ],
  };

  const removeFromFavourite = (game) => {
    dispatch(removeFavourite({ gameId: game.id }));
    setTimeout(() => {
      dispatch(hideToast());
    }, 3000);
  };

  const addToFavourite = (game) => {
    dispatch(makeFavourite({ gameId: game.id, fromCategroyPage: false }));
    setTimeout(() => {
      dispatch(hideToast());
    }, 3000);
  };
  return (
    <div>
      {(user.madeUnFavourite || user.madeFavourite) && (
        <div
          class="toast align-items-center text-bg-dark fade show position-fixed bottom-0 end-0 p-3"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div class="d-flex">
            <div class="toast-body">
              {user.madeFavourite
                ? "Game Added to Favourites!"
                : "Game Removed from Favourites!"}
            </div>
            <button
              type="button"
              class="btn-close btn-close-white me-2 m-auto"
              data-bs-dismiss="toast"
              aria-label="Close"
            ></button>
          </div>
        </div>
      )}
      <div className="slider-custom-style">
        <Slider {...settings}>
          {props.data.map((item, index) => (
            <div className="slide-custom" key={index}>
              <a href={`/play-game/${item.opentableId}`}>
                <div className="overlay">
                  <div className="game--name">
                    <p>{item.name}</p>
                  </div>
                  {props.fav ? (
                    <i
                      className="bi bi-star-fill"
                      title="Remove From Favourites"
                      style={{ cursor: "pointer", color: "yellowgreen" }}
                      onClick={(e) => {
                        removeFromFavourite(item);
                        e.preventDefault();
                      }}
                    ></i>
                  ) : (
                    <i
                      className="bi bi-star"
                      title="Add To Favourites"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        addToFavourite(item);
                        e.preventDefault();
                      }}
                    ></i>
                  )}
                </div>

                <img loading="lazy" src={item.imagePath} />
              </a>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}
