import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAviatrixUrl } from "../../features/slices/GamesSlice";
import { useMediaQuery } from "react-responsive";
import { updateBalance } from "../../features/slices/UserSlice";
// import socket from "../../app/socket";
import socketIOClient from "socket.io-client";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";
export default function PlayAviatrix() {
  //   const { id } = useParams();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const user = useSelector((state) => state.user);
  const game = useSelector((state) => state.game);
  const dispatch = useDispatch();
  const iframeRef = useRef(null);
  const dynamicStyles = {
    marginBottom: "50px",
  };
  useEffect(() => {
    if (user.userLoggedIn) dispatch(getAviatrixUrl());
  }, [user.userLoggedIn]);

  useEffect(() => {
    let decodedToken;
    try {
      const token = Cookies.get("token");
      decodedToken = jwtDecode(token);
      console.log(decodedToken);
    } catch (error) {
      console.error("Token could not be decoded:", error.message);
    }
    const socket = socketIOClient("wss://socket.kubera365.com/");
    socket.on("showBalance", (data) => {
      try {
        const response = JSON.parse(data);
        console.log("socket data", response);
        if (decodedToken.sub === response.user_id)
          dispatch(updateBalance(response.balance.toLocaleString()));
      } catch (error) {
        console.log("socket error", error);
      }
    });
    const headline = document.getElementById("headlines");
    if (headline) headline.style.display = "none";
  }, []);

  return (
    <div style={isMobile ? dynamicStyles : { background: "#000" }}>
      {game.error && <p className="text-center mt-5 mb-5">{game.error}</p>}
      {game?.aviatrixUrl?.url ? (
        <iframe
          src={game?.aviatrixUrl?.url}
          ref={iframeRef}
          id="externalIframeAviatrix"
          width="100%"
          title="Embedded Website"
        ></iframe>
      ) : (
        !game.error && <p className="text-center mt-5 mb-5">Loading...</p>
      )}
    </div>
  );
}
