import React from "react";

export default function ErrorCard(props) {
  return (
    <div
      className="mt-4 mb-4 p-3 payment-error border border-light border-opacity-25 rounded"
      id="errorCard"
      style={{
        color: "rgb(255, 120, 120)",
        background: "rgb(20,20,20)",
      }}
    >
      <p className="text--medium mb-0">
        <i class="bi bi-exclamation-circle"></i> Error: {props.error}
      </p>
    </div>
  );
}
