import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { reset } from "../../features/slices/PaymentSlice";
import { updateSelectedItem } from "../../features/slices/CommonSlice";
import { openPaymentModal } from "../../features/slices/ModalSlice";
import settingsIcon from "../../assets/images/astroIcon.png";
export default function AstroError(props) {
  const dispatch = useDispatch();
  const showModal = () => {
    dispatch(openPaymentModal("Deposit"));
    dispatch(updateSelectedItem(null));
    dispatch(reset());
  };
  return (
    <div className="astro-error-page">
      <div className="container pt-5 pb-5">
        <div className="astro-error-card">
          <div>
            <div className="text-center mb-4">
              <img src={settingsIcon} width={150} />
            </div>
            <p className="text--large font-medium">
              <b>Oops! You are not an active player</b>
            </p>
            <p className="low-opacity">
              <small>Please deposit to access premium features</small>
            </p>
            <p className="font-light">
              Daily horoscope | Numerology | Panchang
            </p>
            <button
              onClick={showModal}
              className="standard-btn mt-4 px-4"
              style={{ borderRadius: "20px" }}
            >
              Deposit Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
