import React, { useEffect } from "react";
import { closeLoginModal } from "../../features/slices/ModalSlice";
import { useDispatch, useSelector } from "react-redux";
import LoginForm from "./LoginForm";
import Cookies from "js-cookie";
import { relogin } from "../../features/slices/UserSlice";
export default function Login() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const hideLoginPopup = () => {
    // if (user.userLoggedIn) dispatch(getUserData());
    dispatch(closeLoginModal());
  };
  useEffect(() => {
    dispatch(relogin());
    Cookies.remove("tempToken");
  }, []);

  const closeModal = (event) => {
    if (event.target.id === "fadeModal") dispatch(closeLoginModal());
  };
  return (
    <div
      className="modal fade show"
      style={{
        display: "block",
        backdropFilter: "blur(15px)",
        background: "rgb(70, 70, 70, 0.6)",
      }}
      id="fadeModal"
      onClick={closeModal}
      tabIndex="-1"
    >
      <div className="modal-dialog modal-dialog-centered" id="loginModal">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title fs-5">Login</h2>
            <i
              data-bs-dismiss="modal"
              aria-label="Close"
              className="bi bi-x-lg"
              onClick={hideLoginPopup}
            ></i>
          </div>
          <div className="modal-body login-form">
            <LoginForm />
          </div>
        </div>
      </div>
    </div>
  );
}
