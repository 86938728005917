import React, { useState } from "react";

export default function VerificationItems({ items, clickItem }) {
  return (
    <div className="veification--sm">
      <p>Verification</p>
      {items.map((item, index) => {
        return (
          <div
            className="d-flex sub--menu-item border border-light border-opacity-25"
            key={index}
            id={item.id}
            onClick={() => clickItem(item.id, "verification")}
          >
            <div className="w-100 d-flex">
              <i className="bi bi-x-lg"></i>
              <p className="text--normal mx-3 mb-0">{item.name}</p>
            </div>
            <i className="bi bi-chevron-right float-end"></i>
          </div>
        );
      })}
    </div>
  );
}
