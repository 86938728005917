import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { startLoading, stopLoading } from "./LoadingSlice";
import api from "../../api";
// Define the async thunk action creator
export const addBankDetails = createAsyncThunk(
  "payment/addBank",
  async (payload, { dispatch, rejectWithValue }) => {
    dispatch(startLoading());
    try {
      const response = await api.post(`/users/addBankDetails`, payload);
      return response.data;
    } catch (error) {
      // Handle error condition
      throw rejectWithValue(error.response.data);
    } finally {
      dispatch(stopLoading());
    }
  }
);
export const removeBankDetails = createAsyncThunk(
  "payment/removeBank",
  async (payload, { dispatch, rejectWithValue }) => {
    dispatch(startLoading());
    try {
      const response = await api.post(`/users/removeBankDetails`, payload);
      return response.data;
    } catch (error) {
      // Handle error condition
      throw rejectWithValue(error.response.data);
    } finally {
      dispatch(stopLoading());
    }
  }
);
export const getPaymentMethods = createAsyncThunk(
  "payment/getpaymentMethods",
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(startLoading());
    try {
      const response = await api.get(`/payment/listMethods`);
      return response.data;
    } catch (error) {
      // Handle error condition
      throw rejectWithValue(error.response.data);
    } finally {
      dispatch(stopLoading());
    }
  }
);
export const createDepositRequest = createAsyncThunk(
  "payment/createDepositRequest",
  async (payload, { dispatch, rejectWithValue }) => {
    dispatch(startLoading());
    let newPayload = { ...payload };
    newPayload.amount = payload.amount.replace(/,/g, "");
    try {
      const response = await api.post(
        `/payment/createDepositRequest`,
        newPayload
      );
      return response.data;
    } catch (error) {
      // Handle error condition
      throw rejectWithValue(error.response.data);
    } finally {
      dispatch(stopLoading());
    }
  }
);
export const createWithdrawRequest = createAsyncThunk(
  "payment/createWithdrawRequest",
  async (payload, { dispatch, rejectWithValue }) => {
    dispatch(startLoading());
    try {
      const response = await api.post(
        `/payment/createWithdrawRequest`,
        payload
      );
      return response.data;
    } catch (error) {
      // Handle error condition
      throw rejectWithValue(error.response.data);
    } finally {
      dispatch(stopLoading());
    }
  }
);
export const cancelWithdrawRequest = createAsyncThunk(
  "payment/cancelWithdrawRequest",
  async (payload, { dispatch, rejectWithValue }) => {
    dispatch(startLoading());
    try {
      const response = await api.post(
        `/payment/cancelWithdrawRequest`,
        payload
      );
      return response.data;
    } catch (error) {
      // Handle error condition
      throw rejectWithValue(error.response.data);
    } finally {
      dispatch(stopLoading());
    }
  }
);
// Create a slice for your API-related actions and reducers
const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    error: null,
    bankDetails: null,
    paymentMethods: [],
    depositRequestedSuccessfully: false,
    withrawRequestedSuccessfully: false,
    depositMessage: "",
    withdrawMessage: "",
    selectedAmount: {},
    bankRemoved: false,
    withdrawCancelled: false,
    transactionUrl: "",
  },
  reducers: {
    reset(state, action) {
      state.depositRequestedSuccessfully = false;
      state.withrawRequestedSuccessfully = false;
      state.error = null;
      state.selectedAmount = action.payload;
      state.bankRemoved = false;
      state.withdrawMessage = "";
      state.depositMessage = "";
      state.bankDetails = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addBankDetails.fulfilled, (state, action) => {
        state.error = null;
        state.bankDetails = action.payload.status === 200;
      })
      .addCase(addBankDetails.rejected, (state, action) => {
        state.error = action.payload.error || "Invalid Request";
      })
      .addCase(removeBankDetails.fulfilled, (state, action) => {
        state.error = null;
        state.bankRemoved = action.payload.status == 200;
      })
      .addCase(removeBankDetails.rejected, (state, action) => {
        state.error = action.payload.error || "Invalid Request";
      })
      .addCase(getPaymentMethods.fulfilled, (state, action) => {
        state.paymentMethods = action.payload.data;
      })
      .addCase(getPaymentMethods.rejected, (state, action) => {
        state.error = action.error.message || "Invalid Request";
      })
      .addCase(createDepositRequest.fulfilled, (state, action) => {
        state.depositMessage =
          action.payload.data.message ||
          "Deposit Request Accepted Successfully";
        state.depositRequestedSuccessfully = action.payload.status == 200;
        state.transactionUrl = action.payload.data.url || "";
      })
      .addCase(createDepositRequest.rejected, (state, action) => {
        state.depositMessage = "";
        state.error = action.payload.error || "Invalid Request";
      })
      .addCase(createWithdrawRequest.fulfilled, (state, action) => {
        state.withdrawMessage =
          action.payload.data.message ||
          "Withdraw Request Accepted Successfully";
        state.withrawRequestedSuccessfully = action.payload.status == 200;
      })
      .addCase(createWithdrawRequest.rejected, (state, action) => {
        state.withdrawMessage = "";
        state.error = action.payload.error || "Invalid Request";
      })
      .addCase(cancelWithdrawRequest.fulfilled, (state, action) => {
        state.withdrawCancelled = true;
      })
      .addCase(cancelWithdrawRequest.rejected, (state, action) => {
        state.withdrawCancelled = false;
        state.error = action.payload.error || "Invalid Request";
      });
  },
});

// Export the async thunk action creator and the reducer
export const { reducer } = paymentSlice;
export const { reset } = paymentSlice.actions;
export default paymentSlice.reducer;
