import React from "react";
import { closePaymentCompletionModal } from "../../features/slices/ModalSlice";
import { useDispatch, useSelector } from "react-redux";
import Completed from "./Completed";
import { getUserData } from "../../features/slices/UserSlice";
export default function PaymentCompletion(props) {
  const dispatch = useDispatch();
  const closeCompletionPopup = () => {
    dispatch(getUserData({ source: "payment_completion", showLoader: true }));
    dispatch(closePaymentCompletionModal());
  };

  return (
    <div
      className={`modal fade show payment-completion-modal`}
      style={{
        display: "block",
        backdropFilter: "blur(15px)",
        background: "rgb(70, 70, 70, 0.6)",
      }}
      tabIndex="-1"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title fs-5"></h2>

            <i onClick={closeCompletionPopup} className="bi bi-x-lg"></i>
          </div>
          <div className="modal-body mobile-otp-modal--body text-center">
            <Completed closeModal={closeCompletionPopup} />
          </div>
        </div>
      </div>
    </div>
  );
}
