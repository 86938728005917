import React, { useState, useEffect } from "react";
import {
  closeMobileOtpModal,
  tick,
  resetCountdown,
  openPopup,
  openforgotPasswordModal,
  openLoginModal,
  openThanksPopup,
  closeThanksPopup,
} from "../../features/slices/ModalSlice";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { updateSelectedItem } from "../../features/slices/CommonSlice";
import {
  getUserData,
  relogin,
  sendMobileOTP,
  validateMobileOTP,
} from "../../features/slices/UserSlice";

export default function MobileOtpForm() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const user = useSelector((state) => state.user);
  const modal = useSelector((state) => state.modal);
  const common = useSelector((state) => state.common);

  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [otpSentMessage, setOtpSentMessage] = useState(
    "A 6 digit One Time Password has been sent to +91 1234 5678 91"
  );
  const isMobileOtpPopupVisible = useSelector(
    (state) => state.modal.isMobileOtpModalVisible
  );
  const countdown = useSelector((state) => state.modal.countdown);
  useEffect(() => {
    let intervalId;
    if (isMobileOtpPopupVisible || common.selectedItem) {
      intervalId = setInterval(() => {
        if (countdown > 0) dispatch(tick());
        else clearInterval(intervalId);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [isMobileOtpPopupVisible, dispatch, countdown]);

  const handleChange = (element, index) => {
    const actualValue = element.value;
    const value = element.value.replace(/[^0-9]/g, "");
    otp[index] = value;
    setOtp([...otp]);
    if (!actualValue.length) {
      if (element.previousSibling) {
        element.previousSibling.focus();
      }
      return;
    }
    // Focus next input
    if (value.length && element.nextSibling) {
      element.nextSibling.focus();
    }
  };
  const resetOtpCountdown = () => {
    setOtp(new Array(4).fill(""));
    dispatch(sendMobileOTP(user.phoneDetails || {}));
    dispatch(resetCountdown());
  };
  const validateOTP = () => {
    const fromForgotPassword =
      modal.isFromForgotPassword || common.isFromForgotPassword;
    if (+otp.join("").length === 4) {
      const obj = {
        otp: otp.join(""),
        action: fromForgotPassword ? "forgot-password" : "register",
      };
      dispatch(
        validateMobileOTP({ data: obj, fromForgotPassword: fromForgotPassword })
      );
      // if (!fromForgotPassword) {
      //   dispatch(closeMobileOtpModal());
      //   dispatch(openPopup("mobile"));
      // }
    }
    return;
  };

  const closeOTPScreen = () => {
    dispatch(updateSelectedItem(null));
  };

  useEffect(() => {
    if (
      user.otpVerified &&
      (modal.isFromForgotPassword || common.isFromForgotPassword)
    ) {
      dispatch(relogin());
      if (!isMobile) {
        dispatch(closeMobileOtpModal());
        dispatch(openforgotPasswordModal());
      } else {
        dispatch(updateSelectedItem(null));
        dispatch(
          updateSelectedItem({
            name: "forgotPassword",
            fromForgotPassword: true,
          })
        );
      }
    } else if (user.otpVerified) {
      // dispatch(relogin());
      if (!isMobile) {
        dispatch(closeMobileOtpModal());
      } else {
        dispatch(updateSelectedItem(null));
      }
      if (modal.initialOtpVerification) {
        dispatch(openLoginModal());
        return;
      }
      dispatch(openThanksPopup());
      dispatch(getUserData({ source: "otp_verified", showLoader: true }));
      setTimeout(() => {
        dispatch(closeThanksPopup());
        // if (isMobile) window.location.reload();
      }, 3000);
      // dispatch(openPopup("mobile"));
    }
  }, [user.otpVerified]);

  const styles = {
    backgroundColor: isMobile ? "#111111" : "none",
    height: isMobile ? "100vh" : "auto",
    paddingBottom: isMobile ? "100px" : "auto",
  };
  return (
    <div
      className={`${isMobile ? "form-mobile otp-form px-3 pt-3" : ""}`}
      style={styles}
    >
      {isMobile && (
        <p className="text-center" style={{ color: "#EFC04B" }}>
          Mobile Verfication
        </p>
      )}
      <p className={`${isMobile ? "text-center" : ""} text--normal`}>
        Please enter the One Time Password to verify your account
      </p>
      {user.error && (
        <p
          className={`${isMobile ? "text-center" : ""} text--medium`}
          style={{ color: "rgb(255, 64, 64)" }}
        >
          Error: {user.error}
        </p>
      )}
      {user.otpMessage && (
        <p
          className={`${isMobile ? "text-center" : ""} text-muted text--medium`}
        >
          {user.otpMessage}
        </p>
      )}

      <div className="otp-inputs d-flex m-auto justify-content-center">
        {otp.map((data, index) => {
          return (
            <input
              className="otp-field m-2 text-center"
              type="text"
              name="otp"
              maxLength="1"
              pattern="[^0-9]*"
              key={index}
              value={data}
              onChange={(e) => handleChange(e.target, index)}
              onFocus={(e) => e.target.select()}
            />
          );
        })}
      </div>
      {isMobile && (
        <button
          className="border-btn mb-3 mt-3 p-2 text-center"
          onClick={closeOTPScreen}
        >
          Cancel
        </button>
      )}
      <button
        className="login-btn mb-3 mt-3 p-2 text-center w-75"
        onClick={validateOTP}
      >
        Validate
      </button>
      <p className={`${isMobile ? "text-center" : ""} text--medium`}>
        Didn't recieve OTP?{" "}
        {countdown != 0 ? (
          `Resend in ${countdown}s`
        ) : (
          <span
            onClick={resetOtpCountdown}
            className="blue--text text-decoration-underline"
          >
            Resend
          </span>
        )}
      </p>
    </div>
  );
}
