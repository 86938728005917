import React, { useState } from "react";
import phonepay from "../../assets/images/paymentSlider/phonepay.svg";
import gpay from "../../assets/images/paymentSlider/gpay.svg";
import imps from "../../assets/images/paymentSlider/imps.svg";
import netbanking from "../../assets/images/paymentSlider/netbanking.svg";
import RTGS from "../../assets/images/paymentSlider/RTGS.svg";
import upi from "../../assets/images/paymentSlider/upi.svg";
import { updateSelectedItem } from "../../features/slices/CommonSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
export default function MobileFooterMain() {
  const [paymentSliderImages, setImages] = useState([
    phonepay,
    gpay,
    imps,
    netbanking,
    RTGS,
    upi,
    phonepay,
    gpay,
    imps,
    netbanking,
    RTGS,
    upi,
  ]);
  const dispatch = useDispatch();
  const common = useSelector((state) => state.common);
  const showChatBox = () => {
    if (common.selectedItem === "chat-bot") {
      dispatch(updateSelectedItem(null));
    } else dispatch(updateSelectedItem("chat-bot"));
  };
  const currentYear = moment().format("YYYY");
  return (
    <div className="mobile-main-foooter">
      <div className="text-center p-3">
        <img
          loading="lazy"
          width={170}
          src={require("../../assets/images/logo2x.png")}
        />
        <p className="mb-1 text--normal">Play | Win | Repeat.</p>
        <div className="white-stp w-100">Unleash Your Luck and Win Big</div>
        <div className="row menu-options mt-3">
          <div className="col-4">
            <div className="footer-title">Services</div>
            <ul className="p-0">
              <li>
                <a href="/terms">Terms of Use</a>
              </li>
              <li>
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
              {/* <li>Payment Options</li> */}
              {/* <li>FAQs</li> */}
            </ul>
          </div>
          <div className="col-4 share">
            <div className="footer-title pb-3">Share</div>
            <div className="social-icons d-flex">
              <img
                loading="lazy"
                src={require("../../assets/images/facebook.png")}
              />
              <img
                loading="lazy"
                src={require("../../assets/images/insta.png")}
              />
              <img
                loading="lazy"
                src={require("../../assets/images/twitter.png")}
              />
            </div>
          </div>
          <div className="col-4" style={{ paddingLeft: "40px" }}>
            <div className="footer-title">Products</div>
            <ul className="p-0">
              {/* <li>About Us</li> */}
              <li onClick={showChatBox}>Contact Us</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container-fluid p-1 payment-slider">
        <div className="d-flex payment-slide">
          {paymentSliderImages.map((item, index) => {
            return (
              <img
                loading="lazy"
                key={index}
                width={35}
                className="mx-4"
                src={item}
              />
            );
          })}
        </div>
        <div className="d-flex payment-slide">
          {paymentSliderImages.map((item, index) => {
            return (
              <img
                loading="lazy"
                key={index}
                width={35}
                className="mx-4"
                src={item}
              />
            );
          })}
        </div>
      </div>
      <div className="container-fluid text-center p-3 copyright">
        <p className="mb-0 text--medium text-muted">
          Copyright {currentYear} @ kubera365 | All Rights Reserved
        </p>
      </div>
    </div>
  );
}
