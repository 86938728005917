import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import deposit from "../../assets/images/deposit-yellow.svg";
import refer from "../../assets/images/refer-yellow.svg";
import favourite from "../../assets/images/favourite-yellow.svg";
import account from "../../assets/images/account-yellow.svg";
import withdraw from "../../assets/images/withdraw-yellow.svg";
import transaction from "../../assets/images/transaction-yellow.svg";
import setting from "../../assets/images/setting-yellow.svg";
import logoutIcon from "../../assets/images/logoutIcon.svg";
import newChatIcon from "../../assets/images/newChatIcon.png";
import AstrologyPopup from "../astrology/AstrologyPopup";
import {
  updateSelectedItem,
  updateOverlayItem,
} from "../../features/slices/CommonSlice";
import { logoutUser } from "../../features/slices/UserSlice";
import { openChatBox, hideChatBox } from "../../features/slices/CommonSlice";
export default function FooterMenuItems() {
  const [astrologyModal, setShowAstrologyModal] = useState(false);
  const common = useSelector((state) => state.common);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  //   const closeMenu = () => {
  //     dispatch(updateSelectedItem(null));
  //   };

  const openDeposit = () => {
    dispatch(updateSelectedItem("deposit"));
    dispatch(updateOverlayItem(null));
  };
  const openWithdraw = () => {
    dispatch(updateSelectedItem("withdraw"));
    dispatch(updateOverlayItem(null));
  };

  const logouUser = () => {
    dispatch(logoutUser());
  };
  const getFormattedBalance = (balance) => {
    if (balance) {
      if (typeof balance === "number") return balance.toLocaleString();
      if (!balance.includes(",")) {
        const intBalance = parseInt(balance);
        return intBalance.toLocaleString();
      }
    }
    return "0";
  };

  const showChatBox = () => {
    dispatch(updateSelectedItem("chat-bot"));
    dispatch(updateOverlayItem(null));
  };

  const gotoFav = () => {
    dispatch(updateOverlayItem(null));
    if (window.location.pathname === "/") {
      const section = document.getElementById("favGames");
      section.scrollIntoView({ behavior: "smooth" });
      return;
    }
    const mainUrl = window.location.protocol + "//" + window.location.host;
    localStorage.setItem("moveToFavourite", true);
    window.open(mainUrl, "_self");
  };

  const goToTransactions = () => {
    localStorage.setItem("mobileProfileScreen", "ledger");
    window.location.pathname = "/profile";
  };
  const hideSubmenu = (event) => {
    if (event.target.id === "submenuMain") dispatch(updateOverlayItem(null));
  };

  const redirectToAstroLogyPage = () => {
    if (user.userData.dob) {
      window.location.pathname = "/astrology";
    } else {
      setShowAstrologyModal(true);
    }
  };
  return (
    <div
      id="submenuMain"
      onClick={hideSubmenu}
      className={`${
        common.overlayItem === "menu" ? "show-sub-menu" : "hide-sub-menu"
      } footer-submenu-container`}
    >
      <div className="games-menu p-3">
        <p>Menu</p>
        <div className="d-flex m-auto justify-content-between mb-2">
          <h3 className="fs-6" style={{ color: "#EFC04B" }}>
            {user.userData.username || ""}
          </h3>
          {/* <span
            className={`blue--text ${user.userLoggedIn ? "" : "text-muted"}`}
          >
            <a href="/profile" className="blue--text">
              Account
            </a>
          </span> */}
        </div>
        <div className="balance-info text--black mb-3">
          <div className="balance d-flex m-auto justify-content-between">
            <div className="total-bal">
              <p className="text--medium mb-1">Total Bal.</p>
              <p className="mb-2">
                <strong>
                  ₹
                  {getFormattedBalance(
                    user.userData.redisBalance || user.userData.totalBalance
                  )}
                </strong>
              </p>
            </div>
            <div className="withdrawl-bal">
              <p className="text--medium mb-1">Withdrawl Bal.</p>
              <p className="mb-2">
                <strong>
                  ₹{getFormattedBalance(
                    user.userData.redisBalance || user.userData.totalBalance
                  )}
                </strong>
              </p>
            </div>
            <div className="bonus-bal">
              <p className="text--medium mb-1">Bonus</p>
              <p className="mb-2">
                <strong>₹{getFormattedBalance(user.userData.bonus)}</strong>
              </p>
            </div>
          </div>
          {/* <div className="bal-progress">
            <div className="circle--progress"></div>
            <span className="progress-value text--medium">
              <b>72.4%</b>
            </span>
          </div> */}
        </div>

        <div className="row p-2">
          <div className="col-4 text-center">
            <div className="game-type" onClick={openDeposit}>
              <img loading="lazy" src={deposit} alt="games" />
              <p className="text--medium text--light mb-0 mt-1">Deposit</p>
            </div>
          </div>
          <div className="col-4 text-center">
            <div className="game-type" onClick={openWithdraw}>
              <img loading="lazy" src={withdraw} alt="games" />
              <p className="text--medium text--light mb-0 mt-1">Withdraw</p>
            </div>
          </div>
          <div className="col-4 text-center">
            <div className="game-type" onClick={goToTransactions}>
              <img loading="lazy" src={transaction} alt="games" />
              <p className="text--medium text--light mb-0 mt-1">Transactions</p>
            </div>
          </div>
          {/* <div className="col-4 text-center">
            <div className="game-type">
              <img  loading="lazy" src={account} alt="games" />
              <p className="text--medium text--light mb-0 mt-1">Account</p>
            </div>
          </div> */}
          <div className="col-4 text-center">
            <div className="game-type" onClick={gotoFav}>
              <img loading="lazy" src={favourite} alt="games" />
              <p className="text--medium text--light mb-0 mt-1">Favourite</p>
            </div>
          </div>
          <div className="col-4 text-center">
            <div className="game-type">
              <a href="/profile">
                <img loading="lazy" src={setting} alt="games" />
                <p className="text--medium text--light mb-0 mt-1">Settings</p>
              </a>
            </div>
          </div>
          <div className="col-4 text-center">
            <div className="game-type" onClick={redirectToAstroLogyPage}>
              <img loading="lazy" src={setting} alt="games" />
              <p className="text--medium text--light mb-0 mt-1">Astrology</p>
            </div>
          </div>
          {/* <div className="col-4 text-center">
            <div className="game-type">
              <img  loading="lazy" src={refer} alt="games" />
              <p className="text--medium text--light mb-0 mt-1">
                Refer a friend
              </p>
            </div>
          </div> */}
          <div className="col-4 text-center">
            <div className="game-type" onClick={showChatBox}>
              <img loading="lazy" src={newChatIcon} alt="games" width={25} />
              <p className="text--medium text--light mb-0 mt-1">Chat with us</p>
            </div>
          </div>
          <div className="col-4 text-center" onClick={logouUser}>
            <div className="game-type">
              <img loading="lazy" src={logoutIcon} alt="games" />
              <p className="text--medium text--light mb-0 mt-1">Logout</p>
            </div>
          </div>
        </div>
      </div>
      {astrologyModal && (
        <AstrologyPopup closeModal={() => setShowAstrologyModal(false)} />
      )}
    </div>
  );
}
