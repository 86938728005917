import React from "react";
// import minilogo from "../../../assets/images/mini-logo.svg";
import newChatIcon from "../../../assets/images/newChatIcon.png";
import { useDispatch } from "react-redux";
import {
  showChatBoxInDesktop,
  updateSelectedItem,
} from "../../../features/slices/CommonSlice";
import { useMediaQuery } from "react-responsive";

import {
  closePaymentMethod,
  closePaymentModal,
} from "../../../features/slices/ModalSlice";
export default function ActionButtons({ toNext }) {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const showChatBox = () => {
    if (isMobile) {
      dispatch(updateSelectedItem("chat-bot"));
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      dispatch(showChatBoxInDesktop());
      dispatch(closePaymentModal());
      dispatch(closePaymentMethod());
    }
  };
  return (
    <div className="action-btns px-3">
      <p className="text-center text--medium statement">
        Problem in making payment please contact customer support
      </p>
      <div className="d-flex justify-content-between">
        <button
          className="border-btn"
          style={{ color: "#F5CA3D", border: "1px solid #F5CA3D" }}
          onClick={showChatBox}
        >
          <img
            loading="lazy"
            src={newChatIcon}
            width={isMobile ? 25 : 30}
            alt="logo"
            className="mx-1"
          />
          Chat with us
        </button>
        <button
          type="submit"
          className="standard-btn"
          onClick={(event) => toNext(event)}
        >
          Next
        </button>
      </div>
    </div>
  );
}
