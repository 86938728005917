import React from "react";
import Cookies from "js-cookie";
import { Route, Routes, Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = Cookies.get("token");

  if (!isAuthenticated) return <Navigate to="/" replace />;
  return children;
};

export default ProtectedRoute;
