import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { fetchTopBanners } from "../../features/slices/GamesSlice";
import { openLoginModal } from "../../features/slices/ModalSlice";
import { useMediaQuery } from "react-responsive";
import { updateSelectedItem } from "../../features/slices/CommonSlice";

export function Carousel() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const game = useSelector((state) => state.game);
  const user = useSelector((state) => state.user);
  const [isMuted, setIsMuted] = useState(true);
  const [slideDuration, setSlideDuration] = useState(7000);
  useEffect(() => {
    if (game?.data?.data.length) {
      if (game.data.data.find((item) => item.isVideo)) {
        setSlideDuration(25000);
      }
      return;
    }
    dispatch(fetchTopBanners());
  }, [game.data]);

  const openLogin = () => {
    if (!user.userLoggedIn) {
      if (!isMobile) dispatch(openLoginModal());
      else dispatch(updateSelectedItem("login"));
    }
  };
  const handleBeforeChange = (oldIndex, newIndex) => {
    if (game?.data?.data.find((item) => item.isVideo)) {
      if (newIndex == 0) setSlideDuration(25000);
      else setSlideDuration(7000);
    }
  };
  let settings = {
    className: "center",
    infinite: true,
    centerMode: true,
    centerPadding: "300px",
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    dots: true,
    autoplay: true,
    autoplaySpeed: slideDuration,
    focusOnSelect: false,
    beforeChange: handleBeforeChange,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          className: "center",
          slidesToShow: 1,
          centerMode: true,
          infinite: true,
          centerPadding: "150px",
          slidesToScroll: 1,
          speed: 500,
          dots: true,
          focusOnSelect: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          className: "slider",
          centerMode: false,
          slidesToShow: 1,
          infinite: true,
          slidesToScroll: 1,
          speed: 500,
          dots: true,
          focusOnSelect: false,
        },
      },
    ],
  };

  return (
    <div className="main-slider">
      <div className="pt-4 slider-custom-style">
        <Slider {...settings}>
          {game?.data?.data.length ? (
            game.data.data.map((item, index) => {
              return item.isVideo ? (
                <div
                  className="slide-custom video-slide"
                  onClick={() => setIsMuted(!isMuted)}
                >
                  <video
                    autoPlay
                    loop
                    muted={isMuted}
                    playsInline
                    id="videoId"
                    className="banner-vdo"
                    // style={{ objectFit: "cover" }}
                    // height={isMobile ? 190 : ""}
                  >
                    <source
                      src={isMobile ? item.mobileUrl : item.desktopUrl}
                      type="video/mp4"
                    />
                  </video>
                  {isMuted ? (
                    <i
                      class="bi bi-volume-mute-fill"
                      onClick={() => setIsMuted(false)}
                    ></i>
                  ) : (
                    <i
                      class="bi bi-volume-up-fill"
                      onClick={() => setIsMuted(true)}
                    ></i>
                  )}
                </div>
              ) : (
                <div className="slide-custom" key={index}>
                  <img
                    loading="lazy"
                    // onClick={openLogin}
                    src={isMobile ? item.mobileUrl : item.desktopUrl}
                    alt={item.description}
                    title={item.title}
                    // style={{ cursor: "pointer" }}
                  />
                </div>
              );
            })
          ) : (
            <>
              <div className="slide-custom" style={{ height: "500px" }}></div>
            </>
          )}
        </Slider>
      </div>
    </div>
  );
}
