import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../features/slices/UserSlice";
export default function AstrologyPopup({ closeModal }) {
  const dispatch = useDispatch();
  const [dob, setDob] = useState("");
  const user = useSelector((state) => state.user);
  const updateUserDOB = (e) => {
    e.preventDefault();
    dispatch(updateUser({ dob: dob }));
  };
  useEffect(() => {
    if (user.userData.dob) {
      window.location.pathname = "/astrology";
    }
  }, [user.userData]);

  return (
    <div
      className={`modal fade show otp--modal astrology-modal`}
      style={{ display: "block", backdropFilter: "blur(15px)" }}
      tabIndex="-1"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title fs-5">Daily Astrology</h2>
            <i className="bi bi-x-lg" onClick={() => closeModal()}></i>
          </div>
          <div className="modal-body mobile-otp-modal--body w-75 astrology-modal-body">
            <p className="text--normal">
              Provide these details for your daily Horoscope, Numerology and
              Panchang
            </p>
            <form>
              <div>
                <label className="d-block">Date of Birth</label>
                <input
                  placeholder="Date of Birth"
                  onChange={(e) => setDob(e.target.value)}
                  className="w-100 mt-2"
                  type="date"
                />
              </div>
              {/* <div className="mt-3">
                <label className="d-block">Time of Birth</label>
                <input
                  placeholder="Time of Birth"
                  className="w-100 mt-2"
                  type="time"
                />
              </div> */}
              <button
                onClick={(e) => updateUserDOB(e)}
                className="w-full login-btn py-2 px-3 mt-5"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
