import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
export default function PlayingInOtherTab() {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const sendMessageToActiveGameTab = () => {
    const channel = new BroadcastChannel("my_channel");
    // Function to send messages through the channel
    channel.postMessage({ closeGame: true });
    // setTimeout(() => {
    window.location.reload();
    // }, 1000);
    window.onbeforeunload = () => {
      channel.close();
    };
  };

  // const closeCurrentTab = () => {
  //   window.close();
  //   window.open("about:blank", "_self").close();
  // };
  useEffect(() => {
    sendMessageToActiveGameTab();
    // closeCurrentTab();
  }, []);

  return (
    // <div
    //   className={`modal fade show payment-completion-modal`}
    //   style={{
    //     display: "block",
    //     backdropFilter: "blur(15px)",
    //     background: "rgb(70, 70, 70, 0.6)",
    //   }}
    //   tabIndex="-1"
    // >
    //   <div className="modal-dialog modal-lg modal-dialog-centered">
    //     <div
    //       className="modal-content alert-popup"
    //       style={{ background: "#1d1d1d", borderRadius: "10px" }}
    //     >
    //       <div className="modal-body p-4 text-center">
    //         {isMobile ? (
    //           <i class="bi bi-phone"></i>
    //         ) : (
    //           <i class="bi bi-laptop"></i>
    //         )}
    //         <div>
    //           <p style={{ lineHeight: "0.4cm" }}>
    //             <span className="text--medium">
    //               Game is active in another window. Click "Use Here" to use in
    //               this window OR close this window.
    //             </span>
    //             <br />
    //             <span style={{ fontSize: "11px", opacity: "0.7" }}>
    //               *If you do so you will logout from another window. Active game
    //               transaction will be lost
    //             </span>
    //           </p>
    //         </div>
    //         <div className="btns d-flex mt-5 justify-content-center">
    //           <button className={`close-btn mb-3`} onClick={closeCurrentTab}>
    //             Close
    //           </button>
    //           <button
    //             className={`use-here-btn mb-3`}
    //             onClick={sendMessageToActiveGameTab}
    //           >
    //             Use Here
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <></>
  );
}
