import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closePaymentModal,
  openPaymentMethod,
} from "../../../features/slices/ModalSlice";
import newChatIcon from "../../../assets/images/newChatIcon.png";
import Slider from "react-slick";
import { reset } from "../../../features/slices/PaymentSlice";
import { useMediaQuery } from "react-responsive";
import {
  showChatBoxInDesktop,
  updateSelectedItem,
} from "../../../features/slices/CommonSlice";
import { createDepositRequest } from "../../../features/slices/PaymentSlice";

export default function DepositContent() {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const dispatch = useDispatch();
  const amountOptions = [
    {
      amount: "500",
      id: "amount1",
    },
    {
      amount: "1,000",
      id: "amount2",
    },
    {
      amount: "5,000",
      id: "amount3",
    },
    {
      amount: "10,000",
      id: "amount4",
    },
    {
      amount: "50,000",
      id: "amount5",
    },
  ];
  const modal = useSelector((state) => state.modal);
  const payment = useSelector((state) => state.payment);
  const [selectedAmount, setSelectedAmount] = useState("amount1");
  const [enteredAmount, setAmount] = useState("0");
  // const [selectedOffer, setSelectedOffer] = useState("offer1");
  const [nextStep, getNextStep] = useState("");
  const [paymentId, setPaymentId] = useState(null);
  // const [offerCode, setOfferCode] = useState(null);
  const [redBorder, setRedBorder] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});
  const settings = {
    className: "slider",
    infinite: false,
    // centerMode: true,
    // centerPadding: "30px",
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 500,
    dots: false,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          className: "slider",
          centerMode: false,
          // centerPadding: "15px",
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
    ],
  };

  const setActiveSlide = (payId) => {
    document
      .querySelectorAll(".slick-current")
      .forEach((item) => item.classList.remove("slick-current"));
    if (payId)
      document
        .getElementById(payId)
        .parentElement.parentElement.classList.add("slick-current");
  };
  const selectMethod = (event, payment_id) => {
    const id = event.target.id || event.target.parentElement.id;
    getNextStep(id);
    setPaymentId(payment_id);
    setActiveSlide(id);
    const selectedMethod = payment.paymentMethods.find(
      (item) => item.id === payment_id
    );
    console.log("selected-methos", selectedMethod);
    setSelectedPaymentMethod(selectedMethod);
  };

  const addCommaInAmmount = (amount) => {
    return (
      parseInt(amount.toString().replace(/[^\d]+/gi, "")) || ""
    ).toLocaleString("en-US");
  };
  const handleAmountSelect = (amount) => {
    const prevAmount = +enteredAmount.replace(/,/g, "");
    const newAmount = +amount.replace(/,/g, "");
    const addedAMount = prevAmount + newAmount;
    let finalAmount = addCommaInAmmount(addedAMount);
    if (finalAmount.length === 7) {
      finalAmount = finalAmount.slice(0, 1) + "," + finalAmount.slice(1);
    }
    setAmount(finalAmount);
  };
  // const handleSelectedOffer = (event) => {
  //   setSelectedOffer(event.target.id || event.target.parentElement.id);
  //   console.log(event.target.parentElement.id);
  // };
  const goToNextStep = () => {
    // dispatch(closePaymentModal());
    if (!selectedPaymentMethod.id) return;

    if (
      !enteredAmount ||
      +enteredAmount.replace(/,/g, "") <
        +selectedPaymentMethod.minAmonut.replace(/,/g, "") ||
      +enteredAmount.replace(/,/g, "") >
        +selectedPaymentMethod.maxAmonut.replace(/,/g, "")
    ) {
      setRedBorder(true);
      if (isMobile) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
      return;
    }
    setRedBorder(false);
    dispatch(
      reset({
        amount: enteredAmount,
        amountId: selectedAmount,
      })
    );
    if (!selectedPaymentMethod.isManualPayment) {
      dispatch(
        createDepositRequest({
          amount: enteredAmount,
          paymentMethodId: paymentId,
          tos: true,
        })
      );
      return;
    }
    dispatch(
      openPaymentMethod({
        nextStep: nextStep,
        amount: enteredAmount,
        paymentMethodId: paymentId,
        // offerCode: null,
      })
    );
  };
  useEffect(() => {
    if (payment.depositRequestedSuccessfully) {
      window.open(payment.transactionUrl, "_self").focus();
    }
  }, [payment.depositRequestedSuccessfully, payment.transactionUrl]);

  useEffect(() => {
    if (payment.selectedAmount?.amount) {
      setAmount(payment.selectedAmount.amount);
      setSelectedAmount(payment.selectedAmount.amountId);
    }
  }, [payment.selectedAmount]);

  const setEnteredAmount = (event) => {
    let amount = addCommaInAmmount(event.target.value);
    if (amount.length === 7) {
      amount = amount.slice(0, 1) + "," + amount.slice(1);
    }
    setAmount(amount);
    setRedBorder(false);
  };

  useEffect(() => {
    if (modal.depositData?.paymentMethodId) {
      const payId = modal.depositData.paymentMethodId === 1 ? "UPI" : "IMPS";
      const oppositeId = payId === "UPI" ? "IMPS" : "UPI";
      setActiveSlide(payId);
      setPaymentId(modal.depositData?.paymentMethodId);
      getNextStep(payId);
      setTimeout(() => {
        if (document.getElementById(oppositeId))
          document
            .getElementById(oppositeId)
            .parentElement.parentElement.classList.remove("slick-current");
      }, 0);
    }
  }, [modal.depositData]);

  const showChatBox = () => {
    if (isMobile) {
      dispatch(updateSelectedItem("chat-bot"));
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else dispatch(showChatBoxInDesktop());
    dispatch(closePaymentModal());
  };

  useEffect(() => {
    if (payment.paymentMethods.length) {
      const activePaymentMethods = payment.paymentMethods.filter(
        (item) => item.status
      );
      console.log(activePaymentMethods);
      setSelectedPaymentMethod(activePaymentMethods[0]);
      setPaymentId(activePaymentMethods[0].id);
      if (activePaymentMethods[0].isManualPayment) {
        getNextStep(activePaymentMethods[0].name.toUpperCase());
      }
    }
  }, [payment.paymentMethods]);
  return (
    <div className="row">
      <div className="col-md-6 col-lg-6 col-xl-6 col-12 deposit-col1 pt-4 pb-4">
        <div className="payment-options px-4">
          <div className="px-4 slider-custom-style">
            <Slider {...settings}>
              {payment.paymentMethods.length
                ? payment.paymentMethods.map(
                    (item, index) =>
                      !!item.status && (
                        <div
                          className={`slide-custom p-1 ${
                            paymentId === item.id ? "selected-slide" : ""
                          }`}
                          onClick={(e) => selectMethod(e, item.id)}
                          id={
                            ["UPI", "ZIPZOPAY"].includes(
                              item.name.toUpperCase()
                            )
                              ? "UPI"
                              : item.name.toUpperCase()
                          }
                          key={index}
                        >
                          <img src={item.imagePath} />
                        </div>
                      )
                  )
                : null}
            </Slider>
          </div>
        </div>
        <div className="amount-input mt-3 px-4">
          <label className="text--normal" style={{ fontWeight: "200" }}>
            Amount <span>*</span>
          </label>
          <div className="d-flex">
            <input
              type="text"
              placeholder="Enter Amount"
              pattern="^[\d,]+$"
              className={redBorder ? "red-border" : ""}
              value={enteredAmount}
              onChange={(e) => {
                setEnteredAmount(e);
              }}
            />
            <span style={{ float: "right" }}>INR</span>
          </div>

          <p className="low-opacity text--small mb-0 mt-1">
            {selectedPaymentMethod.minAmonut +
              "-" +
              selectedPaymentMethod.maxAmonut}
          </p>
        </div>
        <div className="amount-options mt-3 px-4">
          <div className="d-flex">
            {amountOptions.map((item, index) => (
              <button
                key={index}
                className="text-center amount"
                id={item.id}
                onClick={() => handleAmountSelect(item.amount)}
              >
                +{item.amount}
              </button>
            ))}
          </div>
        </div>

        {!!payment.paymentMethods.length && selectedPaymentMethod.id && (
          <div className="px-4">
            <p
              className="low-opacity text--small mb-0 mt-1"
              style={{ color: "#E42D2D" }}
            >
              {+enteredAmount.replace(/,/g, "") <
              +selectedPaymentMethod.minAmonut.replace(/,/g, "")
                ? `Amount can't be less than ${selectedPaymentMethod.minAmonut}`
                : +enteredAmount.replace(/,/g, "") >
                  +selectedPaymentMethod.maxAmonut.replace(/,/g, "")
                ? `Amount can't be greater than ${selectedPaymentMethod.maxAmonut}`
                : ""}
            </p>
          </div>
        )}
        {/* <div className="offer-code amount-input mt-3 px-4">
          <label className="text--normal" style={{ fontWeight: "200" }}>
            Offer Code
          </label>
          <div className="d-flex">
            <input
              type="text"
              className="offer-code-input"
              placeholder="TUTYR YIUOI QERWE"
              onChange={(e) => setOfferCode(e.target.value)}
            />
            <span style={{ float: "right", color: "#32D172" }}>
              <i className="bi bi-check2"></i>
            </span>
          </div>
        </div>
        <div className="available-offers-container mt-3">
          <label className="text--small">Available Offers</label>
          <div className="available-offers">
            <div
              className={`offer-card mt-2 mb-2 ${
                selectedOffer === "offer1" ? "active-offer" : ""
              }`}
              id="offer1"
              onClick={handleSelectedOffer}
            >
              <p className="text-muted text--medium mb-0">Welcome Bonus</p>
              <p className="mb-0 text--normal offer-detail">
                15% Bonus upto ₹1000.00 (Min. deposit ₹2000)
              </p>
              <p
                className="mb-0"
                style={{ color: "#31B3FF", fontSize: "10px", opacity: "0.85" }}
              >
                Terms & Conditions
              </p>
            </div>
            <div
              className={`offer-card mt-2 mb-2 ${
                selectedOffer === "offer2" ? "active-offer" : ""
              }`}
              id="offer2"
              onClick={handleSelectedOffer}
            >
              <p className="text-muted text--medium mb-0">Welcome Bonus</p>
              <p className="mb-0 text--normal offer-detail">
                15% Bonus upto ₹1000.00 (Min. deposit ₹2000)
              </p>
              <p
                className="mb-0"
                style={{ color: "#31B3FF", fontSize: "10px", opacity: "0.85" }}
              >
                Terms & Conditions
              </p>
            </div>
            <div
              className={`offer-card mt-2 mb-2 ${
                selectedOffer === "offer3" ? "active-offer" : ""
              }`}
              onClick={handleSelectedOffer}
              id="offer3"
            >
              <p className="text-muted text--medium mb-0">Welcome Bonus</p>
              <p className="mb-0 text--normal offer-detail">
                15% Bonus upto ₹1000.00 (Min. deposit ₹2000)
              </p>
              <p
                className="mb-0"
                style={{ color: "#31B3FF", fontSize: "10px", opacity: "0.85" }}
              >
                Terms & Conditions
              </p>
            </div>
            <div
              className={`offer-card mt-2 mb-2 ${
                selectedOffer === "offer4" ? "active-offer" : ""
              }`}
              id="offer4"
              onClick={handleSelectedOffer}
            >
              <p className="text-muted text--medium mb-0">Welcome Bonus</p>
              <p className="mb-0 text--normal offer-detail">
                15% Bonus upto ₹1000.00 (Min. deposit ₹2000)
              </p>
              <p
                className="mb-0"
                style={{
                  color: "#31b3ffa0",
                  fontSize: "10px",
                  opacity: "0.75",
                }}
              >
                Terms & Conditions
              </p>
            </div>
          </div>
        </div> */}
      </div>
      <div className="col-md-6 col-lg-6 col-xl-6 col-12 deposit-col2">
        <div
          className="mt-3 px-3"
          style={{ borderBottom: "1px solid #707070", paddingBottom: "7px" }}
        >
          <p
            className="text--normal payment-detail"
            style={{ color: "#F5CA3D" }}
          >
            Payment Details
          </p>
          <div>
            <span className="text--normal mb-0">Amount</span>
            <span
              className="text--normal phone-amount-font"
              style={{ float: "right" }}
            >
              {enteredAmount || "00.00"} INR
            </span>
          </div>
          <div className="mt-1">
            <span className="text--normal mb-0">Bonus Offer</span>
            <span
              className="text--normal phone-amount-font"
              style={{ float: "right" }}
            >
              0.00 INR
            </span>
          </div>
        </div>
        <div className="px-3 mt-1">
          <span className="text--normal mb-0">Total Amount</span>
          <span
            className="text--normal phone-amount-font"
            style={{ float: "right" }}
          >
            {enteredAmount || "0.00"} INR
          </span>
        </div>
        <div className="mt-3 px-3 mobile-spacing">
          <p
            className="mb-0"
            style={{ fontWeight: "200", fontSize: "12px", opacity: "0.75" }}
          >
            Note:
          </p>
          <p
            className="text--normal"
            style={{ color: "#F5CA3D", fontWeight: "300", opacity: "0.75" }}
          >
            Bonus amount can be withdrawn only after 2X wagering
          </p>
        </div>
        <div className="action-btns px-3">
          <p className="text-center text--medium statement">
            Problem in making payment please contact customer support
          </p>
          <div className="d-flex justify-content-between">
            <button
              className="border-btn"
              style={{ color: "#F5CA3D", border: "1px solid #F5CA3D" }}
              onClick={showChatBox}
            >
              <img
                loading="lazy"
                src={newChatIcon}
                alt="logo"
                className="mx-1"
                width={isMobile ? 25 : 30}
              />
              Chat with us
            </button>
            <button className="standard-btn" onClick={goToNextStep}>
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
