import React, { useState } from "react";
import loginImage from "../../assets/images/login-.svg";
import signupImage from "../../assets/images/Group 4316.svg";
import gamesImage from "../../assets/images/Group 4318.svg";
import menuImage from "../../assets/images/menus.svg";
import activeLogin from "../../assets/images/login-active.svg";
import signupActive from "../../assets/images/signup-active.svg";
import gamesActive from "../../assets/images/games-active.svg";
import menuActive from "../../assets/images/menu-active.svg";
import newIconMobile from "../../assets/images/newIconMobile.png";
import activeWalletIcon from "../../assets/images/activeWalletIcon.svg";
import supportIcon from "../../assets/images/Online_Support.svg";
import giftIcon from "../../assets/images/giftIcon.svg";
import depositIcon from "../../assets/images/depositIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { openSignupModal } from "../../features/slices/ModalSlice";

import phonepay from "../../assets/images/paymentSlider/phonepay.svg";
import gpay from "../../assets/images/paymentSlider/gpay.svg";
import imps from "../../assets/images/paymentSlider/imps.svg";
import netbanking from "../../assets/images/paymentSlider/netbanking.svg";
import RTGS from "../../assets/images/paymentSlider/RTGS.svg";
import upi from "../../assets/images/paymentSlider/upi.svg";
import bgAware from "../../assets/images/bg-aware.svg";
import hand from "../../assets/images/hand.svg";
import agePlus from "../../assets/images/age-plus.svg";
import { showChatBoxInDesktop } from "../../features/slices/CommonSlice";
import {
  updateSelectedItem,
  updateOverlayItem,
} from "../../features/slices/CommonSlice";
import FooterGamesMenu from "./FooterGamesMenu";
import FooterMenuItems from "./FooterMenuItems";
import { openChatBox, hideChatBox } from "../../features/slices/CommonSlice";
import moment from "moment";
export function Footer() {
  const dispatch = useDispatch();
  const common = useSelector((state) => state.common);
  const user = useSelector((state) => state.user);

  const [activeItem, setActiveItem] = useState("");
  const [paymentSliderImages, setImages] = useState([
    phonepay,
    gpay,
    imps,
    netbanking,
    RTGS,
    upi,
    phonepay,
    gpay,
    imps,
    netbanking,
    RTGS,
    upi,
  ]);
  const handleClick = (event) => {
    const id = event.target.id || event.target.parentElement.id;
    if (id === "support") {
      dispatch(updateOverlayItem(null));
      if (common.selectedItem === "chat-bot") {
        dispatch(updateSelectedItem(null));
      } else {
        dispatch(updateSelectedItem("chat-bot"));
      }
      return;
    }
    if (common.overlayItem === id) {
      dispatch(updateOverlayItem(null));
      setActiveItem("");
      return;
    } else if (id === "games" || id === "menu") {
      if (common.selectedItem === "login" || common.selectedItem === "signup")
        dispatch(updateSelectedItem(null));
      dispatch(updateOverlayItem(id));
      setActiveItem(id);
      return;
    }
    if (common.selectedItem === id) {
      setActiveItem("");
      dispatch(updateSelectedItem(null));
    } else {
      if (id === "signup" || id === "login") dispatch(updateOverlayItem(null));
      setActiveItem(id);
      dispatch(updateSelectedItem(id));
    }
  };
  const openSignupPopup = () => {
    dispatch(openSignupModal());
  };

  const showChatBox = () => {
    dispatch(updateOverlayItem(null));
    if (common.selectedItem === "chat-bot") {
      dispatch(updateSelectedItem(null));
    } else {
      dispatch(updateSelectedItem("chat-bot"));
    }
  };
  const showChat = () => {
    dispatch(showChatBoxInDesktop());
  };
  const currentYear = moment().format("YYYY");
  return (
    <footer className="footer" id="footer">
      <div className="large-screen-footer">
        <div className="container-fluid pt-4 px-4">
          <div className="row px-1">
            <div className="col-3 footer-logo">
              <div
                className="w-100 text-center mb-3"
                style={{ marginTop: "-15px" }}
              >
                <img
                  loading="lazy"
                  width={170}
                  src={require("../../assets/images/logo2x.png")}
                />
                <p className="mb-1 text--normal">Play | Win | Repeat.</p>
                <div className="white-stp w-100">
                  Unleash Your Luck and Win Big
                </div>
              </div>
            </div>
            <div className="col-3 products-col">
              <div className="footer-title">Products</div>
              <ul className="p-0">
                {/* <li>About Us</li> */}
                <li onClick={showChat}>Contact Us</li>
              </ul>
            </div>
            <div className="col-3 products-col">
              <div className="footer-title">Services</div>
              <ul className="p-0">
                {/* <li>Payment Options</li> */}
                <li>
                  <a href="/terms">Terms of Use</a>
                </li>
                <li>
                  <a href="/privacy-policy">Privacy Policy</a>
                </li>
                {/* <li>FAQs</li> */}
              </ul>
            </div>
            <div className="col-3 products-col">
              <div className="footer-title pb-3">Share</div>
              <div className="social-icons d-flex">
                <img
                  loading="lazy"
                  src={require("../../assets/images/facebook.png")}
                />
                <img
                  loading="lazy"
                  src={require("../../assets/images/insta.png")}
                />
                <img
                  loading="lazy"
                  src={require("../../assets/images/twitter.png")}
                />
              </div>
              <div className="d-flex mt-2">
                <img
                  loading="lazy"
                  className="border border-light border-opacity-10 py-1 px-2 rounded"
                  src={bgAware}
                  style={{ marginRight: "12px" }}
                />
                <img
                  loading="lazy"
                  className="border border-light border-opacity-10 p-1 rounded"
                  style={{ marginRight: "12px" }}
                  src={hand}
                />
                <img
                  loading="lazy"
                  className="border border-light border-opacity-10 p-1 rounded"
                  src={agePlus}
                  style={{ marginRight: "12px" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid p-2 payment-slider">
          <div className="d-flex payment-slide">
            {paymentSliderImages.map((item, index) => {
              return (
                <img loading="lazy" key={index} className="mx-4" src={item} />
              );
            })}
          </div>
          <div className="d-flex payment-slide">
            {paymentSliderImages.map((item, index) => {
              return (
                <img loading="lazy" key={index} className="mx-4" src={item} />
              );
            })}
          </div>
        </div>
        <div className="container-fluid text-center p-3 copyright">
          <p className="mb-0 text--medium">
            Copyright {currentYear} @ kubera365 | All Rights Reserved
          </p>
        </div>
      </div>
      <FooterGamesMenu />
      <FooterMenuItems />
      <div className="small-screen-footer">
        <div className="mobile-footer d-flex justify-content-evenly">
          {user.userLoggedIn ? (
            <>
              <div onClick={handleClick} id="deposit">
                <img
                  loading="lazy"
                  src={
                    common.selectedItem === "deposit"
                      ? activeWalletIcon
                      : depositIcon
                  }
                />
                {common.selectedItem === "deposit" ? (
                  <p style={{ color: "#EFC04B" }}>Deposit</p>
                ) : (
                  <p>Deposit</p>
                )}
              </div>
              <div id="support" onClick={handleClick}>
                <img loading="lazy" src={supportIcon} />
                <p>Support</p>
              </div>
            </>
          ) : (
            <>
              <div onClick={handleClick} id="login">
                <img
                  loading="lazy"
                  src={
                    common.selectedItem === "login" ? activeLogin : loginImage
                  }
                />
                {common.selectedItem === "login" ? (
                  <p style={{ color: "#EFC04B" }}>Login</p>
                ) : (
                  <p>Login</p>
                )}
              </div>
              <div id="signup" onClick={handleClick}>
                <img
                  loading="lazy"
                  src={
                    common.selectedItem === "signup"
                      ? signupActive
                      : signupImage
                  }
                />
                {common.selectedItem === "signup" ? (
                  <p style={{ color: "#EFC04B" }}>Signup</p>
                ) : (
                  <p>Signup</p>
                )}
              </div>
            </>
          )}
          <div className="footer-mobile-logo">
            <img
              loading="lazy"
              src={newIconMobile}
              width={100}
              onClick={showChatBox}
            />
          </div>
          <div id="games" onClick={handleClick}>
            <img
              loading="lazy"
              src={common.overlayItem === "games" ? gamesActive : gamesImage}
            />
            {common.overlayItem === "games" ? (
              <p style={{ color: "#EFC04B" }}>Games</p>
            ) : (
              <p>Games</p>
            )}
          </div>
          {user.userLoggedIn ? (
            <div id="menu" onClick={handleClick}>
              <img
                loading="lazy"
                src={common.overlayItem === "menu" ? menuActive : menuImage}
              />
              {common.overlayItem === "menu" ? (
                <p style={{ color: "#EFC04B" }}>Menu</p>
              ) : (
                <p>Menu</p>
              )}
            </div>
          ) : (
            <div id="support" onClick={handleClick}>
              <img loading="lazy" src={supportIcon} />
              {common.overlayItem === "support" ? (
                <p style={{ color: "#EFC04B" }}>Menu</p>
              ) : (
                <p style={{ marginTop: "3px" }}>Support</p>
              )}
            </div>
          )}
        </div>
      </div>
    </footer>
  );
}
