import React, { useState, useEffect } from "react";
import { hideFilterMenu } from "../../features/slices/FilterSlice";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedItem } from "../../features/slices/CommonSlice";
import {
  checkFilterStatus,
  fetchGames,
  getAllGames,
  resetFilterStatus,
} from "../../features/slices/GamesSlice";
import { useMediaQuery } from "react-responsive";

export default function FiltersMenu(props) {
  const dispatch = useDispatch();
  const game = useSelector((state) => state.game);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [isCategoriesMenuOpen, setIsCategoriesMenuOpen] = useState(false);
  const [isProvidersMenuOpen, setIsProvidersMenuOpen] = useState(false);
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSubCategories, setselectedSubCategories] = useState([]);
  const [isIndian, setIsIndian] = useState("");
  const [popular, setPopular] = useState("");
  const [isNew, setIsNew] = useState("");
  const [allGamesPage, setAllGamesPage] = useState(false);
  const [rerender, setRerender] = useState(0);
  const toggleCategoriesMenu = () =>
    setIsCategoriesMenuOpen(!isCategoriesMenuOpen);
  const toggleProvidersMenu = () =>
    setIsProvidersMenuOpen(!isProvidersMenuOpen);

  const hideMenu = () => {
    dispatch(hideFilterMenu());
    dispatch(updateSelectedItem(null));
  };
  const getParams = () => {
    let params = { page: 1 };
    if (props.subCategory && props.subCategory.id) {
      params.subCategoryId = props.subCategory.id;
      params.categoryId = props.subCategory.categoryId;
    } else if (props.category) {
      params.categoryId = props.category.id;
    }
    return params;
  };
  const removeFilters = () => {
    setSelectedCategories([]);
    setSelectedProviders([]);
    setselectedSubCategories([]);
    setIsIndian("");
    setPopular("");
    setIsNew("");
    dispatch(resetFilterStatus());
    dispatch(hideFilterMenu());
    dispatch(updateSelectedItem(null));
    if (allGamesPage) return;
    if (props.allGames) {
      dispatch(getAllGames(getParams()));
      return;
    }
    dispatch(fetchGames(getParams()));
  };
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const applyFilter = (e, type, id = null) => {
    let providers = selectedProviders;
    let categories = selectedCategories;
    let subCategories = selectedSubCategories;
    const params = {};
    params.isIndian = isIndian === "indian" || "";
    params.isNewRelease = isNew === "new" || "";
    params.isPopular = popular === "popular" || "";
    if (type === "provider") {
      if (e.target.checked) {
        providers.push(id);
        setSelectedProviders(providers);
      } else {
        providers = providers.filter((item) => item !== id);
        setSelectedProviders(providers);
      }
    } else if (type === "category") {
      if (e.target.checked) {
        categories.push(id);
        setSelectedCategories(categories);
      } else {
        categories = categories.filter((item) => item !== id);
        setSelectedCategories(categories);
      }
    } else if (type === "sub-category") {
      if (e.target.checked) {
        subCategories.push(id);
        setselectedSubCategories(subCategories);
      } else {
        subCategories = subCategories.filter((item) => item !== id);
        setselectedSubCategories(subCategories);
      }
    }
    if (type === "indian") {
      setIsIndian(e.target.value);
      params.isIndian = e.target.value === "indian";
    }
    if (type === "popular") {
      setPopular(e.target.value);
      params.isPopular = e.target.value === "popular";
    }
    if (type === "new") {
      setIsNew(e.target.value);
      params.isNewRelease = e.target.value === "new";
    }
    params.providerId = [...providers].join(",");
    params.categoryId = [...categories].join(",");
    params.subCategoryId = [...subCategories].join(",");
    for (const key in params) {
      if (!params[key]) delete params[key];
    }
    if (!isMobile) {
      if (props.category) {
        params.categoryId = props.category.id;
      } else if (props.subCategory) {
        params.subCategoryId = props.subCategory.id;
        params.categoryId = props.subCategory.categoryId;
      }
      if (Object.keys(params).length) {
        props.filterApplied(true, params);
        if (!props.fromAllGames) dispatch(fetchGames({ ...params, page: 1 }));
      } else {
        props.filterApplied(false);
        removeFilters();
      }
    } else {
      let newParams = {};
      if (game.subCategories.length)
        newParams.categoryId = game.subCategories[0].categoryId;
      if (!allGamesPage)
        dispatch(fetchGames({ ...params, ...newParams, page: 1 }));
      else dispatch(getAllGames({ ...params, page: 1 }));
    }

    if (Object.keys(params).length) dispatch(checkFilterStatus(params));
    else dispatch(resetFilterStatus());
    setRerender(rerender + 1);
  };

  useEffect(() => {
    if (isMobile)
      if (window.location.href.includes("all-games")) setAllGamesPage(true);
  }, []);

  useEffect(() => {
    if (typeof game.filters.subCategoryId === "string") {
      const ids = game.filters.subCategoryId;
      setselectedSubCategories(ids.split(",").map((item) => +item));
    }
    if (typeof game.filters.providerId === "string") {
      const ids = game.filters.providerId;
      setSelectedProviders(ids.split(",").map((item) => +item));
    }
    if (typeof game.filters.categoryId === "string") {
      const ids = game.filters.categoryId;
      setSelectedCategories(ids.split(",").map((item) => +item));
    }
    if (game.filters.isIndian) setIsIndian("indian");
    if (game.filters.isPopular) setPopular("popular");
    if (game.filters.isNew) setIsNew("new");
  }, [game.filters]);
  const styles = {
    zIndex: "10",
    overflowY: "scroll",
    position: "fixed",
    height: "100vh",
    top: 0,
    width: "25%",
    background: "#111111",
  };

  return (
    <div
      className="filter-menu pb-4"
      id="filterMenu"
      style={!isMobile ? styles : {}}
      key={rerender}
    >
      <div className="header-parent-div">
        <div className="d-flex py-3 px-4 bg-black header" id="filter-header">
          <div
            className="w-100"
            onClick={hideMenu}
            style={{ cursor: "pointer" }}
          >
            <i className="bi bi-x-lg"></i>
            <b className="mx-2">FILTERS</b>
          </div>
          <p
            className="text--large mb-0"
            style={{
              color: "#2296EF",
              float: "right",
              cursor: "pointer",
            }}
            onClick={removeFilters}
          >
            Clear
          </p>
        </div>
      </div>
      <div className="spacer mt-5"></div>
      {props.allGames || allGamesPage ? (
        <div className="main">
          <div
            id="categories_filter"
            data-bs-toggle="collapse"
            href="#categories_filter_menu"
            onClick={toggleCategoriesMenu}
            role="button"
            aria-expanded="false"
            aria-controls="categories_filter_menu"
            className="py-4 px-3 d-flex categories_filter"
          >
            <p className="w-100 mb-0">Categories</p>
            {isCategoriesMenuOpen ? (
              <i
                className="bi bi-caret-right-fill"
                style={{ float: "right" }}
              ></i>
            ) : (
              <i
                className="bi bi-caret-down-fill"
                style={{ float: "right" }}
              ></i>
            )}
          </div>
          <div
            className="collapse multi-collapse show"
            id="categories_filter_menu"
          >
            <div>
              {game.categories && game.categories.length
                ? game.categories.map((item, index) => (
                    <div
                      className="filter-item border border-light border-opacity-10 border-top-0 border-end-0 border-start-0 px-3 py-4"
                      key={index}
                    >
                      <div className="d-flex">
                        <div className="w-100 d-flex">
                          <input
                            className="form-check-input mt-0 bg-black border border-light border-opacity-25"
                            type="checkbox"
                            aria-label="Checkbox for following text input"
                            checked={selectedCategories.includes(item.id)}
                            onChange={(e) =>
                              applyFilter(e, "category", item.id)
                            }
                          />
                          <p className="mx-3 mb-0">
                            {capitalizeFirstLetter(item.name)}
                          </p>
                        </div>
                        <h6 className="float-end">{item?.totalGames || ""}</h6>
                      </div>
                    </div>
                  ))
                : ""}
            </div>
            {/* <div className="see-all text-center p-1">
              <button className="w-100 pt-1 text-center">
                <p className="text--small mb-0">See All</p>
                <i className="bi bi-chevron-down"></i>
              </button>
            </div> */}
          </div>
        </div>
      ) : null}
      {game.subCategories && game.subCategories.length && !props.subCategory ? (
        <div className="main">
          <div
            id="subCategories_filter"
            data-bs-toggle="collapse"
            href="#subCategories_filter"
            onClick={toggleCategoriesMenu}
            role="button"
            aria-expanded="false"
            aria-controls="subCategories_filter"
            className="py-4 px-3 d-flex categories_filter"
          >
            <p className="w-100 mb-0">Sub Categories</p>
            {isCategoriesMenuOpen ? (
              <i
                className="bi bi-caret-right-fill"
                style={{ float: "right" }}
              ></i>
            ) : (
              <i
                className="bi bi-caret-down-fill"
                style={{ float: "right" }}
              ></i>
            )}
          </div>
          <div
            className="collapse multi-collapse show"
            id="subCategories_filter"
          >
            <div>
              {game.subCategories && game.subCategories.length
                ? game.subCategories.map((item, index) => (
                    <div
                      className="filter-item border border-light border-opacity-10 border-top-0 border-end-0 border-start-0 px-3 py-4"
                      key={index}
                    >
                      <div className="d-flex">
                        <div className="w-100 d-flex">
                          <input
                            className="form-check-input mt-0 bg-black border border-light border-opacity-25"
                            type="checkbox"
                            aria-label="Checkbox for following text input"
                            checked={selectedSubCategories.includes(item.id)}
                            onChange={(e) =>
                              applyFilter(e, "sub-category", item.id)
                            }
                          />
                          <p className="mx-3 mb-0">
                            {capitalizeFirstLetter(item.name)}
                          </p>
                        </div>
                        <h6 className="float-end">
                          {item.totalGames || ""}
                        </h6>
                      </div>
                    </div>
                  ))
                : ""}
            </div>
            {/* <div className="see-all text-center p-1">
              <button className="w-100 pt-1 text-center">
                <p className="text--small mb-0">See All</p>
                <i className="bi bi-chevron-down"></i>
              </button>
            </div> */}
          </div>
        </div>
      ) : null}
      <div className="main">
        <div
          id="providers_filter"
          data-bs-toggle="collapse"
          href="#providers_filter_menu"
          onClick={toggleProvidersMenu}
          role="button"
          aria-expanded="false"
          aria-controls="providers_filter_menu"
          className="py-4 px-3 d-flex providers_filter"
        >
          <p className="w-100 mb-0">Providers</p>
          {isProvidersMenuOpen ? (
            <i
              className="bi bi-caret-right-fill"
              style={{ float: "right" }}
            ></i>
          ) : (
            <i className="bi bi-caret-down-fill" style={{ float: "right" }}></i>
          )}
        </div>
        <div
          className="collapse multi-collapse show"
          id="providers_filter_menu"
        >
          <div>
            {game.providers && game.providers.length
              ? game.providers.map((item, index) => (
                  <div
                    className="filter-item border border-light border-opacity-10 border-top-0 border-end-0 border-start-0 px-3 py-4"
                    key={index}
                  >
                    <div className="d-flex">
                      <div className="w-100 d-flex">
                        <input
                          className="form-check-input mt-0 bg-black border border-light border-opacity-25"
                          type="checkbox"
                          aria-label="Checkbox for following text input"
                          onChange={(e) => applyFilter(e, "provider", item.id)}
                          checked={selectedProviders.includes(item.id)}
                        />
                        <p className="mx-3 mb-0">
                          {capitalizeFirstLetter(item.name)}
                        </p>
                      </div>
                      <h6 className="float-end">{item.totalGames || ""}</h6>
                    </div>
                  </div>
                ))
              : ""}
          </div>
          {/* <div className="see-all text-center p-1">
            <button className="w-100 p-1 text-center">
              <p className="text--medium mb-0">See All</p>
              <i className="bi bi-chevron-down"></i>
            </button>
          </div> */}
        </div>
      </div>

      <div className="p-3 main filter-item">
        <p className="w-100 mb-3">Indian</p>
        <div className="row">
          <div className="col">
            <div className="form-check">
              <input
                className="form-check-input mt-0 bg-black border border-light border-opacity-25"
                style={{ marginRight: "10px" }}
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                value="indian"
                checked={isIndian === "indian"}
                onChange={(e) => applyFilter(e, "indian")}
              />
              <label className="form-check-label" for="flexRadioDefault1">
                Yes
              </label>
            </div>
          </div>
          <div className="col">
            <div class="form-check">
              <input
                class="form-check-input mt-0 bg-black border border-light border-opacity-25"
                style={{ marginRight: "10px" }}
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                value="notIndian"
                checked={isIndian === "notIndian"}
                onChange={(e) => applyFilter(e, "indian")}
              />
              <label class="form-check-label" for="flexRadioDefault2">
                No
              </label>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="p-3 main filter-item">
        <p className="w-100 mb-3">Popular</p>
        <div className="row">
          <div className="col">
            <div className="form-check">
              <input
                className="form-check-input mt-0 bg-black border border-light border-opacity-25"
                style={{ marginRight: "10px" }}
                type="radio"
                name="popular"
                id="popular"
                value="popular"
                checked={popular === "popular"}
                onChange={(e) => applyFilter(e, "popular")}
              />
              <label className="form-check-label" for="popular">
                Yes
              </label>
            </div>
          </div>
          <div className="col">
            <div class="form-check">
              <input
                class="form-check-input mt-0 bg-black border border-light border-opacity-25"
                style={{ marginRight: "10px" }}
                type="radio"
                name="popular"
                id="noPopular"
                value="noPopular"
                checked={popular === "noPopular"}
                onChange={(e) => applyFilter(e, "popular")}
              />
              <label class="form-check-label" for="noPopular">
                No
              </label>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="p-3 main filter-item mobile-new-check">
        <p className="w-100 mb-3">New</p>
        <div className="row">
          <div className="col">
            <div className="form-check">
              <input
                className="form-check-input mt-0 bg-black border border-light border-opacity-25"
                style={{ marginRight: "10px" }}
                type="radio"
                name="new"
                id="new"
                value="new"
                checked={isNew === "new"}
                onChange={(e) => applyFilter(e, "new")}
              />
              <label className="form-check-label" for="new">
                Yes
              </label>
            </div>
          </div>
          <div className="col">
            <div class="form-check">
              <input
                class="form-check-input mt-0 bg-black border border-light border-opacity-25"
                style={{ marginRight: "10px" }}
                type="radio"
                name="new"
                id="notNew"
                value="notNew"
                checked={isNew === "notNew"}
                onChange={(e) => applyFilter(e, "new")}
              />
              <label class="form-check-label" for="notNew">
                No
              </label>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
}
