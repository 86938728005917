import React from "react";
import { closePaymentModal } from "../../../features/slices/ModalSlice";
import { useDispatch, useSelector } from "react-redux";
import WithdrawContent from "./WithdrawContent";
export default function MobileWithdraw() {
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(closePaymentModal());
  };

  return (
    <div
      className="modal fade show deposit--modal"
      style={{
        display: "block",
        backdropFilter: "blur(15px)",
        background: "rgb(70, 70, 70, 0.6)",
      }}
      tabIndex="-1"
    >
      <div className="modal-dialog modal-xl modal-dialog-centered ">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title fs-5">Withdraw</h2>

            <i onClick={closeModal} className="bi bi-x-lg"></i>
          </div>
          <div className="modal-body">
            <WithdrawContent />
          </div>
        </div>
      </div>
    </div>
  );
}
