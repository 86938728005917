import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "./assets/css/styles.scss";
import "./assets/css/responsive.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/js/bootstrap.min.js";
import PlayingInOtherTab from "./components/common/PlayingInOtherTab";
const container = document.getElementById("root");
const root = createRoot(container);
const initialLoad = localStorage.getItem("initialLoad");
const isThisActiveTab = sessionStorage.getItem("userIsActive");
// Tab in which game is active
const channel = new BroadcastChannel("my_channel");

// get Message from game active tab
channel.onmessage = (event) => {
  const message = event.data;
  if (message.closeGame) {
    sessionStorage.removeItem("userIsActive");
    localStorage.removeItem("initialLoad");
    setTimeout(() => {
      window.open("about:blank", "_self");
      localStorage.setItem("initialLoad", true);
    }, 1000);
  }
};
root.render(
  <Provider store={store}>
    {initialLoad && !isThisActiveTab ? <PlayingInOtherTab /> : <App />}
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
