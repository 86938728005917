import React from "react";
import { closeSignupModal } from "../../features/slices/ModalSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import SignupForm from "./SignupForm";

export default function Signup() {
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.modal);
  const hidePopup = () => {
    dispatch(closeSignupModal());
  };
  const closeModal = (event) => {
    if (event.target.id === "fadeModal") dispatch(closeSignupModal());
  };

  return (
    <>
      <div
        className="modal fade signup-modal show"
        tabIndex="-1"
        style={{
          display: "block",
          backdropFilter: "blur(15px)",
          background: "rgb(70, 70, 70, 0.6)",
        }}
        id="fadeModal"
        onClick={closeModal}
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title fs-5">Signup</h2>
              <i
                data-bs-dismiss="modal"
                aria-label="Close"
                className="bi bi-x-lg"
                onClick={hidePopup}
              ></i>
            </div>
            <div className="modal-body login-form signup-form">
              <SignupForm />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
