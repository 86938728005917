import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCountryList } from "../../features/slices/CommonSlice";
const Profile = () => {
  const dispatch = useDispatch();
  const common = useSelector((state) => state.common);
  const [activeMenuItem, setActiveMenuItem] = useState("UserProfile");
  const [Component, setComponent] = useState("UserProfile");
  const [isVerificationMenuOpen, setIsVerificationMenuOpen] = useState(false);
  const [isLedgerMenuOpen, setLedgerSubmenu] = useState(false);
  const loadComponent = async (activeMenuItem) => {
    const { default: DynamicComponent } = await import(`./${activeMenuItem}`);
    setComponent(() => DynamicComponent);
  };

  const handleMenuItemClick = (event) => {
    localStorage.setItem("profileScreen", event.target.id);
    setActiveMenuItem(event.target.id);
    loadComponent(event.target.id);
  };
  useEffect(() => {
    loadComponent(activeMenuItem);
  }, [activeMenuItem]);
  useEffect(() => {
    if (common.showBalance) {
      setActiveMenuItem("Balance");
      loadComponent("Balance");
    }
  }, [common.showBalance]);
  useEffect(() => {
    dispatch(getCountryList());
    const profileSection = localStorage.getItem("profileScreen");
    if (localStorage.getItem("showBalance")) {
      setActiveMenuItem("Balance");
      loadComponent("Balance");
    }
    if (profileSection) {
      setActiveMenuItem(profileSection);
      loadComponent(profileSection);
    }
    localStorage.removeItem("showBalance");
  }, []);

  const toggleVerificationMenu = () =>
    setIsVerificationMenuOpen(!isVerificationMenuOpen);
  const toggleSubMenu = () => setLedgerSubmenu(!isLedgerMenuOpen);
  // window.onbeforeunload = () => {
  //   localStorage.removeItem("profileScreen");
  // };
  return (
    <>
      <div className="pt-5 pb-5">
        <div className="container container-with-sidebar">
          <div className="row">
            <div className="col-md-3 sidebar">
              <ul>
                <li
                  id="UserProfile"
                  className={activeMenuItem === "UserProfile" ? "active" : ""}
                  onClick={handleMenuItemClick}
                >
                  <i className="bi bi-person-circle mx-1"></i> Profile
                </li>
                <li
                  id="Balance"
                  className={activeMenuItem === "Balance" ? "active" : ""}
                  onClick={handleMenuItemClick}
                >
                  <i className="bi bi-wallet mx-1"></i> Balance
                </li>
                <li
                  id="verification"
                  className={
                    isVerificationMenuOpen ||
                    localStorage.getItem("profileScreen") === "PhoneAndEmail" ||
                    localStorage.getItem("profileScreen") === "IdProof" ||
                    localStorage.getItem("profileScreen") === "BankVerification"
                      ? "verification-active active"
                      : ""
                  }
                  data-bs-toggle="collapse"
                  href="#multiCollapseExample1"
                  onClick={toggleVerificationMenu}
                  role="button"
                  aria-expanded="false"
                  aria-controls="multiCollapseExample1"
                >
                  <i className="bi bi-person-check mx-1"></i> Verification
                  {isVerificationMenuOpen ? (
                    <i className="submenu-arrow bi bi-chevron-down"></i>
                  ) : (
                    <i className="submenu-arrow bi bi-chevron-right"></i>
                  )}
                </li>
                <div
                  className={`collapse multi-collapse verification-menu ${
                    localStorage.getItem("profileScreen") === "PhoneAndEmail" ||
                    localStorage.getItem("profileScreen") === "IdProof" ||
                    localStorage.getItem("profileScreen") === "BankVerification"
                      ? "show"
                      : ""
                  }`}
                  id="multiCollapseExample1"
                >
                  <ul>
                    <li
                      id="PhoneAndEmail"
                      className={
                        activeMenuItem === "PhoneAndEmail" ? "active" : ""
                      }
                      onClick={handleMenuItemClick}
                    >
                      <i className="bi bi-check2 mx-1"></i>Phone
                    </li>
                    <li
                      id="IdProof"
                      className={activeMenuItem === "IdProof" ? "active" : ""}
                      onClick={handleMenuItemClick}
                    >
                      <i className="bi bi-check2 mx-1"></i>ID Proof
                    </li>
                    {/* <li
                      id="Selfie"
                      className={activeMenuItem === "Selfie" ? "active" : ""}
                      onClick={handleMenuItemClick}
                    >
                      <i className="bi bi-check2 mx-1"></i>Selfie
                    </li> */}
                    <li
                      id="BankVerification"
                      className={
                        activeMenuItem === "BankVerification" ? "active" : ""
                      }
                      onClick={handleMenuItemClick}
                    >
                      <i className="bi bi-check2 mx-1"></i>BankVerification
                    </li>
                  </ul>
                </div>
                <li
                  id="Security"
                  className={activeMenuItem === "Security" ? "active" : ""}
                  onClick={handleMenuItemClick}
                >
                  <i className="bi bi-shield-fill-check mx-1"></i> Security
                </li>
                <li
                  id="Ledger"
                  className={`${
                    localStorage.getItem("profileScreen") === "Finance" ||
                    localStorage.getItem("profileScreen") === "Bonus" ||
                    localStorage.getItem("profileScreen") ===
                      "GameTransactions" ||
                    isLedgerMenuOpen
                      ? "submenu-active active"
                      : ""
                  }`}
                  style={{ borderTop: "0.5px solid rgba(255, 255, 255, 0.2)" }}
                  data-bs-toggle="collapse"
                  href="#ledgerSubMenu"
                  onClick={toggleSubMenu}
                  role="button"
                  aria-expanded="false"
                  aria-controls="ledgerSubMenu"
                >
                  <i className="bi bi-clock-history mx-1"></i> Ledger
                  {isLedgerMenuOpen ? (
                    <i className="submenu-arrow bi bi-chevron-down"></i>
                  ) : (
                    <i className="submenu-arrow bi bi-chevron-right"></i>
                  )}
                </li>
                <div
                  className={`collapse multi-collapse verification-menu ${
                    localStorage.getItem("profileScreen") === "Finance" ||
                    localStorage.getItem("profileScreen") === "Bonus" ||
                    localStorage.getItem("profileScreen") === "GameTransactions"
                      ? "show"
                      : ""
                  }`}
                  id="ledgerSubMenu"
                >
                  <ul>
                    <li
                      id="Finance"
                      className={activeMenuItem === "Finance" ? "active" : ""}
                      onClick={handleMenuItemClick}
                    >
                      <i className="bi bi-check2 mx-1"></i> Finance
                    </li>
                    <li
                      id="GameTransactions"
                      className={
                        activeMenuItem === "GameTransactions" ? "active" : ""
                      }
                      onClick={handleMenuItemClick}
                    >
                      <i className="bi bi-check2 mx-1"></i>Game Transactions
                    </li>
                    <li
                      id="Bonus"
                      className={activeMenuItem === "Bonus" ? "active" : ""}
                      onClick={handleMenuItemClick}
                    >
                      <i className="bi bi-check2 mx-1"></i> All Transactions
                    </li>
                  </ul>
                </div>
              </ul>
            </div>
            <div className="col-md-9 content">{Component && <Component />}</div>
          </div>
        </div>
      </div>
      {/* <div className="parent-winner-slider">
        <div className="winner-slider d-flex">
          <div className="d-flex p-3 border-top border-light border-opacity-25">
            <img  loading="lazy"
              src={require("../../assets/images/winnerSliderImage.png")}
              width={90}
            />
            <div className="px-3">
              <p
                className="mb-0 text--medium"
                style={{ color: "#F5CA3D", fontWeight: "600" }}
              >
                Live Blackjack
              </p>
              <p className="mb-0 text--medium text--light">
                John Doe has just won ₹3000.00
              </p>
            </div>
          </div>
          <div className="d-flex p-3 border-top border-light border-opacity-25">
            <img  loading="lazy"
              src={require("../../assets/images/winnerSliderImage.png")}
              width={90}
            />
            <div className="px-3">
              <p
                className="mb-0 text--medium"
                style={{ color: "#F5CA3D", fontWeight: "600" }}
              >
                Live Blackjack
              </p>
              <p className="mb-0 text--medium text--light">
                John Doe has just won ₹3000.00
              </p>
            </div>
          </div>
          <div className="d-flex p-3 border-top border-light border-opacity-25">
            <img  loading="lazy"
              src={require("../../assets/images/winnerSliderImage.png")}
              width={90}
            />
            <div className="px-3">
              <p
                className="mb-0 text--medium"
                style={{ color: "#F5CA3D", fontWeight: "600" }}
              >
                Live Blackjack
              </p>
              <p className="mb-0 text--medium text--light">
                John Doe has just won ₹3000.00
              </p>
            </div>
          </div>
          <div className="d-flex p-3 border-top border-light border-opacity-25">
            <img  loading="lazy"
              src={require("../../assets/images/winnerSliderImage.png")}
              width={90}
            />
            <div className="px-3">
              <p
                className="mb-0 text--medium"
                style={{ color: "#F5CA3D", fontWeight: "600" }}
              >
                Live Blackjack
              </p>
              <p className="mb-0 text--medium text--light">
                John Doe has just won ₹3000.00
              </p>
            </div>
          </div>
          <div className="d-flex p-3 border-top border-light border-opacity-25">
            <img  loading="lazy"
              src={require("../../assets/images/winnerSliderImage.png")}
              width={90}
            />
            <div className="px-3">
              <p
                className="mb-0 text--medium"
                style={{ color: "#F5CA3D", fontWeight: "600" }}
              >
                Live Blackjack
              </p>
              <p className="mb-0 text--medium text--light">
                John Doe has just won ₹3000.00
              </p>
            </div>
          </div>
          <div className="d-flex p-3 border-top border-light border-opacity-25">
            <img  loading="lazy"
              src={require("../../assets/images/winnerSliderImage.png")}
              width={90}
            />
            <div className="px-3">
              <p
                className="mb-0 text--medium"
                style={{ color: "#F5CA3D", fontWeight: "600" }}
              >
                Live Blackjack
              </p>
              <p className="mb-0 text--medium text--light">
                John Doe has just won ₹3000.00
              </p>
            </div>
          </div>
          <div className="d-flex p-3 border-top border-light border-opacity-25">
            <img  loading="lazy"
              src={require("../../assets/images/winnerSliderImage.png")}
              width={90}
            />
            <div className="px-3">
              <p
                className="mb-0 text--medium"
                style={{ color: "#F5CA3D", fontWeight: "600" }}
              >
                Live Blackjack
              </p>
              <p className="mb-0 text--medium text--light">
                John Doe has just won ₹3000.00
              </p>
            </div>
          </div>
        </div>
        <div className="winner-slider d-flex">
          <div className="d-flex p-3 border-top border-light border-opacity-25">
            <img  loading="lazy"
              src={require("../../assets/images/winnerSliderImage.png")}
              width={90}
            />
            <div className="px-3">
              <p
                className="mb-0 text--medium"
                style={{ color: "#F5CA3D", fontWeight: "600" }}
              >
                Live Blackjack
              </p>
              <p className="mb-0 text--medium text--light">
                John Doe has just won ₹3000.00
              </p>
            </div>
          </div>
          <div className="d-flex p-3 border-top border-light border-opacity-25">
            <img  loading="lazy"
              src={require("../../assets/images/winnerSliderImage.png")}
              width={90}
            />
            <div className="px-3">
              <p
                className="mb-0 text--medium"
                style={{ color: "#F5CA3D", fontWeight: "600" }}
              >
                Live Blackjack
              </p>
              <p className="mb-0 text--medium text--light">
                John Doe has just won ₹3000.00
              </p>
            </div>
          </div>
          <div className="d-flex p-3 border-top border-light border-opacity-25">
            <img  loading="lazy"
              src={require("../../assets/images/winnerSliderImage.png")}
              width={90}
            />
            <div className="px-3">
              <p
                className="mb-0 text--medium"
                style={{ color: "#F5CA3D", fontWeight: "600" }}
              >
                Live Blackjack
              </p>
              <p className="mb-0 text--medium text--light">
                John Doe has just won ₹3000.00
              </p>
            </div>
          </div>
          <div className="d-flex p-3 border-top border-light border-opacity-25">
            <img  loading="lazy"
              src={require("../../assets/images/winnerSliderImage.png")}
              width={90}
            />
            <div className="px-3">
              <p
                className="mb-0 text--medium"
                style={{ color: "#F5CA3D", fontWeight: "600" }}
              >
                Live Blackjack
              </p>
              <p className="mb-0 text--medium text--light">
                John Doe has just won ₹3000.00
              </p>
            </div>
          </div>
          <div className="d-flex p-3 border-top border-light border-opacity-25">
            <img  loading="lazy"
              src={require("../../assets/images/winnerSliderImage.png")}
              width={90}
            />
            <div className="px-3">
              <p
                className="mb-0 text--medium"
                style={{ color: "#F5CA3D", fontWeight: "600" }}
              >
                Live Blackjack
              </p>
              <p className="mb-0 text--medium text--light">
                John Doe has just won ₹3000.00
              </p>
            </div>
          </div>
          <div className="d-flex p-3 border-top border-light border-opacity-25">
            <img  loading="lazy"
              src={require("../../assets/images/winnerSliderImage.png")}
              width={90}
            />
            <div className="px-3">
              <p
                className="mb-0 text--medium"
                style={{ color: "#F5CA3D", fontWeight: "600" }}
              >
                Live Blackjack
              </p>
              <p className="mb-0 text--medium text--light">
                John Doe has just won ₹3000.00
              </p>
            </div>
          </div>
          <div className="d-flex p-3 border-top border-light border-opacity-25">
            <img  loading="lazy"
              src={require("../../assets/images/winnerSliderImage.png")}
              width={90}
            />
            <div className="px-3">
              <p
                className="mb-0 text--medium"
                style={{ color: "#F5CA3D", fontWeight: "600" }}
              >
                Live Blackjack
              </p>
              <p className="mb-0 text--medium text--light">
                John Doe has just won ₹3000.00
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Profile;
