import React from "react";
import tableGameIcon from "../../assets/images/tableGameIcon.svg";
import slotsGameIcon from "../../assets/images/slots.svg";
import pokerIcon from "../../assets/images/pokerIcon.svg";
import award from "../../assets/images/award.svg";
import { useDispatch, useSelector } from "react-redux";
import { updateOverlayItem } from "../../features/slices/CommonSlice";
import aviatrixLogo from "../../assets/images/aviatrix-logo.png";

export default function FooterGamesMenu() {
  const dispatch = useDispatch();
  const common = useSelector((state) => state.common);
  const game = useSelector((state) => state.game);
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const hideSubmenu = (event) => {
    if (event.target.id === "submenu") dispatch(updateOverlayItem(null));
  };
  const getCategoryIcon = (name) => {
    switch (name) {
      case "table":
        return tableGameIcon;
      case "slots":
        return slotsGameIcon;
      case "games":
        return pokerIcon;
      default:
        break;
    }
  };
  return (
    <div
      id="submenu"
      onClick={hideSubmenu}
      className={`${
        common.overlayItem === "games" ? "show-sub-menu" : "hide-sub-menu"
      } footer-submenu-container`}
    >
      <div className="games-menu p-3">
        <p>Games</p>
        <div className="row p-2">
          {game.categories.map((item, index) => (
            <div className="col-4 text-center" key={index}>
              <a href={`/${item.name}`}>
                <div className="game-type">
                  <img
                    loading="lazy"
                    src={getCategoryIcon(item.name)}
                    alt="games"
                  />
                  <p className="text--medium text--light mb-0 mt-1">
                    {capitalizeFirstLetter(item.name)}
                  </p>
                </div>
              </a>
            </div>
          ))}
          <div className="col-4 text-center">
            <div className="game-type">
              <a href="/all-games">
                <img loading="lazy" src={award} alt="games" />
                <p className="text--medium text--light mb-0 mt-1">All Games</p>
              </a>
            </div>
          </div>
          <div className="col-4 text-center">
            <a href="/play-aviatrix" style={{ height: "100%" }}>
              <div className="game-type" style={{ height: "100%" }}>
                <img loading="lazy" src={aviatrixLogo} alt="games" />
                <p className="text--medium text--light mb-0 mt-1">Aviatrix</p>
              </div>
            </a>
          </div>
          {/* <div className="col-4 text-center">
            <div className="game-type">
              <i className="bi bi-bell-fill"></i>
              <p className="text--medium text--light mb-0 mt-1">
                Notifications
              </p>
            </div>
          </div>
          <div className="col-4 text-center">
            <div className="game-type">
              <i className="bi bi-gift-fill"></i>
              <p className="text--medium text--light mb-0 mt-1">Bonus</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
