import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { startLoading, stopLoading } from "./LoadingSlice";
import api from "../../api";
// Define the async thunk action creator
export const fetchTopBanners = createAsyncThunk(
  "game/fetchTopBanners",
  async (_, { dispatch }) => {
    dispatch(startLoading());
    try {
      const response = await api.get(`/banners?bannerType=top_banner`);
      return response.data;
    } catch (error) {
      // Handle error condition
      throw Error("Error fetching user data");
    } finally {
      dispatch(stopLoading());
    }
  }
);
export const fetchCategories = createAsyncThunk(
  "game/getCategories",
  async (_, { dispatch }) => {
    dispatch(startLoading());
    try {
      const response = await api.get(`/games/categories`);
      return response.data;
    } catch (error) {
      // Handle error condition
      throw Error("Error fetching user data");
    } finally {
      dispatch(stopLoading());
    }
  }
);
export const fetchProviders = createAsyncThunk(
  "game/getProviders",
  async (_, { dispatch }) => {
    dispatch(startLoading());
    try {
      const response = await api.get(`/games/providers`);
      return response.data;
    } catch (error) {
      // Handle error condition
      throw Error("Error fetching user data");
    } finally {
      dispatch(stopLoading());
    }
  }
);
export const fetchSubCategories = createAsyncThunk(
  "game/getSubCategories",
  async (id, { dispatch }) => {
    try {
      const response = await api.get(`/games/subCategories?categoryId=${id}`);
      return response.data;
    } catch (error) {
      // Handle error condition
      throw Error("Error fetching user data");
    } finally {
    }
  }
);
export const fetchGames = createAsyncThunk(
  "game/getGames",
  async (data, { dispatch }) => {
    dispatch(startLoading());
    let params = "?";
    for (const key in data) {
      params += `${key}=${data[key]}&`;
    }
    try {
      const response = await api.get(`/games/list${params}`);
      return response.data;
    } catch (error) {
      // Handle error condition
      throw Error("Error fetching user data");
    } finally {
      dispatch(stopLoading());
    }
  }
);
export const getGameUrl = createAsyncThunk(
  "game/getGameUrl",
  async (id, { dispatch }) => {
    dispatch(startLoading());
    try {
      localStorage.removeItem("isUserPlaying");
      const response = await api.get(`/games/getGameUrl?openTableId=${id}`);
      return response.data;
    } catch (error) {
      // Handle error condition
      throw Error(error.response.data.error);
    } finally {
      dispatch(stopLoading());
    }
  }
);
export const getAviatrixUrl = createAsyncThunk(
  "game/getAviatrixUrl",
  async (_, { dispatch }) => {
    dispatch(startLoading());
    try {
      localStorage.removeItem("isUserPlaying");
      const response = await api.get(`/games/getAviatrixGameUrl`);
      return response.data;
    } catch (error) {
      // Handle error condition
      throw Error(error.response.data.error);
    } finally {
      dispatch(stopLoading());
    }
  }
);
export const searchGames = createAsyncThunk(
  "game/searchGames",
  async (data, { dispatch }) => {
    let params = "?";
    for (const key in data) {
      params += `${key}=${data[key]}&`;
    }
    try {
      const response = await api.get(`/games/list${params}`);
      return response.data;
    } catch (error) {
      // Handle error condition
      throw Error("Error fetching user data");
    }
  }
);

export const getAllGames = createAsyncThunk(
  "game/getAllGames",
  async (data, { dispatch }) => {
    dispatch(startLoading());

    let params = "?";
    for (const key in data) {
      params += `${key}=${data[key]}&`;
    }
    try {
      const response = await api.get(`/games/listAll${params}`);
      return response.data;
    } catch (error) {
      // Handle error condition
      throw Error("Error fetching user data");
    } finally {
      dispatch(stopLoading());
    }
  }
);
// Create a slice for your API-related actions and reducers
const gameSlice = createSlice({
  name: "game",
  initialState: {
    data: null,
    error: null,
    categories: [],
    gamesData: null,
    subCategories: [],
    subCategoryLoader: false,
    providers: [],
    gameUrl: null,
    aviatrixUrl: null,
    searchedGames: null,
    allGames: [],
    filterApplied: false,
    filters: {},
  },
  reducers: {
    resetFilterStatus(state) {
      state.filters = {};
      state.filterApplied = false;
    },
    checkFilterStatus(state, action) {
      state.filterApplied = true;
      state.filters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTopBanners.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(fetchTopBanners.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.categories = action.payload.data;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(fetchGames.fulfilled, (state, action) => {
        state.gamesData = action.payload.data;
      })
      .addCase(fetchGames.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(fetchSubCategories.pending, (state, action) => {
        state.subCategoryLoader = true;
      })
      .addCase(fetchSubCategories.fulfilled, (state, action) => {
        state.subCategories = action.payload.data;
        state.subCategoryLoader = false;
      })
      .addCase(fetchSubCategories.rejected, (state, action) => {
        state.error = action.error.message;
        state.subCategoryLoader = false;
      })
      .addCase(fetchProviders.fulfilled, (state, action) => {
        state.providers = action.payload.data;
      })
      .addCase(fetchProviders.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(getGameUrl.fulfilled, (state, action) => {
        state.gameUrl = action.payload.data;
      })
      .addCase(getGameUrl.rejected, (state, action) => {
        state.error = "Error - " + action.error.message;
      })
      .addCase(getAviatrixUrl.fulfilled, (state, action) => {
        state.aviatrixUrl = action.payload.data;
      })
      .addCase(getAviatrixUrl.rejected, (state, action) => {
        state.error = "Error - " + action.error.message;
      })
      .addCase(searchGames.fulfilled, (state, action) => {
        state.searchedGames = action.payload.data;
      })
      .addCase(searchGames.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(getAllGames.fulfilled, (state, action) => {
        state.allGames = action.payload.data;
      })
      .addCase(getAllGames.rejected, (state, action) => {
        state.error = action.error.message;
      });
  },
});

// Export the async thunk action creator and the reducer
export const { checkFilterStatus, resetFilterStatus } = gameSlice.actions;
export const { reducer } = gameSlice;
export default gameSlice.reducer;
