import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closePaymentMethod,
  openPaymentCompletionModal,
  closePaymentModal,
} from "../../../features/slices/ModalSlice";
import copyIcon from "../../../assets/images/copy.svg";
import { useMediaQuery } from "react-responsive";
import ActionButtons from "./ActionButtons";
import { updateSelectedItem } from "../../../features/slices/CommonSlice";
import { createDepositRequest } from "../../../features/slices/PaymentSlice";
import ErrorCard from "../../common/ErrorCard";
export default function IMPSPayment() {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const modal = useSelector((state) => state.modal);
  // const user = useSelector((state) => state.user);
  const payment = useSelector((state) => state.payment);
  const [referenceNumber, setReferenceNumber] = useState("");
  const [redBorder, setRedBorder] = useState(false);
  const [tos, setTos] = useState(false);
  const [copyMessage, setCopyMessage] = useState("");
  const [checkValidation, setValidationRemider] = useState(false);

  const timer = useRef(null);

  // const accountDetails = payment.paymentMethods.find(
  //   (item) => item.id == modal.depositData.paymentMethodId
  // ).details;
  const dispatch = useDispatch();
  const details = payment.paymentMethods.find(
    (item) => item.name === "imps"
  ).details;

  const goToNextStep = (event) => {
    const form = document.querySelector(".needs-validation");
    if (referenceNumber.length !== 12) {
      setRedBorder(true);
      event.preventDefault();
      return;
    }
    if (form.checkValidity()) event.preventDefault();
    if (!tos) {
      if (isMobile) {
        const section = document.getElementById("checkboxId");
        section.scrollIntoView({ behavior: "smooth" });
        setValidationRemider(true);
      }
      return;
    }
    dispatch(
      createDepositRequest({
        ...modal.depositData,
        referenceNumber: referenceNumber,
        tos: tos,
      })
    );
  };
  useEffect(() => {
    if (payment.depositRequestedSuccessfully) {
      dispatch(closePaymentMethod());
      if (isMobile) dispatch(updateSelectedItem("depositCompleted"));
      else {
        dispatch(openPaymentCompletionModal("Deposit"));
        dispatch(closePaymentModal());
      }
    }
  }, [payment.depositRequestedSuccessfully]);
  useEffect(() => {
    if (isMobile)
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
  }, [payment.error]);

  const copyItem = async (id) => {
    clearTimeout(timer.current);
    const copyText = document.getElementById(id).textContent;
    try {
      await navigator.clipboard.writeText(copyText);
      console.log("Content copied to clipboard");
      setCopyMessage(id);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
    timer.current = setTimeout(() => {
      setCopyMessage("");
    }, 1000);
  };
  return (
    <>
      {payment.error && isMobile ? <ErrorCard error={payment.error} /> : null}
      <div className="row imps--p">
        <div
          className="col-lg-6 col-md-6 col-xl-6 pt-4 pb-4 px-5 imps--p1"
          style={{ borderRight: "0.5px solid #70707071", position: "relative" }}
        >
          <p className="text--medium" style={{ color: "#EFC04B" }}>
            Bank Details
          </p>
          <label className="text--normal low--opacity text-muted">
            Account no.
          </label>
          <div className="d-flex w-100 mb-2">
            <p className="mb-0 w-100 user-select-none" id="acc_no">
              {details?.accountNo || ""}
            </p>
            <div
              style={{
                float: "right",
                position: "relative",
                cursor: "pointer",
              }}
              className="copy-btn copy-img"
            >
              <span
                className={
                  copyMessage === "acc_no"
                    ? "copied text-center text--small"
                    : "not-copied"
                }
              >
                Copied
              </span>
              <button className="copy-info-btn">
                <img
                  loading="lazy"
                  src={copyIcon}
                  onClick={() => copyItem("acc_no")}
                  alt="copy"
                />
              </button>
            </div>
          </div>
          <label className="text--normal low--opacity text-muted">IFSC</label>
          <div className="d-flex w-100 mb-2">
            <p className="mb-0 w-100 user-select-none" id="ifsc">
              {details?.ifscCode}
            </p>
            <div
              style={{
                float: "right",
                position: "relative",
                cursor: "pointer",
              }}
              className="copy-btn copy-img"
            >
              <span
                className={
                  copyMessage === "ifsc"
                    ? "copied text-center text--small"
                    : "not-copied"
                }
              >
                Copied
                <div></div>
              </span>
              <button className="copy-info-btn">
                <img
                  loading="lazy"
                  src={copyIcon}
                  onClick={() => copyItem("ifsc")}
                  alt="copy"
                />
              </button>
            </div>
          </div>
          <label className="text--normal low--opacity text-muted">
            Account Name
          </label>
          <div className="d-flex w-100 mb-2">
            <p className="mb-0 w-100 user-select-none" id="acc_name">
              {details?.accountName || ""}
            </p>
            <div
              style={{
                float: "right",
                position: "relative",
                cursor: "pointer",
              }}
              className="copy-btn copy-img"
            >
              <span
                className={
                  copyMessage === "acc_name"
                    ? "copied text-center text--small"
                    : "not-copied"
                }
              >
                Copied
                <div></div>
              </span>
              <button className="copy-info-btn">
                <img
                  loading="lazy"
                  src={copyIcon}
                  onClick={() => copyItem("acc_name")}
                  alt="copy"
                />
              </button>
            </div>
          </div>
          <label className="text--normal low--opacity text-muted">
            Bank Name
          </label>
          <div className="d-flex w-100 mb-2">
            <p className="mb-0 w-100 user-select-none" id="b_name">
              {details?.bankName || ""}
            </p>
            <div
              style={{
                float: "right",
                position: "relative",
                cursor: "pointer",
              }}
              className="copy-btn copy-img"
            >
              <span
                className={
                  copyMessage === "b_name"
                    ? "copied text-center text--small"
                    : "not-copied"
                }
              >
                Copied
                <div></div>
              </span>
              <button className="copy-info-btn">
                <img
                  loading="lazy"
                  src={copyIcon}
                  onClick={() => copyItem("b_name")}
                  alt="copy"
                />
              </button>
            </div>
          </div>

          {/* <p className="text--medium mt-3">
            <span style={{ opacity: "0.75" }}>How to know your</span>{" "}
            <b>UTR Number</b>
          </p>

          <img  loading="lazy" src={gif} className={isMobile ? "mb-5 w-100" : ""} alt="gif" /> */}

          {isMobile && <ActionButtons toNext={goToNextStep} />}
        </div>

        <div
          className="col-md-6 col-lg-6 col-xl-6 px-4 pt-3 pb-5 imps--p2"
          style={{ position: "relative" }}
        >
          <form className="needs-validation">
            <div className="amount-info text-center">
              Please deposit{" "}
              <span style={{ color: "#F0C451" }}>
                ₹ {modal.depositData.amount}/-
              </span>
            </div>
            <label className="text--medium text--light mb-2">
              Enter the Transaction Reference Number(UTR) <span>*</span>
            </label>
            <div className="trn-utr mb-2" style={{ paddingRight: "1rem" }}>
              <input
                type="number"
                placeHolder="123456789123"
                className={
                  redBorder ? "red-border form-control" : "form-control"
                }
                required
                onChange={(e) => {
                  setReferenceNumber(e.target.value);
                  setRedBorder(false);
                }}
              />
            </div>
            {referenceNumber.length !== 12 ? (
              <p
                className="text--medium"
                style={{ color: "#FF5252", paddingRight: "1rem" }}
              >
                Must key in 12 numeric number
              </p>
            ) : null}
            <p className="text--small text--light" id="checkboxId">
              Please make payment according to the Amount Stated on the QR page
              within the Limited Time, if there's any losses due to wrong amound
              paid or did not complete the whole payment process. COMPANY WILL
              NOT BEAR THE RESPONSIBILITY.
            </p>

            <div className="d-flex margin--bottom">
              <input
                className="form-check-input mt-0 checkbox-upi"
                type="checkbox"
                onChange={(e) => {
                  setTos(e.target.checked);
                  setValidationRemider(false);
                }}
                required
                style={
                  checkValidation
                    ? { border: "1px solid red" }
                    : { border: "1px solid #707070" }
                }
              />
              <p
                className="text--medium text--light mx-2 checkbox-label-upi"
                style={{ marginTop: "3px" }}
              >
                I have read and agree with the terms of{" "}
                <span style={{ color: "#31B3FF" }}>
                  <a
                    href="/privacy-policy"
                    style={{ textDecoration: "none", color: "#31B3FF" }}
                  >
                    payment and withdrawal policy
                  </a>
                </span>
              </p>
            </div>
            {payment.error && !isMobile ? (
              <ErrorCard error={payment.error} />
            ) : null}
            {!isMobile && <ActionButtons toNext={goToNextStep} />}
          </form>
        </div>
        {isMobile && <hr></hr>}
      </div>
    </>
  );
}
