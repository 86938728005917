import React, { useEffect, useState } from "react";
import xButton from "../../assets/images/x-button.svg";
import { useDispatch, useSelector } from "react-redux";
import { removeBankDetails } from "../../features/slices/PaymentSlice";
import { getUserData } from "../../features/slices/UserSlice";
export default function DeleteBankDetail({ accountInfo, closeModal }) {
  const dispatch = useDispatch();
  const [removed, setRemoved] = useState(false);
  const removeAccount = () => {
    dispatch(removeBankDetails({ bankId: accountInfo.bankId }));
  };
  const payment = useSelector((state) => state.payment);
  useEffect(() => {
    if (payment.bankRemoved) {
      setRemoved(true);
      dispatch(getUserData({ source: "delete_bank_detail", showLoader: true }));
    }
  }, [payment]);

  return (
    <div
      className="modal fade cancel-request-modal show"
      style={{
        display: "block",
        background: "rgba(0, 0, 0, 0.8)",
      }}
      tabIndex="-1"
    >
      <div className="modal-dialog modal-dialog-centered delete-bank-popup">
        <div
          className="modal-content p-5"
          style={{ background: "#1D1D1D", width: "100%" }}
        >
          <i onClick={closeModal} className="bi bi-x-lg"></i>
          <div className="modal-body mobile-otp-modal--body text-center">
            {removed ? (
              <>
                <img
                  loading="lazy"
                  src={require("../../assets/images/payment-check.png")}
                  alt="check"
                  className="mb-3"
                  width={40}
                />
                <p className="text--medium">Account Removed Successfully!</p>
                <button className="yes-btn mx-2" onClick={() => closeModal()}>
                  Okay
                </button>
              </>
            ) : (
              <>
                <img loading="lazy" src={xButton} alt="x-button" />
                {payment.error ? (
                  <p
                    className="text--small mt-3"
                    style={{ color: "rgb(255, 120, 120)" }}
                  >
                    Error: {payment.error}
                  </p>
                ) : null}
                <p className="mt-3 mb-2" style={{ fontSize: "12px" }}>
                  Delete Bank Account End with **********
                  {(accountInfo.accountNumber % 1000).toString()}
                </p>
                <p style={{ fontSize: "10px" }}>
                  Are you sure you want to delete the Bank Account?
                </p>
                <div className="actions d-flex justify-content-center mt-4">
                  <button className="yes-btn mx-2" onClick={removeAccount}>
                    Yes
                  </button>
                  <button className="no-btn mx-2" onClick={() => closeModal()}>
                    No
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
