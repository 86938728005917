import React, { useState, useEffect } from "react";
import { Carousel } from "../home/slider";
import { useDispatch, useSelector } from "react-redux";
import filterButton from "../../assets/images/filters.svg";
import SearchField from "../common/SearchField";
import { showFilterMenu } from "../../features/slices/FilterSlice";
import FiltersMenu from "./FiltersMenu";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import { openLoginModal } from "../../features/slices/ModalSlice";
import {
  fetchGames,
  fetchSubCategories,
} from "../../features/slices/GamesSlice";
import seeMoreImage from "../../assets/images/see-more.svg";
import { makeFavourite, hideToast } from "../../features/slices/UserSlice";

export default function Game() {
  const { id } = useParams();
  const categories = useSelector((state) => state.categories);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const dispatch = useDispatch();
  const filter = useSelector((state) => state.filter);
  const user = useSelector((state) => state.user);
  const game = useSelector((state) => state.game);
  const [slides, setSlides] = useState(new Array(16).fill(""));
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [rerender, setrerender] = useState(0);
  const [subCategory, setSubCategory] = useState({});
  const [filterCheck, setFilterCheck] = useState(false);
  const [allFetched, setAllFetched] = useState(false);

  const openFilters = () => {
    dispatch(showFilterMenu());
  };
  const expandSlides = () => {
    console.log(subCategory);
    setPage(page + 1);
    setTimeout(() => {
      dispatch(
        fetchGames({
          categoryId: subCategory.categoryId,
          subCategoryId: subCategory.id,
          page: page + 1,
        })
      );
    }, 0);
  };
  useEffect(() => {
    if (filter.isFilterMenuVisible && !isMobile) {
      // Scroll to a specific position
      const filterHeader = document.getElementById("filter-header");
      filterHeader.classList.add("sticky");
      const section = document.getElementById("gamesDiv");
      section.scrollIntoView({ behavior: "smooth" });
    }
  }, [filter.isFilterMenuVisible]);

  useEffect(() => {
    if (game.categories.length) {
      const categoryName = window.location.href.split("/")[3];
      const category = game.categories.find(
        (item) => item.name === categoryName
      );
      if (category) dispatch(fetchSubCategories(category.id));
    }
  }, [game.categories]);

  useEffect(() => {
    if (game.subCategories.length) {
      const subCategoryFound = game.subCategories.find(
        (item) => item.name === id
      );
      setSubCategory(subCategoryFound);
      if (subCategoryFound)
        dispatch(
          fetchGames({
            categoryId: subCategoryFound.categoryId,
            subCategoryId: subCategoryFound.id,
            page: page,
          })
        );
    }
  }, [game.subCategories]);
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const setFilterApplied = (val) => {
    setFilterCheck(val);
  };
  useEffect(() => {
    let games = [];
    setAllFetched(false);
    if (!filterCheck) games = data;
    if (game.gamesData && game.gamesData.length) {
      game.gamesData.forEach((element) => {
        const subCategoryName = capitalizeFirstLetter(element.subCategory);
        const obj = {};
        let alreadyFoundInGames = games.find(
          (item) => item.subCategory === subCategoryName
        );
        if (alreadyFoundInGames) {
          alreadyFoundInGames.games = [
            ...alreadyFoundInGames.games,
            ...element.games.filter(
              (item2) =>
                !alreadyFoundInGames.games.some(
                  (item1) => item1.id === item2.id
                )
            ),
          ];
        } else {
          obj.subCategory = subCategoryName;
          obj.games = element.games;
          games.push(obj);
        }
      });
      setData(games);
      setrerender(rerender + 1);
    } else {
      setAllFetched(true);
      console.log("here we go", game);
      // setData([]);
    }
  }, [game.gamesData]);
  const addToFavourites = (game) => {
    if (user.userLoggedIn) {
      dispatch(makeFavourite({ gameId: game.id, fromCategroyPage: true }));
      setTimeout(() => {
        dispatch(hideToast());
      }, 3000);
    } else {
      dispatch(openLoginModal("login"));
      localStorage.setItem("toAddFav", JSON.stringify(game));
    }
  };
  useEffect(() => {
    if (localStorage.getItem("toAddFav")) {
      const game = JSON.parse(localStorage.getItem("toAddFav"));
      dispatch(makeFavourite({ gameId: game.id, fromCategroyPage: true }));
    }
    localStorage.removeItem("toAddFav");
  }, [user.userLoggedIn]);
  const openLoginWhenLoggedOut = (id, item) => {
    if (!allFetched && id == item.games.length - 1 && item.games.length > 7) {
      expandSlides(item.games[0].subCategoryId, item.subCategory);
      return;
    }
    if (!user.userLoggedIn) dispatch(openLoginModal());
  };

  const showSeeMore = (id, item) => {
    return !allFetched && id == item.games.length - 1 && item.games.length > 7;
  };
  return (
    <div>
      <Carousel />
      <SearchField />
      <div className="row">
        {filter.isFilterMenuVisible && !isMobile && (
          <div className="col-3">
            <FiltersMenu
              subCategory={subCategory}
              filterApplied={setFilterApplied}
            />
          </div>
        )}
        {user.madeFavourite && (
          <div
            class="toast align-items-center text-bg-dark fade show position-fixed bottom-0 end-0 p-3"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div class="d-flex">
              <div class="toast-body">Game Added to Favourites!</div>
              <button
                type="button"
                class="btn-close btn-close-white me-2 m-auto"
                data-bs-dismiss="toast"
                aria-label="Close"
              ></button>
            </div>
          </div>
        )}
        <div className={`${filter.isFilterMenuVisible ? "col-9" : "col-12"}`}>
          <div className="games custom-padding" key={rerender} id="gamesDiv">
            {data.length ? (
              data.map((item, index) => (
                <div key={index}>
                  <h4>{item.subCategory}</h4>
                  <div className="row mt-3 mb-5">
                    {item.games.length &&
                      item.games.map((game, id) => {
                        return (
                          <div
                            className={`game-card mb-4 ${
                              filter.isFilterMenuVisible
                                ? "col-4"
                                : "col-xl-3 col-lg-3 col-md-3 col-6"
                            }`}
                            key={id}
                            onClick={() => openLoginWhenLoggedOut(id, item)}
                          >
                            {showSeeMore(id, item) ? (
                              <>
                                <img
                                  loading="lazy"
                                  width="100%"
                                  height="100%"
                                  src={game.imagePath}
                                  alt="game"
                                />
                                <i
                                  className="bi bi-star"
                                  onClick={(e) => {
                                    e.preventDefault(); // Prevent redirection
                                    addToFavourites(game);
                                  }}
                                  style={{ cursor: "pointer" }}
                                  title="Add To Favorites"
                                ></i>
                                <div className="game--name">
                                  <p>{game.name}</p>
                                </div>
                                <div className="see-more-parent">
                                  <div
                                    className="see-more d-flex"
                                    width={100}
                                    height={100}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation(); // Prevent redirection
                                      expandSlides(item.games[0].subCategoryId);
                                    }}
                                  >
                                    <img
                                      loading="lazy"
                                      src={seeMoreImage}
                                      width="35"
                                      height="35"
                                      alt="see-more"
                                    />
                                    <p className="text-center mb-0">
                                      <b>See More</b>
                                    </p>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <a
                                href={
                                  user.userLoggedIn &&
                                  `/play-game/${game.opentableId}`
                                }
                              >
                                <img
                                  loading="lazy"
                                  width="100%"
                                  height="100%"
                                  src={game.imagePath}
                                  alt="game"
                                />
                                <i
                                  className="bi bi-star"
                                  onClick={(e) => {
                                    e.preventDefault(); // Prevent redirection
                                    addToFavourites(game);
                                  }}
                                  style={{ cursor: "pointer" }}
                                  title="Add To Favorites"
                                ></i>
                                <div className="game--name">
                                  <p>{game.name}</p>
                                </div>
                              </a>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </div>
              ))
            ) : (
              <p>No Games found!</p>
            )}
          </div>
        </div>
      </div>
      <img
        loading="lazy"
        className={`filters ${
          filter.isFilterMenuVisible ? "hide-filter-btn" : ""
        }`}
        id="filters"
        src={filterButton}
        onClick={openFilters}
      />
    </div>
  );
}
