// utils.js

export function getUserInfo() {
  const userAgent = navigator.userAgent;

  // Get browser name and version
  let browser = "";
  if (userAgent.indexOf("Chrome") > -1) {
    browser = "Chrome";
  } else if (userAgent.indexOf("Firefox") > -1) {
    browser = "Firefox";
  } else if (userAgent.indexOf("Safari") > -1) {
    browser = "Safari";
  } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
    browser = "Opera";
  } else if (
    userAgent.indexOf("Trident") > -1 ||
    userAgent.indexOf("MSIE") > -1
  ) {
    browser = "Internet Explorer";
  }

  const versionRegex = new RegExp(`${browser}/([\\d.]+)`);
  const versionMatch = userAgent.match(versionRegex);
  const browserVersion =
    versionMatch && versionMatch.length > 1 ? versionMatch[1] : "";

  browser = browser + " " + browserVersion;

  // Get OS name
  let os = "";
  if (userAgent.indexOf("Windows") > -1) {
    os = "Windows";
  } else if (userAgent.indexOf("Mac") > -1) {
    os = "MacOS";
  } else if (userAgent.indexOf("Linux") > -1) {
    os = "Linux";
  } else if (userAgent.indexOf("Android") > -1) {
    os = "Android";
  } else if (userAgent.indexOf("iOS") > -1) {
    os = "iOS";
  }

  // Get IP address
  const ipAddressPromise = fetch("https://api.ipify.org?format=json")
    .then((response) => response.json())
    .then((data) => data.ip)
    .catch((error) => {
      console.error("Error fetching IP address:", error);
      return "";
    });

  // Get device information
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent
    );
  const device = isMobile ? "Mobile" : "Desktop";

  return Promise.all([ipAddressPromise])
    .then(([ip]) => ({
      browser,
      os,
      ip,
      device,
    }))
    .catch((error) => {
      console.error("Error retrieving user info:", error);
      return {};
    });
}
