import React from "react";
import WithdrawContent from "../../transactions/withdraw/WithdrawContent";
import { updateSelectedItem } from "../../../features/slices/CommonSlice";
import { useDispatch, useSelector } from "react-redux";

export default function MobileWithdraw() {
  const dispatch = useDispatch();
  const closeWithdraw = () => {
    dispatch(updateSelectedItem(null));
  };
  return (
    <div>
      <div className="d-flex px-3 mt-2">
        <i
          className="bi bi-arrow-left"
          onClick={closeWithdraw}
          style={{
            fontSize: "25px",
            marginRight: "10px",
          }}
        ></i>
        <p className="mb-0" style={{ marginTop: "5px" }}>
          Withdraw
        </p>
      </div>
      <WithdrawContent />
    </div>
  );
}
