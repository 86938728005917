import React, { useState } from "react";
import chatyellow from "../../assets/images/chat-yellow.svg";
import deposit from "../../assets/images/deposit-yellow.svg";
import refer from "../../assets/images/refer-yellow.svg";
import favourite from "../../assets/images/favourite-yellow.svg";
import account from "../../assets/images/account-yellow.svg";
import withdraw from "../../assets/images/withdraw-yellow.svg";
import transaction from "../../assets/images/transaction-yellow.svg";
import setting from "../../assets/images/setting-yellow.svg";
import newChatIcon from "../../assets/images/newChatIcon.png";
import { useDispatch, useSelector } from "react-redux";
import {
  closePaymentCompletionModal,
  openPaymentMethod,
  openPaymentModal,
} from "../../features/slices/ModalSlice";
import Cookies from "js-cookie";
import {
  showChatBoxInDesktop,
  updateSelectedItem,
} from "../../features/slices/CommonSlice";
import { reset } from "../../features/slices/PaymentSlice";
import { useParams } from "react-router-dom";
import { logoutUser } from "../../features/slices/UserSlice";
import AstrologyPopup from "../astrology/AstrologyPopup";
export default function SideBarMenu() {
  const user = useSelector((state) => state.user);
  const { query } = useParams();
  const dispatch = useDispatch();
  const [astrologyModal, setShowAstrologyModal] = useState(false);
  const [list, setList] = useState([
    {
      src: deposit,
      name: "Deposit",
      id: "Deposit",
    },
    {
      src: withdraw,
      name: "Withdraw",
      id: "Withdraw",
    },
    {
      src: transaction,
      name: "Transactions",
      id: "Transactions",
    },
    // {
    //   src: account,
    //   name: "Account",
    //   id: "Account",
    // },
    {
      src: favourite,
      name: "Favourite Games",
      id: "favourite",
    },
    {
      src: setting,
      name: "Settings",
      id: "Settings",
    },
    {
      src: setting,
      name: "Astrology",
      id: "Astrology",
    },
    // {
    //   src: refer,
    //   name: "Refer a Friend",
    // },
    {
      src: newChatIcon,
      name: "Chat With us",
      id: "chat",
    },
  ]);
  const showModal = (event) => {
    const id = event.target.id || event.target.parentElement.id;
    if (id === "Astrology") {
      if (user.userData.dob) {
        window.location.pathname = "/astrology";
        return;
      }
      setShowAstrologyModal(true);
      return;
    }
    if (id === "Transactions") {
      localStorage.setItem("profileScreen", "Bonus");
      window.location.pathname = "/profile";
    }
    if (id === "chat") {
      dispatch(showChatBoxInDesktop());
    }
    if (id.includes("fav")) {
      if (window.location.pathname === "/") {
        const section = document.getElementById("favGames");
        section.scrollIntoView({ behavior: "smooth" });
        return;
      }
      const mainUrl = window.location.protocol + "//" + window.location.host;
      localStorage.setItem("moveToFavourite", true);
      window.open(mainUrl, "_self");
    }
    dispatch(
      openPaymentModal(event.target.id || event.target.parentElement.id)
    );

    dispatch(updateSelectedItem(null));
    dispatch(closePaymentCompletionModal());
    dispatch(reset());
  };
  const logouUser = () => {
    Cookies.remove("tokenExpires");
    localStorage.clear();
    sessionStorage.clear();
    dispatch(logoutUser());
  };
  const getFormattedBalance = (balance) => {
    if (balance) {
      if (typeof balance === "number") return balance.toLocaleString();
      if (!balance.includes(",")) {
        const intBalance = parseInt(balance);
        return intBalance.toLocaleString();
      }
    }
    return "0";
  };

  return (
    <div>
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="sidebarMenu"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <h6
            className="offcanvas-title text--large"
            id="offcanvasRightLabel"
            style={{ color: "#fff" }}
          >
            Menu
          </h6>
          <i
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            className="bi bi-x-lg"
            style={{ cursor: "pointer" }}
          ></i>
        </div>
        <div className="offcanvas-body">
          <div className="p-3 offcanvas-body--upper">
            <div className="d-flex m-auto justify-content-between mb-4">
              <h3
                className="fs-6 mb-0"
                style={{ color: "#EFC04B", marginTop: "2px" }}
              >
                {user.userData.username}
              </h3>
              {/* <span className="account">
                <a href="/profile">Account</a>
              </span> */}
            </div>
            <div className="balance-info text--black mb-3">
              <div className="balance d-flex m-auto justify-content-between">
                <div className="total-bal">
                  <p className="text--normal mb-1" style={{ fontSize: "15px" }}>
                    Total Bal.
                  </p>
                  <p className="mb-0">
                    <strong>
                      ₹
                      {user.userData?.socketBalance ||
                        user.userData.redisBalance ||
                        user.userData.totalBalance}
                    </strong>
                  </p>
                </div>
                <div className="withdrawl-bal">
                  <p className="text--normal mb-1" style={{ fontSize: "15px" }}>
                    Withdrawl Bal.
                  </p>
                  <p className="mb-0">
                    <strong>
                      ₹
                      {user.userData?.socketBalance ||
                        user.userData.redisBalance ||
                        user.userData.totalBalance}
                    </strong>
                  </p>
                </div>
                <div className="bonus-bal">
                  <p className="text--normal mb-1" style={{ fontSize: "15px" }}>
                    Bonus
                  </p>
                  <p className="mb-0">
                    <strong>
                      ₹{getFormattedBalance(user.userData.bonus) || "0"}
                    </strong>
                  </p>
                </div>
              </div>
              {/* <div className="bal-progress">
                <div className="circle--progress"></div>
                <span className="progress-value text--medium">
                  <b>72.4%</b>
                </span>
              </div> */}
            </div>
          </div>
          <div className="off-canvas-lower-body">
            {list.map((item, index) => {
              return item.name === "Settings" ? (
                <a
                  href="/profile"
                  className="text-decoration-none"
                  style={{ color: "#fff" }}
                >
                  <div
                    onClick={showModal}
                    className="d-flex sidebar-menu-item px-3 pb-3 pt-2"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    id={item.id}
                    key={index}
                  >
                    <img
                      loading="lazy"
                      src={item.src}
                      alt={item.name}
                      width="20"
                      height="20"
                    />
                    <span className="text--normal mx-3 low-opacity">
                      {item.name}
                    </span>
                  </div>
                </a>
              ) : (
                <div
                  onClick={showModal}
                  className="d-flex sidebar-menu-item px-3 pb-3 pt-2"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  id={item.id}
                  key={index}
                >
                  <img
                    loading="lazy"
                    src={item.src}
                    alt={item.name}
                    width="20"
                  />

                  <span
                    className="text--normal mx-3 low-opacity"
                    style={{ color: "#fff" }}
                  >
                    {item.name}
                  </span>
                </div>
              );
            })}

            <div className="p-3 logout-btn-div">
              <button
                className="p-3 w-100 logout-btn low-opacity"
                onClick={logouUser}
              >
                LOGOUT
              </button>
            </div>
          </div>
        </div>
      </div>
      {astrologyModal && (
        <AstrologyPopup closeModal={() => setShowAstrologyModal(false)} />
      )}
    </div>
  );
}
