import React, { useState, useEffect } from "react";
import ChatBotOption from "./ChatBotOption";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import {
  closeLoginModal,
  openforgotPasswordModal,
  openMobileOtpModal,
  openSignupModal,
} from "../../features/slices/ModalSlice";
import { updateSelectedItem } from "../../features/slices/CommonSlice";
import {
  loginUser,
  relogin,
  resetError,
  sendMobileOTP,
  storePhoneAndCountry,
  saveUserCreds,
} from "../../features/slices/UserSlice";
import Cookies from "js-cookie";
export default function LoginForm() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const modal = useSelector((state) => state.modal);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [phone, setPhone] = useState("+91");
  const [isvalid, setIsValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isInfoIncorrect, setIsInfoIncorrect] = useState(true);
  const [countryCode, setCountryCode] = useState("");
  const [password, setPassword] = useState("");
  const [defaultCountry, setDefaultCountry] = useState("IN");
  const [localError, setLocalError] = useState("");
  const handlePhoneNumberChange = (value) => {
    if (value) setIsValid(isValidPhoneNumber(value));
    setLocalError("");
    if (value && isValidPhoneNumber(value)) {
      const phoneNumber = parsePhoneNumber(value);
      setDefaultCountry(phoneNumber.country);
      setCountryCode("+" + phoneNumber.countryCallingCode);
      setPhone(phoneNumber.nationalNumber);
    } else {
      setPhone(value);
    }
  };
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  const openForgotPasswordPopup = (initialOtpVerification = false) => {
    if (isMobile)
      dispatch(
        updateSelectedItem({
          name: "forgotPassword",
          initialOtpVerification: initialOtpVerification,
        })
      );
    else {
      dispatch(closeLoginModal());
      dispatch(openforgotPasswordModal(initialOtpVerification));
    }
  };
  const handlePasswordInput = (event) => {
    setPassword(event.target.value);
  };
  const openSignup = (e) => {
    e.preventDefault();
    if (isMobile) dispatch(updateSelectedItem("signup"));
    else {
      dispatch(openSignupModal());
      dispatch(relogin());
      dispatch(closeLoginModal());
    }
  };
  const login = (event) => {
    const form = document.querySelector(".needs-validation");

    if (form.checkValidity()) {
      event.preventDefault();
      if (!isValidPhoneNumber(countryCode + "" + phone)) {
        setLocalError("Phone number is not correct");
        return;
      }
      if (isvalid) {
        let payload;
        const refToken = Cookies.get("refreshToken");
        if (refToken) {
          payload = {
            refreshToken: refToken || "",
            grantType: "refreshToken",
          };
        } else
          payload = {
            phone: phone,
            countryCode: countryCode,
            password: password,
            grantType: "password",
          };
        dispatch(
          storePhoneAndCountry({ countryCode: countryCode, phone: phone })
        );
        dispatch(
          saveUserCreds({
            phone: phone,
            countryCode: countryCode,
            password: password,
            grantType: "password",
          })
        );
        dispatch(loginUser(payload));
      }
    }
  };

  const closeLoginForm = () => {
    dispatch(updateSelectedItem(null));
  };

  useEffect(() => {
    if (user.userLoggedIn) {
      closeLoginForm();
    }
  }, [user.userLoggedIn]);
  // useEffect(() => {
  //   if (user.continueSession) {
  //     const refToken = Cookies.get("refreshToken");
  //     const payload = {
  //       grantType: "refreshToken",
  //       refreshToken: refToken,
  //     };
  //     dispatch(loginUser(payload));
  //   }
  // }, [user.continueSession]);

  const styles = {
    backgroundColor: isMobile ? "#111111" : "none",
    height: isMobile ? "100vh" : "auto",
  };

  const verifyMobileOtp = () => {
    dispatch(resetError());
    dispatch(sendMobileOTP(user.phoneDetails));
    // openForgotPasswordPopup(true);
  };

  useEffect(() => {
    if (user.otpSent) {
      dispatch(closeLoginModal());
      // const isFromForgotPassword = !modal.initialOtpVerification;
      if (!isMobile) dispatch(openMobileOtpModal(false));
      else
        dispatch(
          updateSelectedItem({
            name: "mobileOTP",
            fromForgotPassword: false,
          })
        );
    }
  }, [user.otpSent]);

  return (
    <form className="needs-validation login-form">
      <div
        className={`${isMobile ? "form-mobile px-3 pt-3 pb-5" : ""}`}
        style={styles}
      >
        {isMobile && (
          <p className="text-center" style={{ color: "#EFC04B" }}>
            Login
          </p>
        )}
        {/* {user.userLoggedIn && (
          <div className="login-success p-4 text-center mb-4">
            <p className="mb-0 text--medium">Login Successful !</p>
          </div>
        )} */}

        <div className="incorrect-details-div mb-3">
          {user.error ? (
            <p className="text-center message">
              {user.error}{" "}
              <span
                style={{ color: "#31b3ff", cursor: "pointer" }}
                className="verify-otp"
                onClick={verifyMobileOtp}
              >
                {user.error === "Please verify first otp for login."
                  ? "Verify Now"
                  : ""}
              </span>
            </p>
          ) : null}
          {localError ? (
            <p className="text-center message">{localError} </p>
          ) : null}
          <ChatBotOption />
        </div>

        <b>Mobile</b>
        <PhoneInput
          placeholder="Enter phone number"
          className={`mb-3 text-input ${
            isvalid ? "form-control is-valid" : ""
          }`}
          onChange={handlePhoneNumberChange}
          defaultCountry={defaultCountry}
          // international
          autoComplete="off"
        ></PhoneInput>
        <b>Password</b>
        <div className="password mb-3 text-input">
          <input
            type={`${showPassword ? "text" : "password"}`}
            name="password"
            id="password"
            onChange={handlePasswordInput}
            required
            placeholder="Enter Password"
          />
          <i
            className={`bi ${showPassword ? "bi-eye" : "bi-eye-slash"}`}
            id="togglePassword"
            style={{ fontSize: "18px", marginTop: "-3px" }}
            onClick={togglePassword}
          ></i>
        </div>
        <div className="d-flex w-100 justify-content-between">
          <span className="d-flex">
            <input
              className="form-check-input mt-0"
              type="checkbox"
              value=""
              aria-label="Checkbox for following text input"
            />
            <p className="remember-me" style={{ marginTop: "2px" }}>
              Remember me
            </p>
          </span>
          <span
            className="forgot-password"
            onClick={() => openForgotPasswordPopup(false)}
          >
            Forgot Password?
          </span>
        </div>
        <button
          className="login-btn mb-3 mt-3 p-2"
          type="submit"
          onClick={login}
        >
          Login
        </button>
        <p className="text-center not--account">Don't have an account?</p>
        <button className="signup-btn mt-2 p-2" onClick={(e) => openSignup(e)}>
          Signup
        </button>
        {isMobile && (
          <button className="signup-btn mt-4 p-2" onClick={closeLoginForm}>
            Close
          </button>
        )}
      </div>
    </form>
  );
}
