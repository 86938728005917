import React, { useEffect } from "react";
import DepositContent from "../../transactions/deposit/DepositContent";
import { updateSelectedItem } from "../../../features/slices/CommonSlice";
import { useDispatch, useSelector } from "react-redux";
import UPIPayment from "../../transactions/deposit/UPIPayment";
import IMPSPayment from "../../transactions/deposit/IMPSPayment";
import { closePaymentMethod } from "../../../features/slices/ModalSlice";
import { getPaymentMethods } from "../../../features/slices/PaymentSlice";

export default function MobileDeposit() {
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.modal);
  const common = useSelector((state) => state.common);
  const closeDeposit = () => {
    if (modal.nextStep) {
      dispatch(closePaymentMethod());
    } else dispatch(updateSelectedItem(null));
  };
  useEffect(() => {
    dispatch(getPaymentMethods());
  }, []);

  return (
    <div>
      <div className="d-flex px-3 mt-2">
        <i
          className="bi bi-arrow-left"
          style={{
            fontSize: "25px",
            marginRight: "10px",
          }}
          onClick={closeDeposit}
        ></i>
        <p className="mb-0" style={{ marginTop: "5px" }}>
          {modal.nextStep || "Deposit"}
        </p>
      </div>
      {modal.nextStep === "UPI" && <UPIPayment />}
      {modal.nextStep === "IMPS" && <IMPSPayment />}
      {!modal.nextStep && <DepositContent />}
    </div>
  );
}
