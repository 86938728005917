import React from "react";
import check from "../../assets/images/check.svg";
import { closeThanksPopup } from "../../features/slices/ModalSlice";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../../features/slices/UserSlice";
import { useMediaQuery } from "react-responsive";
export default function Thanks() {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(closeThanksPopup());
    dispatch(getUserData());
    // if (isMobile) window.location.reload();
  };
  return (
    <div>
      <div
        className={`modal fade show otp--modal`}
        style={{ display: "block", backdropFilter: "blur(15px)" }}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title fs-5"></h2>
              <i onClick={closeModal} className="bi bi-x-lg"></i>
            </div>
            <div className="modal-body mobile-otp-modal--body text-center">
              <div className="text-center">
                <img
                  loading="lazy"
                  src={check}
                  alt="verification-complete"
                  width={80}
                />
                <p className="text---large mt-4">Thanks for Registering</p>
                <p className="text-muted text--medium">
                  Sign Up has been successful!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
