import React from "react";

export default function Loader() {
  return (
    <div className="loader">
      <div className="loader-box d-flex justify-content-center p-4">
        {/* <div className="spinner-border text-info" role="status">
          <span className="visually-hidden">Loading...</span>
        </div> */}
        <div className="text-center">
          <img
            loading="lazy"
            src={require("../../assets/images/loading.gif")}
            width={200}
            height={200}
          />
        </div>
      </div>
    </div>
  );
}
