import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
  name: "modal",
  initialState: {
    isLoginModalVisible: false,
    isSingupModalVisible: false,
    isMobileOtpModalVisible: false,
    openCompletionModal: false,
    isMobileVerificationCompleted: false,
    showForgotPasswordModal: false,
    isFromForgotPassword: false,
    nextStep: "",
    countdown: 30,
    paymentModal: "",
    paymentType: "",
    selectedModal: null,
    mobileLogin: false,
    thanksPopupVisible: false,
    depositData: {},
    initialOtpVerification: false,
  },
  reducers: {
    openLoginModal: (state, action) => {
      state.isFromForgotPassword = false;
      state.isLoginModalVisible = true;
      state.selectedModal = action.payload;
      state.initialOtpVerification = false;
    },
    closeLoginModal: (state) => {
      state.isLoginModalVisible = false;
      state.selectedModal = null;
    },
    toggleMobileModal: (state) => {
      state.mobileLogin = !state.mobileLogin;
    },
    openSignupModal: (state) => {
      state.isSingupModalVisible = true;
    },
    closeSignupModal: (state) => {
      state.isSingupModalVisible = false;
    },
    openMobileOtpModal: (state, action) => {
      state.isFromForgotPassword = false;
      state.isMobileOtpModalVisible = true;
      if (action.payload) state.isFromForgotPassword = true;
    },
    closeMobileOtpModal: (state) => {
      state.isMobileOtpModalVisible = false;
      state.completionModal = true;
    },
    openPopup: (state, action) => {
      if (action.payload === "mobile")
        state.isMobileVerificationCompleted = true;
      else state.isMobileVerificationCompleted = false;
      state.openCompletionModal = true;
    },
    closePopup: (state) => {
      state.openCompletionModal = false;
    },
    openThanksPopup(state) {
      state.thanksPopupVisible = true;
    },
    closeThanksPopup(state) {
      state.thanksPopupVisible = false;
    },
    tick(state) {
      state.countdown -= 1;
    },
    resetCountdown(state) {
      state.countdown = 30;
    },
    closeforgotPasswordModal(state) {
      state.showForgotPasswordModal = false;
    },
    openforgotPasswordModal(state, action) {
      state.initialOtpVerification = action.payload;
      state.showForgotPasswordModal = true;
    },
    openPaymentModal(state, action) {
      state.paymentModal = action.payload;
    },
    closePaymentModal(state) {
      state.paymentModal = "";
    },
    setNextStep(state, action) {
      state.nextStep = action.payload;
    },
    openPaymentMethod(state, action) {
      const { amount, offerCode, paymentMethodId } = action.payload;
      state.depositData.amount = amount;
      state.depositData.offerCode = offerCode;
      state.depositData.paymentMethodId = paymentMethodId;
      state.nextStep = action.payload.nextStep;
    },
    closePaymentMethod(state) {
      state.nextStep = "";
    },
    openPaymentCompletionModal(state, action) {
      state.paymentType = action.payload;
    },
    closePaymentCompletionModal(state) {
      state.paymentType = "";
    },
  },
});

export const {
  openLoginModal,
  closeLoginModal,
  openSignupModal,
  closeSignupModal,
  openMobileOtpModal,
  closeMobileOtpModal,
  tick,
  resetCountdown,
  openPopup,
  closePopup,
  openforgotPasswordModal,
  closeforgotPasswordModal,
  openPaymentModal,
  closePaymentModal,
  setNextStep,
  openPaymentMethod,
  closePaymentMethod,
  openPaymentCompletionModal,
  closePaymentCompletionModal,
  toggleMobileModal,
  openThanksPopup,
  closeThanksPopup,
} = modalSlice.actions;

export default modalSlice.reducer;
