import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoginLogs,
  resetError,
  updateUser,
} from "../../features/slices/UserSlice";
import { useMediaQuery } from "react-responsive";

export default function Security() {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [updatePassword, setUpdatePassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setLocalMessage] = useState("");
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const changePassword = () => {
    setUpdatePassword(true);
  };
  const togglePassword1 = () => {
    setShowPassword1(!showPassword1);
  };
  const togglePassword2 = () => {
    setShowPassword2(!showPassword2);
  };
  const togglePassword3 = () => {
    setShowPassword3(!showPassword3);
  };

  const updateNewPassword = () => {
    if (!currentPassword || !newPassword || !confirmPassword) {
      setLocalMessage("Please Enter All Passwords");
      return;
    }
    if (newPassword.length < 8) {
      setLocalMessage("Password must contain 8 characters");
      return;
    }
    if (newPassword.length && confirmPassword !== newPassword) {
      setLocalMessage("Password Should Match");
      return;
    }
    dispatch(
      updateUser({
        password: currentPassword,
        newPassword: newPassword,
        confirmPassword: confirmPassword,
      })
    );
  };

  useEffect(() => {
    if (user.userUpdated) {
      setUpdatePassword(false);
      setLocalMessage("Password Updated Successfully!");
      setTimeout(() => {
        setLocalMessage("");
        if (!isMobile) dispatch(resetError());
      }, 2000);
    }
  }, [user.userUpdated]);

  useEffect(() => {
    if (currentPassword || newPassword || confirmPassword) {
      if (!isMobile) dispatch(resetError());
    }
  }, [currentPassword, newPassword, confirmPassword]);

  useEffect(() => {
    dispatch(resetError());
    dispatch(getLoginLogs());
  }, []);

  return (
    <div className="security-sm">
      <h6 className="mt-3">Security</h6>
      {updatePassword ? (
        <div className="mt-3 update-password-container">
          {message ? (
            <p className="text--medium" style={{ color: "red" }}>
              <i class="bi bi-exclamation-octagon"></i> {message}
            </p>
          ) : null}
          {user.error ? (
            <p className="text--medium" style={{ color: "red" }}>
              <i class="bi bi-exclamation-octagon"></i> {user.error}
            </p>
          ) : null}
          <div className="row">
            <div className="col-md-8">
              <label className="text--medium">Current Password</label>
              <div className="password mb-4 mt-2">
                <input
                  type={`${showPassword1 ? "text" : "password"}`}
                  name="password"
                  id="password1"
                  onChange={(e) => {
                    setCurrentPassword(e.target.value);
                    setLocalMessage("");
                  }}
                />
                <i
                  className={`bi ${showPassword1 ? "bi-eye" : "bi-eye-slash"}`}
                  id="togglePassword"
                  onClick={togglePassword1}
                ></i>
              </div>
              <label className="text--medium">New Password</label>
              <div className="password mb-1 mt-2">
                <input
                  type={`${showPassword2 ? "text" : "password"}`}
                  name="password"
                  id="password2"
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    setLocalMessage("");
                  }}
                />
                <i
                  className={`bi ${showPassword2 ? "bi-eye" : "bi-eye-slash"}`}
                  id="togglePassword"
                  onClick={togglePassword2}
                ></i>
              </div>
              {newPassword.length < 8 ? (
                <p className="text-muted text--small">
                  Password should contain 8 characters
                </p>
              ) : null}
              <label className="text--medium">Confirm Password</label>
              <div className="password mb-1 mt-2">
                <input
                  type={`${showPassword3 ? "text" : "password"}`}
                  name="password"
                  id="password3"
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setLocalMessage("");
                  }}
                />
                <i
                  className={`bi ${showPassword3 ? "bi-eye" : "bi-eye-slash"}`}
                  id="togglePassword"
                  onClick={togglePassword3}
                ></i>
              </div>
              {!newPassword.length || newPassword !== confirmPassword ? (
                <p className="text-muted text--small">
                  Both passwords should match
                </p>
              ) : null}
              <div className="d-flex justify-content-between mt-4">
                <button
                  onClick={() => setUpdatePassword(false)}
                  className="border-btn"
                >
                  Cancel
                </button>
                <button className="standard-btn" onClick={updateNewPassword}>
                  Change Password
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-3 pt-3">
              <p
                className="text-light text--medium"
                style={{ fontWeight: "200" }}
              >
                Password
              </p>
              <button
                onClick={changePassword}
                className="w-100 standard-btn change-password mb-5"
              >
                Change Password
              </button>
            </div>
            {message ? (
              <div className="col-xl-4 col-lg-4 col-md-4 pt-4">
                <p
                  className="text--medium mb-0 password-updated-message"
                  style={{ color: "yellowgreen", marginTop: "30px" }}
                >
                  {message}
                </p>
              </div>
            ) : null}
          </div>
          <h6>Login History</h6>
          {!user.loginLogs.length ? (
            <p className="text--normal mt-3">No Logs Found!</p>
          ) : null}
          <div className="login-history-parent">
            <div className="row login-history p-2">
              <table className="table login-logs-table">
                <thead>
                  <tr>
                    <th>Date & Time</th>
                    <th>Browser</th>
                    <th>Device</th>
                    <th>Location/IP</th>
                  </tr>
                </thead>
                <tbody>
                  {user.loginLogs.map((item, index) => (
                    <tr key={index}>
                      <td>{item.createdAt}</td>
                      <td>{item.browser}</td>
                      <td>{item.device}</td>
                      <td>{item.ip}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
