import React, { useEffect } from "react";
import {
  closePaymentMethod,
  closePaymentModal,
} from "../../../features/slices/ModalSlice";
import { useDispatch, useSelector } from "react-redux";
import DepositContent from "./DepositContent";
import IMPSPayment from "./IMPSPayment";
import UPIPayment from "./UPIPayment";
import { getPaymentMethods } from "../../../features/slices/PaymentSlice";
export default function Deposit() {
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.modal);

  const closeModal = () => {
    dispatch(closePaymentModal());
    dispatch(closePaymentMethod());
  };
  const closeDeposit = () => {
    dispatch(closePaymentMethod());
  };

  useEffect(() => {
    dispatch(getPaymentMethods());
  }, []);

  return (
    <div
      className="modal fade show deposit--modal"
      style={{
        display: "block",
        backdropFilter: "blur(15px)",
        background: "rgb(70, 70, 70, 0.6)",
      }}
      tabIndex="-1"
    >
      <div className="modal-dialog modal-xl modal-dialog-centered ">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title fs-5">
              {modal.nextStep && (
                <i
                  className="bi bi-arrow-left"
                  style={{
                    fontSize: "16px",
                    marginRight: "10px",
                  }}
                  onClick={closeDeposit}
                ></i>
              )}
              {modal.nextStep || "Deposit"}
            </h2>

            <i onClick={closeModal} className="bi bi-x-lg"></i>
          </div>
          <div className="modal-body">
            {modal.nextStep === "IMPS" && <IMPSPayment />}
            {modal.nextStep === "UPI" && <UPIPayment />}
            {!modal.nextStep && <DepositContent />}
          </div>
        </div>
      </div>
    </div>
  );
}
