import axios from "axios";
import Cookies from "js-cookie";
const apiURL = process.env.REACT_APP_API_BASE_URL;
const getToken = () => {
  const token = Cookies.get("token");
  const tempToken = Cookies.get("tempToken");
  if (token || tempToken) return decodeURIComponent(token || tempToken);
  return "";
};
const getRefreshToken = () => {
  const refreshToken = Cookies.get("refreshToken");
  if (refreshToken) return refreshToken;
  return "";
};
const api = axios.create({
  baseURL: apiURL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use((config) => {
  if (getToken()) config.headers["X-Access-Token"] = getToken();
  if (getRefreshToken()) config.headers["Refresh-Token"] = getRefreshToken();
  return config;
});
export default api;
