import { createSlice } from "@reduxjs/toolkit";

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    documentsSubmitted: false,
    addBankModal: false,
  },
  reducers: {
    submitDocument: (state) => {
      state.documentsSubmitted = true;
    },
    setDocumentsSubmittedFalse: (state) => {
      state.documentsSubmitted = false;
    },
    showAddBankModal: (state) => {
      state.addBankModal = true;
    },
    closeAddBankModal: (state) => {
      state.addBankModal = false;
    },
  },
});

export const {
  submitDocument,
  closePopup,
  setDocumentsSubmittedFalse,
  showAddBankModal,
  closeAddBankModal,
} = profileSlice.actions;

export default profileSlice.reducer;
