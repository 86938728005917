import React from "react";
import { closeMobileOtpModal } from "../../features/slices/ModalSlice";
import { useDispatch, useSelector } from "react-redux";
import MobileOtpForm from "./MobileOtpForm";
export default function MobileOtp(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const hideMobileOtpPopup = () => {
    dispatch(closeMobileOtpModal());
  };
  const closeModal = (event) => {
    if (event.target.id === "fadeModal") dispatch(closeMobileOtpModal());
  };

  return (
    <>
      <div
        className="modal fade otp--modal show"
        style={{
          display: "block",
          backdropFilter: "blur(15px)",
          background: "rgb(70, 70, 70, 0.6)",
        }}
        tabIndex="-1"
        id="fadeModal"
        onClick={closeModal}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title fs-5">Mobile Verfication</h2>

              <i
                data-bs-dismiss="modal"
                aria-label="Close"
                className="bi bi-x-lg"
                onClick={hideMobileOtpPopup}
              ></i>
            </div>
            <div className="modal-body mobile-otp-modal--body text-center">
              <MobileOtpForm />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
