import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import ohmSVG from "../../assets/images/ohm.svg";
import numerology from "../../assets/images/numerology-01.png";
import happyFace from "../../assets/images/happy-face.png";
import happy from "../../assets/images/happy.png";
import loss from "../../assets/images/loss.png";
import neutral from "../../assets/images/neutral.png";
import aquariusImg from "../../assets/images/signs-images/Aquarius.svg";
import ariesImg from "../../assets/images/signs-images/Aries.svg";
import cancerImg from "../../assets/images/signs-images/Cancer.svg";
import capriconImg from "../../assets/images/signs-images/Capricon.svg";
import geminiImg from "../../assets/images/signs-images/Gemini.svg";
import leoImg from "../../assets/images/signs-images/Leo.svg";
import libraImg from "../../assets/images/signs-images/Libra.svg";
import piscesImg from "../../assets/images/signs-images/Pisces.svg";
import sagitariusImg from "../../assets/images/signs-images/Sagitttatarius.svg";
import scorpioImg from "../../assets/images/signs-images/Scorpio.svg";
import taurusImg from "../../assets/images/signs-images/Taurus.svg";
import virgoImg from "../../assets/images/signs-images/Virgo.svg";
import TimeFrameDropdown from "../common/TimeFrameDropdown";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { getAstroData } from "../../features/slices/UserSlice";
import moment from "moment";
export default function Astrology() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [horoScopeParsedData, setHoroScopeData] = useState({});
  const [numeroData, setNumeroData] = useState({});
  const [panchangData, setPanchangData] = useState({});
  const [panchangFinalData, setPanchangFinalData] = useState([]);
  const [selectedHoroData, setSelectedHoroData] = useState({});
  const [selectedNumerologyData, setSelectedNumerologyData] = useState("");
  const [horoScopeOptions, setHoroScopeOptions] = useState([]);
  const [numeroOptions, setNumeroOptions] = useState([]);
  const listItems = [
    {
      id: "1",
      name: "PERSONAL",
      detail:
        "If you have a speaking engagement on your schedule, approach it with confidence. The energy of the 11-Day will work in your favor, making it easier for you to express your thoughts clearly and captivate your audience's attention. Your charisma is likely to shine, making you quite magnetic to others. Embrace the day with a positive outlook and enjoy all that it has to offer, One.",
    },
    {
      id: "2",
      name: "HEALTH",
      detail: "Health",
    },
    {
      id: "3",
      name: "PROFESSION",
      detail: "Profession",
    },
    {
      id: "4",
      name: "EMOTIONS",
      detail: "Emotions",
    },
    {
      id: "5",
      name: "TRAVEL",
      detail: "Travel",
    },
    {
      id: "6",
      name: "LUCK",
      detail: "Luck",
    },
  ];
  const panchangItems = [
    {
      imageSrc: happyFace,
      state: "Best",
      category: "Amrita",
      time: "06:20 AM - 07:52 AM",
      timeName: "Auspicious Time",
      color: "#25C761",
      backColor: "#25c76062",
    },
    {
      imageSrc: loss,
      state: "Loss",
      category: "Kala",
      time: "07:52 AM - 09:18 AM",
      timeName: "Kaal Vela",
      color: "#FE2626",
      backColor: "#fe262659",
    },
    {
      imageSrc: happy,
      state: "Good",
      category: "Shubha",
      time: "09:18 AM - 10:52 AM",
      timeName: "Auspicious Time",
      color: "#25C761",
      backColor: "#25c76062",
    },
    {
      imageSrc: loss,
      state: "Evil",
      category: "Rog",
      time: "07:52 AM - 09:18 AM",
      timeName: "Kaal Vela",
      color: "#FE2626",
      backColor: "#fe262659",
    },
    {
      imageSrc: loss,
      state: "Bad",
      category: "Udvega",
      time: "07:52 AM - 09:18 AM",
      timeName: "Kaal Vela",
      color: "#FE2626",
      backColor: "#fe262659",
    },
    {
      imageSrc: neutral,
      state: "Neutral",
      category: "Chara",
      time: "09:18 AM - 10:52 AM",
      timeName: "Auspicious Time",
      color: "#2990F8",
      backColor: "#2991f856",
    },
    {
      imageSrc: happy,
      state: "Gain",
      category: "Labh",
      time: "09:18 AM - 10:52 AM",
      timeName: "Auspicious Time",
      color: "#25C761",
      backColor: "#25c76062",
    },
    {
      imageSrc: happyFace,
      state: "Best",
      category: "Amrita",
      time: "09:18 AM - 10:52 AM",
      timeName: "Auspicious Time",
      color: "#25C761",
      backColor: "#25c76062",
    },
  ];

  useEffect(() => {
    dispatch(getAstroData());
  }, []);

  useEffect(() => {
    if (user?.astroData?.horoScopeData) {
      const horoData = user?.astroData?.horoScopeData;
      const numeroData = user?.astroData?.numberologyData;
      const dailyPanchangData = JSON.parse(
        user?.astroData?.choghadiyaData?.data
      );
      setPanchangData(dailyPanchangData);
      const modifiedPanchangData = getPanchangArray(dailyPanchangData);
      setPanchangFinalData(modifiedPanchangData);
      setNumeroData(numeroData);
      setSelectedNumerologyData(numeroData?.daily || "");
      console.log("astro data panchang", modifiedPanchangData);
      setHoroScopeOptions(Object.keys(horoData));
      setNumeroOptions(Object.keys(numeroData));
      let data = {};
      for (const key in horoData) {
        data[key] = JSON.parse(horoData[key]);
      }
      setHoroScopeData(data);
      console.log("parsed data", data);
      const currentHoroScopeData = {
        personal: data.date.prediction.personal,
        luck: data.date.prediction.luck,
      };
      setSelectedHoroData(currentHoroScopeData);
    }
  }, [user.astroData]);

  const getFormattedDate = () => {
    return moment(user.userData.dob).format("DD-MM-YYYY");
  };
  const getSign = () => {
    if (horoScopeOptions.length && user.astroData.horoScopeData) {
      const sign = JSON.parse(
        user.astroData.horoScopeData[horoScopeOptions[0]]
      ).sign;
      return capitalizeFirstLetter(sign);
    }
    return "";
  };

  const getLuckyNumber = () => {
    console.log(
      "lucky number",
      moment(user.userData.dob)
        .format("DD")
        .split("")
        .reduce((a, c) => +a + +c)
    );
    return moment(user.userData.dob)
      .format("DD")
      .split("")
      .reduce((a, c) => +a + +c);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getSelectedItem = (val) => {
    if (val === "date") {
      const currentHoroScopeData = {
        personal: horoScopeParsedData.date.prediction.personal,
        luck: horoScopeParsedData.date.prediction.luck,
      };
      setSelectedHoroData(currentHoroScopeData);
    } else if (val === "week") {
      const currentHoroScopeData = {
        personal: horoScopeParsedData.week.weekly_horoscope.personal,
        luck: horoScopeParsedData.week.weekly_horoscope.luck,
      };
      setSelectedHoroData(currentHoroScopeData);
    } else if (val === "year") {
      const currentHoroScopeData = {
        personal: horoScopeParsedData.year.yearly_horoscope.personal,
        luck: horoScopeParsedData.year.yearly_horoscope.luck,
      };
      setSelectedHoroData(currentHoroScopeData);
    }
  };

  const getSelectedNumeroOption = (val) => {
    setSelectedNumerologyData(numeroData[val]);
  };

  const getPanchangArray = (data) => {
    let dataToArray = [];
    for (const key in data) {
      dataToArray.push(data[key]);
    }
    dataToArray = dataToArray.map((item) => {
      const obj = {};
      obj.category = item.name;
      obj.time = `${moment(item.starts_at).format("HH:MM A")} - ${moment(
        item.ends_at
      ).format("HH:MM A")}`;
      obj.color = getColor(item.name).color;
      obj.backColor = getColor(item.name).backColor;
      obj.state = getState(item.name);
      obj.imageSrc = getImageSrc(item.name);
      return obj;
    });
    return dataToArray;
  };
  const getColor = (name) => {
    let item = {};
    switch (name) {
      case "Amrit":
      case "Shubh":
      case "Labh":
        item.color = "#25C761";
        item.backColor = "#25c76062";
        break;
      case "Kaal":
      case "Rog":
      case "Udveg":
        item.color = "#FE2626";
        item.backColor = "#fe262659";
        break;
      case "Char":
        item.color = "#2990F8";
        item.backColor = "#2991f856";
        break;
      default:
        break;
    }
    return item;
  };

  const getImageSrc = (name) => {
    let image;
    switch (name) {
      case "Amrit":
        image = happyFace;
        break;
      case "Kaal":
      case "Rog":
      case "Udveg":
        image = loss;
        break;
      case "Char":
        image = neutral;
        break;
      case "Labh":
      case "Shubh":
        image = happy;
        break;
      default:
        break;
    }
    return image;
  };

  const getState = (name) => {
    let state;
    switch (name) {
      case "Amrit":
        state = "Best";
        break;
      case "Kaal":
        state = "Loss";
        break;
      case "Shubh":
        state = "Good";
        break;
      case "Rog":
        state = "Evil";
        break;
      case "Udveg":
        state = "Bad";
        break;
      case "Char":
        state = "Neutral";
        break;
      case "Labh":
        state = "Gain";
        break;
      default:
        break;
    }
    return state;
  };

  const getSignImage = (sign) => {
    let image = null;
    switch (sign) {
      case "Aquarius":
        image = aquariusImg;
        break;
      case "Aries":
        image = ariesImg;
        break;
      case "Cancer":
        image = cancerImg;
        break;
      case "Capricon":
        image = capriconImg;
        break;
      case "Gemini":
        image = geminiImg;
        break;
      case "Leo":
        image = leoImg;
        break;
      case "Libra":
        image = libraImg;
        break;
      case "Pisces":
        image = piscesImg;
        break;
      case "Sagittarius":
        image = sagitariusImg;
        break;
      case "Scorpio":
        image = scorpioImg;
        break;
      case "Taurus":
        image = taurusImg;
        break;
      case "Virgo":
        image = virgoImg;
        break;
      default:
        break;
    }
    return image;
  };

  return (
    <div className="astro-main">
      <div className="container pt-5 pb-5">
        <div className="d-flex justify-content-between">
          <h5 className="mb-0 align-center">Horoscope</h5>
          <Button
            className="exit-btn"
            onClick={() => {
              window.location.pathname = "/";
            }}
          >
            <i class="bi bi-x me-1"></i>
            Exit
          </Button>
        </div>
        <div className="row gx-5 top-cards justify-content-around my-5">
          <div className="col-md-3 astro-user-detail-card align-center p-4">
            <div>
              <div>
                <p className="mb-0 text--xl">
                  <b>{getFormattedDate()}</b>
                </p>
                <p>
                  <small className="low-opacity">Date of Birth</small>
                </p>
              </div>
              {/* <hr />
              <div>
                <p className="mb-0 text--xl">
                  <b>10:25 PM</b>
                </p>
                <p>
                  <small className="low-opacity">Time of Birth</small>
                </p>
              </div> */}
            </div>
          </div>
          <div className="col-md-3 astro-user-detail-card align-center p-3">
            <div className="d-flex">
              <img
                width={100}
                className="object-fit-contain me-3"
                src={getSignImage(getSign())}
              />
              <div className="align-center">
                <div>
                  <p className="mb-0 text--xl">
                    <b>{horoScopeOptions.length ? getSign() : ""} </b>
                  </p>
                  <small className="low-opacity">Zodiac Sign</small>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 astro-user-detail-card align-center p-3">
            <div className="text-center">
              <h1 className="text--2xl">{getLuckyNumber()}</h1>
              <p className="text--xl low-opacity">Zodiac Sign</p>
            </div>
          </div>
        </div>
        <div className="horo-section w-full p-5 mb-5">
          <div className="d-flex justify-content-between">
            <h3>Today Horoscope</h3>
            {horoScopeOptions && (
              <TimeFrameDropdown
                options={horoScopeOptions}
                getSelectedItem={getSelectedItem}
              />
            )}
          </div>
          <div className="horo-child-section d-flex mt-5">
            <div className="horo-tabs">
              <ul className="horo-list pe-5">
                <div>
                  <p className="mb-2 fs-5">
                    <b>PERSONAL</b>
                  </p>
                  <p className="fw-light">{selectedHoroData.personal}</p>
                </div>
                <div>
                  <p className="mb-2 fs-5">
                    <b>LUCK</b>
                  </p>
                  {Array.isArray(selectedHoroData.luck) &&
                  selectedHoroData.luck.length ? (
                    <ul>
                      {selectedHoroData.luck.map((item, index) => {
                        return <li>{item}</li>;
                      })}
                    </ul>
                  ) : (
                    selectedHoroData.luck
                  )}
                </div>
              </ul>
            </div>
          </div>
        </div>
        <div className="horo-section hero-section-2 w-full p-5 mb-5">
          <div className="numerology-scroll-section">
            <div className="d-flex justify-content-between">
              <img
                width={100}
                className="object-fit-contain mb-2"
                src={numerology}
              />
              <TimeFrameDropdown
                options={numeroOptions}
                getSelectedItem={getSelectedNumeroOption}
              />
            </div>
            <div className="d-flex justify-content-between">
              <div>
                <h2 className="text--2xl">Numerology {getLuckyNumber()}</h2>
                <p className="mt-4">{moment().format("LL")}</p>
                <p className="low-opacity text--xl font-light w-75">
                  {selectedNumerologyData}
                </p>
              </div>
              <div className="numerology-num-p">
                <div className="numerology-number">
                  <div>
                    <h1>{getLuckyNumber()}</h1>
                    <p>Lucky Number</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="horo-section hero-section-3 w-fullmb-5">
          <div className="panchang-overlay">
            <div className="top-content d-flex">
              <div>
                <img
                  width={200}
                  className="object-fit-contain mb-2 p-3"
                  src={ohmSVG}
                />
              </div>
              <div style={{ display: "grid", placeItems: "center" }}>
                <div>
                  <h2 className="text--2xl">
                    <b>Daily panchang</b>
                  </h2>
                </div>
                {/* <button className="standard-btn">View More</button> */}
              </div>
            </div>
            <div className="row gx-5 justify-content-center mt-5">
              <div className="col-md-6">
                <div className="panchang-items">
                  <div>
                    <div className="d-flex justify-content-between">
                      <p>Day Choghadiya</p>
                      <p>6:30 AM</p>
                    </div>
                    {panchangFinalData.slice(0, 8).map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="panchang-item p-3 d-flex justify-content-between mb-2"
                          style={{
                            border: `1px solid ${item.color}`,
                            backgroundColor: `${item.backColor}`,
                          }}
                        >
                          <div className="d-flex">
                            <img
                              className="object-fit-contain me-2"
                              src={item.imageSrc}
                            />
                            <div>
                              <p style={{ fontSize: "10px" }} className="mb-0">
                                {item.category}
                              </p>
                              <p
                                className="mb-0"
                                style={{ color: `${item.color}` }}
                              >
                                <b>{item.state}</b>
                              </p>
                            </div>
                          </div>
                          <div>
                            <p className="mb-0">{item.time}</p>
                            {/* <p
                              className="mb-0"
                              style={{ fontSize: "10px", opacity: "0.5" }}
                            >
                              {item.timeName}
                            </p> */}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="panchang-items">
                  <div>
                    <div className="d-flex justify-content-between">
                      <p>Day Choghadiya</p>
                      <p>6:30 AM</p>
                    </div>
                    {panchangFinalData
                      .slice(8, panchangFinalData.length)
                      .map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="panchang-item p-3 d-flex justify-content-between mb-2"
                            style={{
                              border: `1px solid ${item.color}`,
                              backgroundColor: `${item.backColor}`,
                            }}
                          >
                            <div className="d-flex">
                              <img
                                className="object-fit-contain me-2"
                                src={item.imageSrc}
                              />
                              <div>
                                <p
                                  style={{ fontSize: "10px" }}
                                  className="mb-0"
                                >
                                  {item.category}
                                </p>
                                <p
                                  className="mb-0"
                                  style={{ color: `${item.color}` }}
                                >
                                  <b>{item.state}</b>
                                </p>
                              </div>
                            </div>
                            <div>
                              <p className="mb-0">{item.time}</p>
                              {/* <p
                              className="mb-0"
                              style={{ fontSize: "10px", opacity: "0.5" }}
                            >
                              {item.timeName}
                            </p> */}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
