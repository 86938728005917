import { createSlice } from "@reduxjs/toolkit";

const categoriesSlice = createSlice({
  name: "categories",
  initialState: {
    homeCategories: ["Last Played Games", "Your Favourite"],
    allGamesCategories: ["Blackjack", "Roulette", "Andar Bahar"],
  },
});

export default categoriesSlice.reducer;
