import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { updateUser } from "../../features/slices/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import {
  getStateList,
  getCityList,
  emptyLists,
} from "../../features/slices/CommonSlice";
export default function UserProfile() {
  const user = useSelector((state) => state.user);
  const common = useSelector((state) => state.common);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [country, setCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [city, setCity] = useState(null);
  const [userInfo, setUserInfo] = useState({});
  const dispatch = useDispatch();
  const updateUserInfo = (event) => {
    const form = document.querySelector(".needs-validation");
    console.log(userInfo);
    if (form.checkValidity()) {
      event.preventDefault();
      dispatch(updateUser(userInfo));
    }
  };
  const selectCountry = (value) => {
    setCountry(value);
    dispatch(emptyLists());
    selectCity([]);
    selectState([]);
    if (value.length) {
      setUserInfo((userdetail) => ({ ...userdetail, country: value[0].id }));
      dispatch(getStateList(value[0].id));
    }
  };
  const selectState = (value) => {
    setSelectedState(value);
    selectCity([]);
    if (value.length) {
      setUserInfo((userdetail) => ({ ...userdetail, state: value[0].id }));
      if (country) {
        dispatch(
          getCityList({ countryId: country[0].id, stateId: value[0].id })
        );
      }
    }
  };
  const selectCity = (value) => {
    setCity(value);
    if (value.length) {
      setUserInfo((userdetail) => ({ ...userdetail, city: value[0].id }));
    }
  };
  useEffect(() => {
    setUserInfo(user.userData);
  }, [user]);
  useEffect(() => {
    setUserInfo(user.userData);
  }, []);

  useEffect(() => {
    let selectedCountry;
    if (common.countries && common.countries.length)
      selectedCountry = common.countries.filter(
        (item) => item.id === +user.userData.country
      );
    selectCountry(selectedCountry || []);
  }, [common.countries]);
  useEffect(() => {
    let selectedState;
    if (common.states && common.states.length)
      selectedState =
        common.states.filter((item) => item.id === +user.userData.state) || [];
    console.log(selectedState);
    selectState(selectedState || []);
    if (!selectedState) selectCity([]);
  }, [common.states]);
  useEffect(() => {
    let selectedCity;
    if (common.cities && common.cities.length)
      selectedCity = common.cities.filter(
        (item) => item.id === +user.userData.city
      );
    selectCity(selectedCity || []);
  }, [common.cities]);
  return (
    <div className="mt-2 user-profile p-2">
      <form className="row g-3 mt-3 needs-validation user-info-form">
        <div className="d-flex">
          <h6 className="w-100">Profile</h6>
          {!isMobile && (
            <div className="d-flex float-end" style={{ marginTop: "-10px" }}>
              <button className="save" type="submit" onClick={updateUserInfo}>
                Save
              </button>
            </div>
          )}
        </div>
        <div className="col-md-6 col-xl-6 col-lg-6 col-12">
          <label
            for="validationCustom01"
            className="form-label text--medium text--light"
          >
            Username
          </label>
          <input
            type="text"
            className="form-control bg-black border border-light border-opacity-50"
            id="validationCustom01"
            placeholder="Username"
            defaultValue={user.userData.username}
            onChange={(event) =>
              setUserInfo((userdetail) => ({
                ...userdetail,
                username: event.target.value,
              }))
            }
            readOnly={user.userData.username ? true : false}
            required
            autoComplete="off"
          />
        </div>
        <div className="col-md-3 col-xl-3 col-lg-3 col-6">
          <label
            for="validationCustom02"
            className="form-label text--medium text--light"
          >
            First Name
          </label>
          <input
            type="text"
            className="form-control bg-black border border-light border-opacity-50"
            id="validationCustom02"
            placeholder="First Name"
            required
            defaultValue={user.userData.firstName}
            onChange={(event) =>
              setUserInfo((userdetail) => ({
                ...userdetail,
                firstName: event.target.value,
              }))
            }
            readOnly={user.userData.firstName ? true : false}
            autoComplete="off"
          />
        </div>
        <div className="col-md-3 col-xl-3 col-lg-3 col-6">
          <label
            for="validationCustomUsername"
            className="form-label text--medium text--light"
          >
            Last name
          </label>
          <input
            type="text"
            className="form-control bg-black border border-light border-opacity-50"
            id="validationCustomUsername"
            placeholder="Last Name"
            defaultValue={user.userData.lastName}
            onChange={(event) =>
              setUserInfo((userdetail) => ({
                ...userdetail,
                lastName: event.target.value,
              }))
            }
            readOnly={user.userData.lastName ? true : false}
            required
            autoComplete="off"
          />
        </div>
        {!isMobile && <div className="mt-4"></div>}

        <div className="col-md-6 col-xl-6 col-lg-6 col-6">
          <label className="form-label text--medium text--light">
            Birthdate
          </label>
          <input
            type="date"
            id="dob"
            className="form-control bg-black border border-light border-opacity-50"
            name="dob"
            placeholder="01/01/2000"
            defaultValue={user.userData.dob}
            onChange={(event) =>
              setUserInfo((userdetail) => ({
                ...userdetail,
                dob: event.target.value,
              }))
            }
            required
            autoComplete="off"
          />
        </div>
        {/* <div className="col-md-3 col-xl-3 col-lg-3 col-6">
          <label className="form-label text--medium text--light">
            Birth Time
          </label>
          <input
            type="text"
            id="dobTime"
            className="form-control bg-black border border-light border-opacity-50"
            name="dobTime"
            placeholder="12:00 AM"
            defaultValue={user.userData.dobTime}
            onChange={(event) =>
              setUserInfo((userdetail) => ({
                ...userdetail,
                dobTime: event.target.value,
              }))
            }
            required
            readOnly={user.userData.dobTime ? true : false}
            autoComplete="off"
          />
        </div> */}
        <div className="col-md-6 col-xl-6 col-lg-6 col-12">
          <label
            for="validationCustom02"
            className="form-label text--medium text--light"
          >
            Country
          </label>
          <Typeahead
            id="basic-typeahead-country"
            labelKey="name"
            onChange={selectCountry}
            options={common.countries || []}
            placeholder="Choose a country..."
            selected={country}
            clearButton
            required
            inputProps={{
              autoComplete: "off",
            }}
          />
        </div>
        {!isMobile && <div className="mt-4"></div>}
        <div className="col-md-6 col-xl-6 col-lg-6 col-12">
          <label
            for="validationCustomUsername"
            className="form-label text--medium text--light"
          >
            State
          </label>
          <Typeahead
            id="basic-typeahead-state"
            labelKey="name"
            onChange={selectState}
            options={common.states || []}
            placeholder="Choose a state..."
            selected={selectedState}
            inputProps={{
              autoComplete: "off",
            }}
          />
        </div>
        <div className="col-md-6 col-xl-6 col-lg-6 col-12">
          <label
            for="validationCustomUsername"
            className="form-label text--medium text--light"
          >
            City
          </label>
          <Typeahead
            id="basic-typeahead-city"
            labelKey="name"
            onChange={selectCity}
            options={common.cities || []}
            placeholder="Choose a city..."
            selected={city}
            inputProps={{
              autoComplete: "off",
            }}
          />
        </div>
        {!isMobile && <div className="mt-4"></div>}
        <div className="col-md-9 col-xl-9 col-lg-9 col-12">
          <label
            for="validationCustom01"
            className="form-label text--medium text--light"
          >
            Address
          </label>
          <input
            type="text"
            className="form-control bg-black border border-light border-opacity-50"
            id="validationCustom01"
            placeholder="Address"
            required
            defaultValue={user.userData.address}
            onChange={(event) =>
              setUserInfo((userdetail) => ({
                ...userdetail,
                address: event.target.value,
              }))
            }
            autoComplete="off"
          />
        </div>
        <div className="col-md-3 col-xl-3 col-lg-3 col-12">
          <label
            for="validationCustom02"
            className="form-label text--medium text--light"
          >
            Pin Code
          </label>
          <input
            type="text"
            className="form-control bg-black border border-light border-opacity-50"
            id="validationCustom02"
            placeholder="1234 242"
            defaultValue={user.userData.zip}
            onChange={(event) =>
              setUserInfo((userdetail) => ({
                ...userdetail,
                zip: event.target.value,
              }))
            }
            required
            autoComplete="off"
          />
        </div>
        {isMobile && (
          <div>
            <button className="save w-50 float-end" onClick={updateUserInfo}>
              Save
            </button>
          </div>
        )}
      </form>
    </div>
  );
}
