import React, { useState, useEffect, useRef } from "react";
import { Header } from "./components/common/header";
import { Footer } from "./components/common/footer";
import { Home } from "./components/home/home";
import SideBarMenu from "./components/common/SideBarMenu";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Terms from "./components/Terms";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Profile from "./components/profile/Profile";
import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import MobileOtp from "./components/common/MobileOtp";
import Signup from "./components/common/Signup";
import Login from "./components/common/Login";
import CompletionPopup from "./components/common/CompletionPopup";
import ForgotPasswordModal from "./components/common/ForgotPasswordModal";
import Deposit from "./components/transactions/deposit/Deposit";
import Withdraw from "./components/transactions/withdraw/Withdraw";
import PaymentCompletion from "./components/common/PaymentCompletion";
import TableGames from "./components/games/TableGames";
import Game from "./components/games/Game";
import FiltersMenu from "./components/games/FiltersMenu";
import Headlines from "./components/common/Headlines";
import LoginForm from "./components/common/LoginForm";
import { useMediaQuery } from "react-responsive";
import SignupForm from "./components/common/SignupForm";
import {
  updateSelectedItem,
  getCountryList,
  hideChatBox,
  showChatBoxInDesktop,
  hideChatBoxDesktop,
} from "./features/slices/CommonSlice";
import MobileOtpForm from "./components/common/MobileOtpForm";
import ProfilePhone from "./components/mobile/Profile";
import MobileDeposit from "./components/mobile/transactions/Deposit";
import Completed from "./components/common/Completed";
import MobileWithdraw from "./components/mobile/transactions/Withdraw";
import ForgotPasswordActions from "./components/common/ForgotPasswordActions";
import Loader from "./components/common/Loader";
import Thanks from "./components/common/Thanks";
import ProtectedRoute from "./app/ProtectedRoute";
import {
  getUserData,
  checkUserLogin,
  relogin,
  logoutUser,
  loginUser,
  showKeepWorkingModal,
  hideKeepWorkingModal,
} from "./features/slices/UserSlice";
import { fetchCategories, fetchProviders } from "./features/slices/GamesSlice";
import PlayGame from "./components/games/PlayGame";
import AllGames from "./components/games/AllGames";
import {
  closeLoginModal,
  closePaymentModal,
} from "./features/slices/ModalSlice";
import { getPaymentMethods } from "./features/slices/PaymentSlice";
import { getUserInfo } from "./app/utils";
import NewBankAccount from "./components/profile/NewBankAccount";
import MobileFooterMain from "./components/mobile/MobileFooterMain";
import Cookies from "js-cookie";
import ChatBox from "./components/common/ChatBox";
import newChatIcon from "./assets/images/newChatIcon.png";
import chatBoxIcon from "./assets/images/supportIcon_box.png";
import AlreadyLoggedInMessagePopup from "./components/common/AlreadyLoggedInMessagePopup";
import KeepWorkingPopup from "./components/common/KeepWorkingPopup";
import PaymentStatus from "./components/common/PaymentStatus";
import Astrology from "./components/astrology/Astrology";
import AstroError from "./components/astrology/AstroError";
import PlayAviatrix from "./components/games/PlayAviatrix";
function App() {
  const dispatch = useDispatch();
  const playingGame =
    window.location.pathname.includes("play-game") ||
    window.location.pathname.includes("play-aviatrix");
  const modal = useSelector((state) => state.modal);
  const profile = useSelector((state) => state.profile);
  const user = useSelector((state) => state.user);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const common = useSelector((state) => state.common);
  const payment = useSelector((state) => state.payment);
  const game = useSelector((state) => state.game);
  const isLoading = useSelector((state) => state.loading.isLoading);
  const [userInfo, setUserInfo] = useState(null);
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [initialAuth, hideInitialAuth] = useState(true);
  const [openChatBox, setOpenChatBox] = useState(false);
  const [inActive, setInActive] = useState(true);
  const [noActivity, setNoActivity] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);
  // const [alreadyPlaying, setShowAlreadyPlaying] = useState(false);
  const token = Cookies.get("token");

  const idleTimeoutRef = useRef(null);
  const timer = useRef(null);
  const timer2 = useRef(null);

  const handleMouseMovement = () => {
    setInActive(false);
    clearTimeout(idleTimeoutRef.current);
    clearInterval(idleTimeoutRef.current);
    // Set a new timeout to check for idle after 1 hour (3600000 milliseconds)
    idleTimeoutRef.current = setTimeout(logUserInactive, 300 * 1000);
  };

  const logUserInactive = () => {
    console.log("User has been inactive for 1 minute.");
    setNoActivity(true);
  };

  useEffect(() => {
    dispatch(updateSelectedItem(null));
    dispatch(checkUserLogin());
    dispatch(fetchCategories());
    dispatch(fetchProviders());
    getUserInfo()
      .then((data) => {
        setUserInfo(data);
        console.log("info", data);
      })
      .catch((error) => console.error("Error retrieving user info:", error));

    window.onbeforeunload = () => {
      localStorage.removeItem("initialLoad");
    };
    if (!window.location.pathname.includes("profile")) {
      localStorage.removeItem("profileScreen");
      localStorage.removeItem("mobileProfileScreen");
    }
  }, []);

  useEffect(() => {
    if (inActive && isMobile) {
      clearInterval(idleTimeoutRef.current);
      idleTimeoutRef.current = setInterval(logUserInactive, 10000);
    }
  }, [inActive]);
  useEffect(() => {
    const isJwtInvalid =
      user.error === "JWT Token Invalid." ||
      payment.error === "JWT Token Invalid." ||
      game.error === "JWT Token Invalid.";
    if (isJwtInvalid) {
      dispatch(relogin());
      Cookies.remove("refreshToken");
      Cookies.remove("tokenExpires");
      localStorage.clear();
      window.location.reload();
    }
  }, [user.error, payment.error]);

  useEffect(() => {
    if (user.userLoggedIn) {
      sessionStorage.setItem("userIsActive", true);
      localStorage.setItem("initialLoad", true);
      document.addEventListener("mousemove", handleMouseMovement);
      document.addEventListener("touchstart", handleMouseMovement);

      dispatch(
        getUserData({
          source: window.location.href.includes("/play-game")
            ? "play_game"
            : "games_page",
          showLoader: true,
        })
      );
      // dispatch(getCountryList());
      // dispatch(getPaymentMethods());
      setTimeout(() => {
        dispatch(closeLoginModal());
      }, 500);
      if (localStorage.getItem("game")) {
        const game = JSON.parse(localStorage.getItem("game"));
        window.open(`/play-game/${game.opentableId}`, "_blank");
      }
      localStorage.removeItem("game");
    }
  }, [user.userLoggedIn]);

  const toggleChatBox = () => {
    if (common.chatBoxDesktop) {
      dispatch(hideChatBoxDesktop());
    } else {
      dispatch(showChatBoxInDesktop());
    }
  };

  useEffect(() => {
    if (user.alreadyLoggedData.alreadyLoggedIn) {
      dispatch(closeLoginModal());
    }
  }, [user.alreadyLoggedData]);
  useEffect(() => {
    if (user.loggedOut) {
      Cookies.remove("token");
      Cookies.remove("refreshToken");
      window.location.reload();
    }
  }, [user.loggedOut]);
  useEffect(() => {
    if (Cookies.get("refreshToken") && Cookies.get("token")) {
      console.log("token--refreshed");
      setTokenExpired(false);
      clearTimeout(timer.current);
      clearTimeout(timer2.current);
      const logout = () => {
        dispatch(logoutUser());
        setTokenExpired(false);
        localStorage.clear();
        sessionStorage.clear();
      };
      const checkTokenExpiration = () => {
        const expirationDate = Cookies.get("tokenExpires");
        if (expirationDate) {
          const currentTimestamp = new Date().getTime();
          const expirationTimestamp =
            new Date(expirationDate).getTime() - (5 * 60 * 1000 + 3000);
          // console.log("no completed");
          if (currentTimestamp > expirationTimestamp) {
            setTokenExpired(true);
            timer2.current = setTimeout(logout, 5 * 60 * 1000);
            clearTimeout(timer.current);
          }
        }
      };
      // const checkTokenExpirationPeriodically = () => {
      timer.current = setInterval(checkTokenExpiration, 1000);
      // };

      // Call checkTokenExpirationPeriodically on component load or at an appropriate place
      // checkTokenExpirationPeriodically();
    }
  }, [Cookies.get("refreshToken")]);

  const closeKeepWorkingModal = () => {
    setTokenExpired(false);
  };

  useEffect(() => {
    if (
      user.alreadyLoggedData.alreadyLoggedIn &&
      user.showAlreadyLoginMessage
    ) {
      const refreshToken = Cookies.get("refreshToken");
      const payload = {
        grantType: "refreshToken",
        refreshToken: refreshToken || "",
      };
      dispatch(loginUser(payload));
    }
  }, [user.alreadyLoggedData.alreadyLoggedIn, user.showAlreadyLoginMessage]);

  return (
    <Router>
      {/* {user.alreadyLoggedData.alreadyLoggedIn &&
        user.showAlreadyLoginMessage && <AlreadyLoggedInMessagePopup />} */}

      {tokenExpired && <KeepWorkingPopup closeModal={closeKeepWorkingModal} />}
      {common.chatBoxDesktop && !isMobile && <ChatBox />}
      {!isMobile && (
        <div className="chatbox--icon">
          <img
            loading="lazy"
            src={chatBoxIcon}
            width={70}
            onClick={toggleChatBox}
            alt="kubera"
          />
        </div>
      )}
      {isLoading && <Loader />}
      {profile.addBankModal && <NewBankAccount />}
      {/* {modal.openCompletionModal && <CompletionPopup />} */}
      {modal.thanksPopupVisible && <Thanks />}
      {!isMobile && (
        <div className="desktop-content">
          {modal.isMobileOtpModalVisible && <MobileOtp />}
          {modal.isSingupModalVisible && <Signup />}
          {modal.isLoginModalVisible && <Login />}
          {modal.showForgotPasswordModal && <ForgotPasswordModal />}
          {modal.paymentModal === "Deposit" && <Deposit />}
          {modal.paymentModal === "Withdraw" && <Withdraw />}
          {modal.paymentType && <PaymentCompletion />}
        </div>
      )}
      <SideBarMenu />
      <Header />
      {common.selectedItem === "chat-bot" && isMobile && <ChatBox />}
      {common.selectedItem === null && !common.chatBox && (
        <>
          <Headlines />
          <Routes>
            <Route exact path="/" element={<Home />}></Route>\
            <Route exact path="/terms" element={<Terms />}></Route>
            <Route
              exact
              path="/privacy-policy"
              element={<PrivacyPolicy />}
            ></Route>
            <Route exact path="/all-games" element={<AllGames />}></Route>
            {/* <Route exact path="/astrology" element={<Astrology />}></Route> */}
            <Route exact path="/player-status" element={<AstroError />}></Route>
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  {isMobile ? <ProfilePhone /> : <Profile />}
                </ProtectedRoute>
              }
            />
            <Route
              path="/play-game/:id"
              element={
                <ProtectedRoute>
                  <PlayGame />
                </ProtectedRoute>
              }
            />
            <Route
              path="/astrology"
              element={
                <ProtectedRoute>
                  <Astrology />
                </ProtectedRoute>
              }
            />
            <Route
              path="/play-aviatrix"
              element={
                <ProtectedRoute>
                  <PlayAviatrix />
                </ProtectedRoute>
              }
            />
            <Route
              path="/payment-status"
              element={
                <ProtectedRoute>
                  <PaymentStatus />
                </ProtectedRoute>
              }
            />
            <Route exact path="/:id" element={<TableGames />}></Route>
            <Route exact path="/:id/:id" element={<Game />}></Route>
          </Routes>
        </>
      )}
      <div className="mobile-content">
        {common.selectedItem === "filter" && (
          <div className="mobile-filters">
            <FiltersMenu />
          </div>
        )}
        {common.selectedItem === "login" && <LoginForm />}
        {common.selectedItem === "signup" && <SignupForm />}
        {common.selectedItem === "mobileOTP" && <MobileOtpForm />}

        {common.selectedItem === "deposit" && (
          <div>
            <Headlines />
            <MobileDeposit />
          </div>
        )}
        {common.selectedItem === "withdraw" && (
          <div>
            <Headlines />
            <MobileWithdraw />
          </div>
        )}
        {(common.selectedItem === "depositCompleted" ||
          common.selectedItem === "withdrawCompleted") && (
          <div>
            <Headlines />
            <Completed />
          </div>
        )}
        {common.selectedItem === "forgotPassword" && (
          <div className="mobile--fp p-3">
            <ForgotPasswordActions />
          </div>
        )}
      </div>
      {isMobile && !playingGame ? <MobileFooterMain /> : null}
      {!playingGame && <Footer />}
    </Router>
  );
}

export default App;
