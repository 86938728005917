import React, { useState, useEffect } from "react";
import { Carousel } from "../home/slider";
import seeMoreImage from "../../assets/images/see-more.svg";
import filterButton from "../../assets/images/filters.svg";
import SearchField from "../common/SearchField";
import FiltersMenu from "./FiltersMenu";
import { showFilterMenu } from "../../features/slices/FilterSlice";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import {
  fetchGames,
  fetchSubCategories,
} from "../../features/slices/GamesSlice";
import { makeFavourite, hideToast } from "../../features/slices/UserSlice";
import { openLoginModal } from "../../features/slices/ModalSlice";
export default function TableGames() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const game = useSelector((state) => state.game);
  const user = useSelector((state) => state.user);
  const common = useSelector((state) => state.common);
  const [isCategoriesMenuOpen, setIsCategoriesMenuOpen] = useState(false);
  const [isProvidersMenuOpen, setIsProvidersMenuOpen] = useState(false);
  const filter = useSelector((state) => state.filter);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [category, setCategory] = useState({});
  const [rerender, setrerender] = useState(0);
  const [filterCheck, setFilterCheck] = useState(false);
  const [allFetched, setAllFetched] = useState(false);
  const [currentSubCategory, setCurrentSubCategory] = useState(null);

  useEffect(() => {
    if (filter.isFilterMenuVisible && !isMobile) {
      // Scroll to a specific position
      const filterHeader = document.getElementById("filter-header");
      filterHeader.classList.add("sticky");
      const section = document.getElementById("gamesDiv");
      section.scrollIntoView({ behavior: "smooth" });
    }
  }, [filter.isFilterMenuVisible]);

  const expandSlides = (id, subCategory) => {
    const current = data.find(
      (item) => item.subCategory === subCategory
    ).currentPage;
    setPage(current + 1);
    setFilterCheck(false);
    setCurrentSubCategory(subCategory);
    data.find((item) => item.subCategory === subCategory).currentPage += 1;
    setTimeout(() => {
      dispatch(
        fetchGames({
          categoryId: category.id,
          subCategoryId: id,
          page: current + 1,
        })
      );
    }, 0);
  };

  const openFilters = () => {
    dispatch(showFilterMenu());
  };
  const toggleCategoriesMenu = () =>
    setIsCategoriesMenuOpen(!isCategoriesMenuOpen);
  const toggleProvidersMenu = () =>
    setIsProvidersMenuOpen(!isProvidersMenuOpen);

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  useEffect(() => {
    if (game.categories.length) {
      const category = game.categories.find((item) => item.name === id);
      setCategory(category);
      if (category) {
        if (game.filterApplied) return;
        dispatch(fetchGames({ categoryId: category.id, page: page }));
        dispatch(fetchSubCategories(category.id));
      }
    }
  }, [game.categories]);

  useEffect(() => {
    setAllFetched(false);
    let games = [];
    if (!filterCheck) games = data;
    if (game.gamesData && game.gamesData.length) {
      game.gamesData.forEach((element) => {
        const obj = { allFetched: false, currentPage: 1 };
        const subCategoryName = capitalizeFirstLetter(element.subCategory);
        let alreadyFoundInGames = games.find(
          (item) => item.subCategory === subCategoryName
        );
        if (alreadyFoundInGames) {
          alreadyFoundInGames.games = [
            ...alreadyFoundInGames.games,
            ...element.games.filter(
              (item2) =>
                !alreadyFoundInGames.games.some(
                  (item1) => item1.id === item2.id
                )
            ),
          ];
        } else {
          obj.subCategory = subCategoryName;
          obj.games = element.games;
          games.push(obj);
        }
      });
      setData(games);
      setrerender(rerender + 1);
    } else {
      const foundSubCategory = data.find(
        (item) => item.subCategory === currentSubCategory
      );
      if (foundSubCategory) foundSubCategory.allFetched = true;
      setAllFetched(true);
      if (!data.length) {
        setData([]);
      }
      setrerender(rerender + 1);
    }
    console.log("final data", data, page);
  }, [game.gamesData]);

  const addToFavourites = (game) => {
    if (user.userLoggedIn) {
      dispatch(makeFavourite({ gameId: game.id, fromCategroyPage: true }));
      setTimeout(() => {
        dispatch(hideToast());
      }, 3000);
    } else {
      dispatch(openLoginModal("login"));
      localStorage.setItem("toAddFav", JSON.stringify(game));
    }
  };
  useEffect(() => {
    if (localStorage.getItem("toAddFav")) {
      const game = JSON.parse(localStorage.getItem("toAddFav"));
      dispatch(makeFavourite({ gameId: game.id, fromCategroyPage: true }));
    }
    localStorage.removeItem("toAddFav");
  }, [user.userLoggedIn]);

  const setFilterApplied = (val) => {
    setFilterCheck(val);
  };
  const openLoginWhenLoggedOut = (id, item) => {
    if (
      !item.allFetched &&
      id == item.games.length - 1 &&
      item.games.length > 7
    ) {
      expandSlides(item.games[0].subCategoryId, item.subCategory);
      return;
    }
    if (!user.userLoggedIn) dispatch(openLoginModal());
  };

  const showSeeMore = (id, item) => {
    return (
      !item.allFetched && id == item.games.length - 1 && item.games.length > 7
    );
  };
  return (
    <div>
      <Carousel />
      <SearchField />
      <div className="row">
        {filter.isFilterMenuVisible && !isMobile && (
          <div className="col-3">
            <FiltersMenu
              data={data}
              category={category}
              filterApplied={setFilterApplied}
            />
          </div>
        )}

        {user.madeFavourite && (
          <div
            class="toast align-items-center text-bg-dark fade show position-fixed bottom-0 end-0 p-3"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div class="d-flex">
              <div class="toast-body">Game Added to Favourites!</div>
              <button
                type="button"
                class="btn-close btn-close-white me-2 m-auto"
                data-bs-dismiss="toast"
                aria-label="Close"
              ></button>
            </div>
          </div>
        )}
        <div className={`${filter.isFilterMenuVisible ? "col-9" : "col-12"}`}>
          <div className="games custom-padding" key={rerender} id="gamesDiv">
            {data.length ? (
              data.map((item, index) => (
                <div key={index}>
                  <h4>{item.subCategory}</h4>
                  <div className="row mt-3">
                    {item.games.length &&
                      item.games.map((game, id) => {
                        return (
                          <div
                            className={`game-card mb-4 ${
                              filter.isFilterMenuVisible
                                ? "col-4"
                                : "col-xl-3 col-lg-3 col-md-3 col-6"
                            }`}
                            key={id}
                            onClick={() => openLoginWhenLoggedOut(id, item)}
                          >
                            {showSeeMore(id, item) ? (
                              <>
                                <img
                                  loading="lazy"
                                  width="100%"
                                  height="100%"
                                  src={game.imagePath}
                                  alt="game"
                                />
                                <i
                                  className="bi bi-star"
                                  onClick={(e) => {
                                    e.preventDefault(); // Prevent redirection
                                    addToFavourites(game);
                                  }}
                                  style={{ cursor: "pointer" }}
                                  title="Add To Favorites"
                                ></i>
                                <div className="game--name">
                                  <p>{game.name}</p>
                                </div>

                                {showSeeMore(id, item) && (
                                  <div className="see-more-parent">
                                    <div className="see-more d-flex">
                                      <img
                                        loading="lazy"
                                        src={seeMoreImage}
                                        width="35"
                                        height="35"
                                        alt="see-more"
                                      />
                                      <p className="text-center mb-0">
                                        <b>See More</b>
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : (
                              <a
                                href={
                                  user.userLoggedIn &&
                                  `/play-game/${game.opentableId}`
                                }
                              >
                                <img
                                  loading="lazy"
                                  width="100%"
                                  height="100%"
                                  src={game.imagePath}
                                  alt="game"
                                />
                                <i
                                  className="bi bi-star"
                                  onClick={(e) => {
                                    e.preventDefault(); // Prevent redirection
                                    addToFavourites(game);
                                  }}
                                  style={{ cursor: "pointer" }}
                                  title="Add To Favorites"
                                ></i>
                                <div className="game--name">
                                  <p>{game.name}</p>
                                </div>
                              </a>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </div>
              ))
            ) : (
              <p>No Games found!</p>
            )}
          </div>
        </div>
      </div>

      <img
        loading="lazy"
        className={`filters ${
          filter.isFilterMenuVisible ? "hide-filter-btn" : ""
        }`}
        id="filters"
        src={filterButton}
        onClick={openFilters}
      />
    </div>
  );
}
