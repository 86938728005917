import React, { useState, useEffect, useRef } from "react";
import { Carousel } from "../home/slider";
import seeMoreImage from "../../assets/images/see-more.svg";
import filterButton from "../../assets/images/filters.svg";
import SearchField from "../common/SearchField";
import FiltersMenu from "./FiltersMenu";
import { showFilterMenu } from "../../features/slices/FilterSlice";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { makeFavourite, hideToast } from "../../features/slices/UserSlice";
import { openLoginModal } from "../../features/slices/ModalSlice";
import { startLoading, stopLoading } from "../../features/slices/LoadingSlice";
import api from "../../api";
export default function AllGames() {
  const dispatch = useDispatch();
  const game = useSelector((state) => state.game);
  const user = useSelector((state) => state.user);
  const filter = useSelector((state) => state.filter);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [offsetY, setOffsetY] = useState(0);
  const [page, setPage] = useState(1);
  const [rerender, setrerender] = useState(0);
  const [filterCheck, setFilterCheck] = useState(false);
  const [allgamesData, setAllGamesData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [params, setParams] = useState("");
  const [allFetched, setAllFetched] = useState(false);
  const timer = useRef(null);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop + 1 >=
      document.documentElement.scrollHeight
    ) {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        if (!loader) setPage((prev) => prev + 1);
      }, 200);
    }
  };

  useEffect(() => {
    if (filter.isFilterMenuVisible && !isMobile) {
      // Scroll to a specific position
      const filterHeader = document.getElementById("filter-header");
      filterHeader.classList.add("sticky");
      const section = document.getElementById("gamesDiv");
      section.scrollIntoView({ behavior: "smooth" });
    }
  }, [filter.isFilterMenuVisible]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const getData = async (paramss, showParentLoader = false) => {
    try {
      let data = allgamesData;
      if (showParentLoader) {
        dispatch(startLoading());
        data = [];
      } else setLoader(true);
      // if (filterCheck) data = [];
      let queryParams = "";
      for (const key in paramss) {
        queryParams += `${key}=${paramss[key]}&`;
      }
      const response = await api.get(
        `/games/listAll?page=${page}&${queryParams}`
      );
      if (response.data.data.length)
        setAllGamesData([...data, ...response.data.data]);
      else setAllFetched(true);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(stopLoading());
      setLoader(false);
    }
  };

  useEffect(() => {
    if (game.filterApplied) getData(game.filters);
    else {
      if (!allFetched) {
        getData(params);
        setrerender(rerender + 1);
      }
    }
  }, [page]);

  const openFilters = () => {
    dispatch(showFilterMenu());
  };

  useEffect(() => {
    let games = [];
    if (!filterCheck) games = allgamesData;
    if (game.allGames && game.allGames.length) {
      games = [
        ...games,
        ...game.allGames.filter(
          (item2) => !games.some((item1) => item1.id === item2.id)
        ),
      ];
      setAllGamesData(games);
      setrerender(rerender + 1);
    } else setAllGamesData([]);
  }, [game.allGames]);

  const addToFavourites = (game) => {
    if (user.userLoggedIn) {
      dispatch(makeFavourite({ gameId: game.id, fromCategroyPage: true }));
      setTimeout(() => {
        dispatch(hideToast());
      }, 3000);
    } else {
      dispatch(openLoginModal("login"));
      localStorage.setItem("toAddFav", JSON.stringify(game));
    }
  };
  useEffect(() => {
    if (localStorage.getItem("toAddFav")) {
      const game = JSON.parse(localStorage.getItem("toAddFav"));
      dispatch(makeFavourite({ gameId: game.id, fromCategroyPage: true }));
    }
    localStorage.removeItem("toAddFav");
  }, [user.userLoggedIn]);
  const setFilterApplied = (val, data = {}) => {
    const section = document.getElementById("gamesDiv");
    section.scrollIntoView({ behavior: "smooth" });
    setAllGamesData([]);
    setFilterCheck(val);
    console.log(data);
    setPage(1);
    setParams(data);
    getData(data, true);
  };
  const openLoginWhenLoggedOut = () => {
    if (!user.userLoggedIn) dispatch(openLoginModal());
    else return;
  };
  return (
    <div>
      <Carousel />
      <SearchField />
      <div className="row">
        {filter.isFilterMenuVisible && !isMobile && (
          <div className="col-3">
            <FiltersMenu
              allGames={true}
              filterApplied={setFilterApplied}
              fromAllGames={true}
            />
          </div>
        )}
        {user.madeFavourite && (
          <div
            class="toast align-items-center text-bg-dark fade show position-fixed bottom-0 end-0 p-3"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div class="d-flex">
              <div class="toast-body">Game Added to Favourites!</div>
              <button
                type="button"
                class="btn-close btn-close-white me-2 m-auto"
                data-bs-dismiss="toast"
                aria-label="Close"
              ></button>
            </div>
          </div>
        )}
        <div className={`${filter.isFilterMenuVisible ? "col-9" : "col-12"}`}>
          <div className="games custom-padding" key={rerender} id="gamesDiv">
            <div className="row">
              {allgamesData.length ? (
                allgamesData.map((game, id) => {
                  return (
                    <div
                      className={`game-card mb-4 ${
                        filter.isFilterMenuVisible
                          ? "col-4"
                          : "col-xl-3 col-lg-3 col-md-3 col-6"
                      }`}
                      key={id}
                      onClick={openLoginWhenLoggedOut}
                    >
                      <a
                        href={
                          user.userLoggedIn && `/play-game/${game.opentableId}`
                        }
                      >
                        <img
                          width="100%"
                          height="100%"
                          src={game.imagePath}
                          alt="game"
                        />
                        <i
                          className="bi bi-star"
                          onClick={(e) => {
                            e.preventDefault(); // Prevent redirection
                            addToFavourites(game);
                          }}
                          style={{ cursor: "pointer" }}
                          title="Add To Favorites"
                        ></i>
                        <div className="game--name">
                          <p>{game.name}</p>
                        </div>
                      </a>
                    </div>
                  );
                })
              ) : (
                <p>Games Not Found!</p>
              )}
            </div>
          </div>
        </div>
        {loader && (
          <div className="all-games-loader">
            <div className="loader-box d-flex justify-content-center p-4">
              <div className="text-center">
                <img
                  loading="lazy"
                  src={require("../../assets/images/loading.gif")}
                  width={200}
                  height={200}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <img
        loading="lazy"
        className={`filters ${
          filter.isFilterMenuVisible ? "hide-filter-btn" : ""
        }`}
        id="filters"
        src={filterButton}
        onClick={openFilters}
      />
    </div>
  );
}
