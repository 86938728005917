import React, { useState } from "react";
import { IoCaretDown } from "react-icons/io5";
import { FaCheck } from "react-icons/fa";
export default function TimeFrameDropdown({ options, getSelectedItem }) {
  const [activeTimeFrame, setActiveTimeFrame] = useState("Day");
  const [showDropdown, setShowDropdown] = useState(false);
  const changeTimeFrame = (timeframe) => {
    setActiveTimeFrame(timeframe);
    getSelectedItem(timeframe);
    setShowDropdown(false);
  };
  return (
    <div>
      <div className="timeframe-dropdown">
        <div
          className="selected-timeframe d-flex justify-content-between me-3"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <div>{activeTimeFrame}</div>
          <IoCaretDown className="ms-2 mt-1" />
        </div>
        {showDropdown && (
          <ul className="dropdown-items list-unstyled">
            {options.map((item, index) => {
              return (
                <li
                  key={index}
                  className="d-flex justify-content-between"
                  onClick={() => changeTimeFrame(item)}
                >
                  <div>{item.toUpperCase()}</div>
                  {activeTimeFrame === item ? (
                    <FaCheck className="checked-item mt-1" />
                  ) : null}
                </li>
              );
            })}
            {/* <li
              className="d-flex justify-content-between"
              onClick={() => changeTimeFrame("Today")}
            >
              <div>Today</div>
              {activeTimeFrame === "Today" ? (
                <FaCheck className="checked-item mt-1" />
              ) : null}
            </li>
            <li
              className="d-flex justify-content-between"
              onClick={() => changeTimeFrame("Weekly")}
            >
              <div>Weekly</div>{" "}
              {activeTimeFrame === "Weekly" ? (
                <FaCheck className="checked-item mt-1" />
              ) : null}
            </li>
            <li
              className="d-flex justify-content-between"
              onClick={() => changeTimeFrame("Monthly")}
            >
              <div>Monthly</div>{" "}
              {activeTimeFrame === "Monthly" ? (
                <FaCheck className="checked-item mt-1" />
              ) : null}
            </li> */}
          </ul>
        )}
      </div>
    </div>
  );
}
