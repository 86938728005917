import React from "react";
import newChatIcon from "../../assets/images/newChatIcon.png";
import { useDispatch, useSelector } from "react-redux";
import {
  showChatBoxInDesktop,
  updateOverlayItem,
  updateSelectedItem,
} from "../../features/slices/CommonSlice";
import { useMediaQuery } from "react-responsive";
import { openChatBox, hideChatBox } from "../../features/slices/CommonSlice";
import {
  closeLoginModal,
  closeSignupModal,
} from "../../features/slices/ModalSlice";
export default function ChatBotOption() {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const common = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const showChat = () => {
    if (isMobile) {
      dispatch(updateSelectedItem("chat-bot"));
    } else {
      dispatch(showChatBoxInDesktop());
      dispatch(closeLoginModal());
      dispatch(closeSignupModal());
    }
  };
  return (
    <div className="d-flex chat-bot-option" onClick={showChat}>
      <img loading="lazy" src={newChatIcon} alt="logo" />
      <div>
        <b>Chat with us</b>
        <p>We are available 24/7</p>
      </div>
    </div>
  );
}
